import React, { useState } from 'react';
import 'react-tabs/style/react-tabs.css'; // Import default styles
import Skeleton from 'react-loading-skeleton';
import { formatNumber, formatDate } from "utils";

const KeyData = ({ stockInfo }) => {
    return (
        <div className='flex flex-col w-full h-full p-4 bg-white rounded-lg'>
            <span className='font-bold text-lg mb-2'>Key Data</span>
            <div className='flex flex-col text-xs h-full rounded-lg border'>
                {/* ROW 1 */}
                <div className='flex justify-between gap-2 bg-gray-200 flex-grow items-center rounded-t-lg'>
                    <div className='flex w-full justify-between pl-2 border-gray-300 items-center'>
                        <span className=' whitespace-nowrap text-xs'>Previous Close</span>
                        <span className='font-semibold whitespace-nowrap text-sm'>{stockInfo.summary?.previousClose || <Skeleton width="90px" height="1em" />}</span>
                    </div>
                    <div className='w-0.5 h-full bg-gray-300'/>
                    <div className='flex w-full justify-between pr-2 border-gray-300 items-center'>
                        <span className=' whitespace-nowrap'>Market Cap</span>
                        <span className='font-semibold whitespace-nowrap text-sm'>{formatNumber(stockInfo.summary?.marketCap) || <Skeleton width="90px" height="1em" />}</span>
                    </div>
                </div>
                {/* ROW 2 */}
                <div className='flex justify-between gap-2 flex-grow items-center'>
                    <div className='flex w-full justify-between pl-2 border-gray-300 items-center'>
                        <span className=' whitespace-nowrap'>Open</span>
                        <span className='font-semibold whitespace-nowrap text-sm'>{stockInfo.summary?.open || <Skeleton width="90px" height="1em" />}</span>
                    </div>
                    <div className='w-0.5 h-full bg-gray-300'/>
                    <div className='flex w-full justify-between pr-2 border-gray-300 items-center'>
                        <span className='whitespace-nowrap'>Beta (5Y Monthly)</span>
                        <span className='font-semibold whitespace-nowrap text-sm'>{stockInfo.summary?.beta || <Skeleton width="90px" height="1em" />}</span>
                    </div>
                </div>
                {/* ROW 3 */}
                <div className='flex justify-between gap-2 bg-gray-200 flex-grow items-center'>
                    <div className='flex w-full justify-between pl-2 border-gray-300 items-center'>
                        <span className='whitespace-nowrap'>Day's Range</span>
                        {stockInfo.summary?.daysLow && stockInfo.summary?.daysHigh ? (
                            <span className='font-semibold whitespace-nowrap text-sm'>{stockInfo.summary.daysLow} - {stockInfo.summary.daysHigh}</span>
                        ) : (
                            <Skeleton width="90px" />
                        )}
                    </div>
                    <div className='w-0.5 h-full bg-gray-300'/>
                    <div className='flex w-full justify-between pr-2 border-gray-300 items-center'>
                        <span className='whitespace-nowrap'>P/E Ratio (TTM)</span>
                        <span className='font-semibold whitespace-nowrap text-sm'>{stockInfo.summary?.peRatio || <Skeleton width="90px" height="1em" />}</span>
                    </div>
                </div>
                {/* ROW 4 */}
                <div className='flex justify-between gap-2 flex-grow items-center'>
                    <div className='flex w-full justify-between pl-2 border-gray-300 items-center'>
                        <span className='whitespace-nowrap'>52 Week Range</span>
                        {stockInfo.summary?.fiftyTwoWeekLow && stockInfo.summary?.fiftyTwoWeekHigh ? (
                            <span className='font-semibold whitespace-nowrap text-sm'>{stockInfo.summary.fiftyTwoWeekLow} - {stockInfo.summary.fiftyTwoWeekHigh}</span>
                        ) : (
                            <Skeleton width="90px" />
                        )}
                    </div>
                    <div className='w-0.5 h-full bg-gray-300'/>
                    <div className='flex w-full justify-between pr-2 border-gray-300 items-center'>
                        <span className='whitespace-nowrap'>EPS (TTM)</span>
                        <span className='font-semibold whitespace-nowrap text-sm'>{stockInfo.summary?.eps || <Skeleton width="90px" height="1em" />}</span>
                    </div>
                </div>
                {/* ROW 5 */}
                <div className='flex justify-between gap-2 bg-gray-200 flex-grow items-center'>
                    <div className='flex w-full justify-between pl-2 border-gray-300 items-center'>
                        <span className='whitespace-nowrap'>Volume</span>
                        <span className='font-semibold whitespace-nowrap text-sm'>{formatNumber(stockInfo.summary?.volume) || <Skeleton width="90px" height="1em" />}</span>
                    </div>
                    <div className='w-0.5 h-full bg-gray-300'/>
                    <div className='flex w-full justify-between pr-2 border-gray-300 items-center'>
                        <span className='whitespace-nowrap'>Forward Dividend Yield</span>
                        <span className='font-semibold whitespace-nowrap text-sm'>{stockInfo.summary?.forwardDividendYield || <Skeleton width="90px" height="1em" />}</span>
                    </div>
                </div>
                {/* ROW 6 */}
                <div className='flex justify-between gap-2 flex-grow items-center rounded-b-lg'>
                    <div className='flex w-full justify-between pl-2 border-gray-300 items-center'>
                        <span className='whitespace-nowrap'>Avg. Volume</span>
                        <span className='font-semibold whitespace-nowrap text-sm'>{formatNumber(stockInfo.summary?.averageVolume) || <Skeleton width="90px" height="1em" />}</span>
                    </div>
                    <div className='w-0.5 h-full bg-gray-300'/>
                    <div className='flex w-full justify-between pr-2 border-gray-300 items-center'>
                        <span className=''>Ex-Dividend Date</span>
                        <span className='font-semibold whitespace-normal text-sm'>{formatDate(stockInfo.summary?.exDividendDate) || <Skeleton width="90px" height="1em" />}</span>
                    </div>
                </div>
            </div>
        </div>
        
    );
};

export default KeyData;