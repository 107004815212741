import React, { useEffect, useRef } from 'react';

const EconomicCalendarComponent = () => {
    const widgetContainerRef = useRef(null);

    useEffect(() => {
        const widgetConfig = {
              "colorTheme": "light",
              "isTransparent": false,
              "width": "900",
              "height": "650",
              "locale": "en",
              "importanceFilter": "-1,0,1",
              "countryFilter": "us,cn,eu,in,ru"
        };

        if (widgetContainerRef.current) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-events.js';
            script.innerHTML = JSON.stringify(widgetConfig);
            widgetContainerRef.current.innerHTML = ''; // Clear the container
            widgetContainerRef.current.appendChild(script);
        }
    }, []);

    return (
        <div class="tradingview-widget-container" ref={widgetContainerRef}>
            <div class="tradingview-widget-container__widget"></div>
            <div class="tradingview-widget-copyright">
            </div>
        </div>
    );
};

export default EconomicCalendarComponent;
