import { useNavigate } from "react-router-dom";
import { Button, Heading, Img } from "./..";

export default function TopNavLogin({ ...props }) {
  const navigate = useNavigate();

  const handleRegisterClickStudent = () => {
    navigate('/register/student');
  };

  const handleLoginClickLogin = () => {
    navigate('/LoginPage');
  };

  return (
    <header {...props} className={`${props.className} flex items-center justify-center bg-white-A700 p-2 shadow-xs`}>
      <div className="container-sm flex items-center justify-between gap-5 md:flex-col">
        <div className="flex w-[50%] items-center justify-center gap-8 md:w-full sm:flex-col">
          <Img src="/images/img_header_logo.png" alt="headerlogo" className="h-[64px] w-[203px] object-contain" />
          <ul className="flex flex-wrap items-center gap-6">
            <li>
              <a href="#" className="border-b-2 border-solid border-light_blue-500">
                <Heading size="6xl" as="h6" className="py-1.5 !font-inter !text-light_blue-500">
                  Home
                </Heading>
              </a>
            </li>
            <li>
              <a href="#" className="cursor-pointer">
                <Heading
                  size="6xl"
                  as="h6"
                  className="py-1.5 !font-inter !text-gray-600_01 hover:!text-light_blue-500"
                >
                  Features
                </Heading>
              </a>
            </li>
            <li>
              <a href="#" className="cursor-pointer">
                <Heading
                  size="6xl"
                  as="h6"
                  className="py-1.5 !font-inter !text-gray-600_01 hover:!text-light_blue-500"
                >
                  Pricing
                </Heading>
              </a>
            </li>
            <li>
              <a href="#" className="cursor-pointer">
                <Heading
                  size="6xl"
                  as="h6"
                  className="py-1.5 !font-inter !text-gray-600_01 hover:!text-light_blue-500"
                >
                  About Us
                </Heading>
              </a>
            </li>
          </ul>
        </div>
        <div className="flex gap-4">
          <a onClick={handleLoginClickLogin} className="cursor-pointer">
            <div className="flex rounded-lg px-[13px] pb-2.5 pt-[13px] shadow-sm">
              <Heading size="6xl" as="h6" className="!font-inter !text-blue-A200">
                Login
              </Heading>
            </div>
          </a>
          <Button onClick={handleRegisterClickStudent}
            size="4xl"
            shape="round"
            className="min-w-[123px] border border-solid border-blue-A200 font-inter font-semibold shadow-sm"
          >
            I am a Student
          </Button>
        </div>
      </div>
    </header>
  );
}