import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Heading, Img } from "../../components";
import Header from "../../components/Header";
import StudentSidebar from "../../components/StudentSidebar";
import { useNavigate } from "react-router-dom";
import SpendingStartComponent from "../../components/SpendingPopup1";



export default function BudgetSimulatorPage() {
  const navigate = useNavigate();
  const [showSpendingStart, setShowSpendingStart] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  

  return (
    <>
      {showSpendingStart && <SpendingStartComponent />}
      {showPopup && <SpendingStartComponent closePopup={togglePopup} />}
      <Helmet>
        <title>intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-center bg-white-A700 md:flex-col">
        <StudentSidebar />
        <div className="container-md md:p-5">
          <div>
            <Header className="px-4 py-2" />
            <div className="p-4">
              <div className="flex flex-col gap-2 rounded-lg border border-solid border-blue_gray-100 bg-white-A700 px-[26px] pb-[182px] pt-8 md:pb-5 sm:p-5">
                <Img
                  src="images/img_adobestock_124712720.png"
                  alt="adobestock"
                  className="h-[266px] rounded-lg object-cover"
                />
                <div className="border-b border-solid border-blue_gray-100 pb-2">
                  <div className="flex items-start justify-between gap-5">
                    <Heading size="11xl" as="h1" className="!font-bold !text-gray-900_05">
                      Budget Simulator
                    </Heading>
                    <div className="flex items-center gap-2">
                      <Img src="images/img_clock.svg" alt="clock" className="h-[16px] w-[16px]" />
                      <Text as="p" className="!font-roboto tracking-[0.25px] !text-gray-800_01">
                        Duration : 30 minute
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start gap-6 pb-4">
                  <div className="flex flex-col items-start gap-4 self-stretch">
                    <Text as="p" className="w-[68%] leading-[150%] !text-gray-900_05 md:w-full">
                      You are a college student and just started working at a local diner. You and your friends decide
                      to go for a trip to Cancun for the spring break. Throughout the this scenario you will work
                      towards a goal to save 1000$ for the trip. You will have to make tough decisions on multiple
                      occasions, but also consider your happiness during the process.
                    </Text>
                    <div className="flex w-[40%] flex-col items-start gap-[15px] md:w-full">
                      <Heading size="6xl" as="h2" className="!text-gray-900_05">
                        Learning Objective
                      </Heading>
                      <Text as="p" className="w-full leading-[150%] !text-gray-900_05">
                        <>
                          Understand the concept of budgeting and expense tracking.
                          <br />
                          Practice allocating income towards savings goals and essential expenses.
                          <br />
                          Make informed financial decisions based on income and desired spending.
                        </>
                      </Text>
                    </div>
                    <div className="flex w-[35%] flex-col items-start gap-[17px] md:w-full">
                      <Heading size="6xl" as="h3" className="!text-gray-900_05">
                        Scenario Goal
                      </Heading>
                      <Text as="p" className="w-full leading-[150%] !text-gray-900_05">
                        <>
                          Save a total of 1000$ by the end of March
                          <br />
                          maintain a happiness core above 45%
                          <br />
                          Pay all the bills and maintain a credit card debt minimum to 250$
                        </>
                      </Text>
                    </div>
                  </div>
                  <Button size="5xl" shape="round" className="min-w-[179px] font-semibold shadow-sm" onClick={togglePopup}>
                  Start the scenario
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
