import React from "react";
import { flexRender, getCoreRowModel, useReactTable, getSortedRowModel } from "@tanstack/react-table";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const variants = {
  simple: "bg-white-A700",
};
const sizes = {
  xs: "p-[5px]",
  sm: "p-2",
  md: "p-[11px]",
  lg: "p-3",
  xl: "p-5",
  "2xl": "p-6",
};

const ReactTableHoldings = ({
  columns,
  data = [],
  headerProps = {},
  bodyProps = {},
  className = "",
  rowDataProps = { className: "" },
  size,
  variant,
  isLoading = false,
  ...restConfig
}) => {
  const tableConfig = {
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    ...restConfig,
  };

  const table = useReactTable(tableConfig);

  return (
    <table className={`${className} w-full text-left`}>
      <thead {...headerProps}>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id} {...header.column.columnDef?.meta} className="p-3 text-blue_gray-500 text-center justify-center">
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...bodyProps}>
        {isLoading ? (
          [...Array(10)].map((_, index) => (
            <tr key={index} className={`${variant ? variants[variant] : ""} ${rowDataProps?.className}`}>
              {columns.map((column, colIndex) => (
                <td key={colIndex} className={`p-3 ${size ? sizes[size] : ''} text-center justify-center`}>
                  <Skeleton />
                </td>
              ))}
            </tr>
          ))
        ) : (
          table.getRowModel().rows.map((row) => (
            <tr
              {...rowDataProps}
              className={`${variant ? variants[variant] : ""} ${rowDataProps?.className}`}
              key={row.id}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={`p-3 ${size ? sizes[size] : ''} text-center`}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

export { ReactTableHoldings };