import React from "react";
import { Helmet } from "react-helmet";
import { Text, Heading, Img, Button, Input, CheckBox, TextArea, SelectBox } from "./../../../../components";
import LoginIPadOne from "components/LoginIPadOne";
import TopNavLogin from "components/TopNavLogin";
import TeacherRegisterForm from "./TeacherRegisterForm";



export default function RegisterTeacherPage() {
  const [searchBarValue4, setSearchBarValue4] = React.useState("");

  return (
    <>
      <Helmet>
        <title>Register | Intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="w-full bg-white-A700">
        <TopNavLogin/>

        <div className="flex justify-center">
          <div className="container-md flex items-start justify-between gap-5 pl-[15px] md:flex-col md:p-5">
            <div className="mt-6 flex w-[42%] flex-col items-center gap-[35px] md:w-full">
              <div className="flex w-[85%] flex-col items-center md:w-full">
                <Heading size="15xl" as="h1" className="!text-gray-900_05">
                  Teacher Registration
                </Heading>
                <Text size="4xl" as="p" className="w-[78%] text-center leading-[130%] !text-blue_gray-800 md:w-full">
                  It all begins with you. Create an online classroom environment that cultivates learning, curiosity,
                  and collaboration.
                </Text>
              </div>
              <TeacherRegisterForm />
            </div>
            <LoginIPadOne />
            </div>
        </div>
      </div>
    </>
  );
}