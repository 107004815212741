import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Heading, Img, Text } from "../../components";
import { useNavigate } from 'react-router-dom';
import HomeDate from "components/HomeDate";
import { ConvertDuration } from "utils";
import { fetchCourses } from "TeacherAPIService";
import Sidebar1 from "components/Sidebar1";
import TeacherCourseCard from "components/TeacherCourseCard";

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

export default function TeacherCourseView() {
    const navigate = useNavigate(); // Initialize useNavigate
    const [courses, setCourses] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [studentId, setStudentId] = useState();
    const [classrooms, setClassrooms] = useState();

    const handleOpenPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedClassrooms = await fetchCourses();
                setCourses(fetchedClassrooms);
            } catch (error) {
                if (error.message.includes("401")) {
                    navigate('/timeout'); // Navigate to the timeout page if a 401 is encountered
                } else {
                    console.error('Error fetching classrooms:', error);
                }
            }
        };

        fetchData();

    }, [navigate]); // Add navigate to the dependency array

    return (
        <>
            <Helmet>
                <title>intertwined</title>
                <meta name="description" content="Web site created using create-react-app" />
            </Helmet>

            <div className="flex w-full h-screen overflow-hidden m-auto">

                <div><Sidebar1 /></div>

                <div className="flex flex-col w-full m-4 gap-4">
                    <HomeDate className="w-full px-4 rounded-lg" />
                    <div className="flex w-full rounded-lg gap-4 overflow-y-auto">
                        <div className="bg-white-A700 px-6 pt-6 md:pb-5 sm:p-5">
                            <div className="flex flex-col gap-6">
                                <div className="flex self-start pb-[7px] pt-3.5">
                                    <Heading size="9xl" as="h1">
                                        My Courses
                                    </Heading>
                                </div>
                                <div className="flex gap-6 rounded-lg md:flex-col">
                                    {courses.map((course, index) => (
                                        <TeacherCourseCard
                                            key={index}
                                            className={course.name} // Adjust according to data structure
                                            courseName={course.name} // Example: "Not Started", "Continue"
                                            expectedDuration={course.expectedDuration}
                                            durationUnit={ConvertDuration(course.durationUnit)}
                                            courseId={course.id}
                                            studentId={studentId}
                                            to={`/course/${course.id}`} // Adjust routing as needed
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
