// TODO: Issue with plurals! Maybe add an optional parameter that's amount?
function ConvertDuration(d) {
    switch (d) {
        case 0: return "seconds";
        case 1: return "minutes";
        case 2: return "hours";
        case 3: return "days";
        case 4: return "weeks";
        case 5: return "months";
        default: return "";
    }
}

// shuffle from https://stackoverflow.com/a/12646864
function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function formatNumber(num) {
    if (num >= 1000000000000) {
        return (num / 1000000000000).toFixed(1) + 'T';
    }
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1) + 'B';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
    }
    return num;
}

export function formatDate(dateString) {
    if (dateString) {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
        }).format(date);
    }
}

const LESSON_ARTICLE = 1;
const LESSON_MEDIA = 0;


export { ConvertDuration, shuffle, LESSON_ARTICLE, LESSON_MEDIA }