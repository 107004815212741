import React from "react";
import { Helmet } from "react-helmet";
import { Text, Img, Button, Heading, Input } from "../../components";
import Header from "../../components/Header";
import Sidebar1 from "../../components/Sidebar1";
import { useNavigate } from 'react-router-dom';

export default function BudgetSimulatorOnePage() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-center bg-white-A700 md:flex-col">
        <Sidebar1 />
        <div className="container-md md:p-5">
          <div>
            <Header className="px-4 py-2" />
            <div className="bg-white-A700 p-4">
              <div className="pb-4">
                <div className="flex items-start gap-4 md:flex-col">
                  <div className="flex h-[400%] flex-1 flex-col gap-[22px] rounded-lg border border-solid border-blue_gray-100 pb-1.5 md:self-stretch">
                    <div className="h-full pb-4 pt-[15px]">
                      <div className="flex px-4">
                        <div className="flex flex-col items-start pb-2 pl-4 pr-4">
                          <Heading size="11xl" as="h1" className="!font-poppins !text-gray-900_05">
                            Budget Simulator
                          </Heading>
                          <div className="flex">
                            <Text as="p" className="tracking-[0.25px] !text-gray-800_01">
                              May 1, 2024
                            </Text>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-8 border-b border-solid border-blue_gray-100 px-8 sm:px-5">
                        <div className="flex w-[7%] flex-col pt-[15px]">
                          <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-blue-A200" onClick={() => navigate('/budgetsimulatorTwo')}>
                            Calender
                          </Text>
                          <Img src="images/img_close_blue_a200.svg" alt="close" className="h-[14px]" />
                        </div>
                        <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01" onClick={() => navigate('/budgetsimulatorThree')}>
                          Accounts
                        </Text>
                        <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01" onClick={() => navigate('/budgetsimulatorfour')}>
                          Statement
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col gap-4 px-2">
                  <div className="rounded-lg border border-solid border-blue_gray-100">
                  <div className="flex border-b border-solid border-blue_gray-100 px-4 pb-2.5 pt-[13px]">
                    <Heading size="4xl" as="h3" className="!text-blue_gray-700">
                      Happiness Meter
                    </Heading>
                  </div>
                  <div className="flex items-center justify-between gap-5">
                    <Heading
                      size="14xl"
                      as="h4"
                      className="mb-[7px] self-end !font-poppins !text-[33.29px] !text-gray-900_05"
                    >
                      <span className="text-green-A700_01 score-value">80</span>
                      <span className="text-2xl text-blue_gray-700">/</span>
                      <span className="text-2xl text-gray-900_05 score-value">100</span>
                    </Heading>
                    <Img src="images/img_group_16.svg" alt="image" className="h-[99px] w-[69%]" />
                  </div>
                </div>
                <div className="rounded-lg border border-solid border-blue_gray-100">
                  <div className="flex border-b border-solid border-blue_gray-100 px-4 pb-2.5 pt-[13px]">
                    <Heading size="4xl" as="h5" className="!text-blue_gray-700">
                      Credit Score
                    </Heading>
                  </div>
                  <div className="flex items-center justify-between gap-5">
                    <Heading
                      size="14xl"
                      as="h6"
                      className="self-end !font-poppins !text-[33.29px] !text-gray-900_05"
                    >
                      <span className="text-yellow_800 score-value">670</span>
                      <span className="text-2xl text-blue_gray-700">/</span>
                      <span className="text-2xl text-gray-900_05 score-value">900</span>
                    </Heading>
                    <div className="flex w-[54%] flex-col items-center">
                      <Text size="xs" as="p" className="relative z-[1] !font-inter !text-[5.5px] !text-black-900">
                        GOOD
                      </Text>
                      <div className="relative mt-[-5px] h-[92px] self-stretch bg-[url(/public/images/img_group_101.svg)] bg-cover bg-no-repeat px-1 pb-[22px] pt-1 md:h-auto sm:pb-5">
                        <div className="flex flex-col items-center gap-2.5">
                          <div className="flex w-[71%] flex-wrap items-center justify-between gap-5 md:w-full">
                            <Text
                              size="xs"
                              as="p"
                              className="h-[12px] w-[11px] rotate-[39deg] !font-inter !text-[5.5px] !text-black-900"
                            >
                              FAIR
                            </Text>
                            <Text
                              size="xs"
                              as="p"
                              className="rotate-[-30deg] !font-inter !text-[5.5px] !text-black-900"
                            >
                              VERY GOOD
                            </Text>
                          </div>
                          <div className="flex flex-wrap items-start justify-between gap-5 self-stretch">
                            <Text
                              size="xs"
                              as="p"
                              className="mt-1 rotate-[71deg] !font-inter !text-[5.5px] !text-black-900"
                            >
                              POOR
                            </Text>
                            <Text
                              size="xs"
                              as="p"
                              className="rotate-[-75deg] !font-inter !text-[5.5px] !text-black-900"
                            >
                              EXCELLENT
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                    <div className="rounded-lg border border-solid border-blue_gray-100">
                      <div className="flex border-b border-solid border-blue_gray-100 px-4 py-3">
                        <Heading size="4xl" as="p" className="!text-blue_gray-700">
                          ACCOUNTS
                        </Heading>
                      </div>
                      <div className="flex flex-col gap-px px-4 py-2">
                        <div className="flex flex-1">
                          <div className="flex flex-col items-center px-2 py-4">
                            <Img src="images/img_bank.svg" alt="checking" className="h-[24px] w-[24px]" />
                          </div>
                          <div className="flex w-[55%] justify-center p-2">
                            <div className="relative right-[30px] flex pb-[9px] pt-[11px]">
                              <Text size="7xl" as="p" className="!font-roboto tracking-[0.50px]">
                                Checking
                              </Text>
                            </div>
                          </div>
                          <div className="flex pb-[19px] pl-4 pr-2 pt-[17px]">
                            <Text size="7xl" as="p" className="!font-roboto tracking-[0.50px] !text-green-A700_01">
                              $400
                            </Text>
                          </div>
                          <div className="flex flex-col items-center px-2 py-4">
                            <Img src="images/img_arrow_up_black_900.svg" alt="checking" className="h-[24px] w-[24px]" />
                          </div>
                        </div>
                        <div className="flex flex-1">
                          <div className="flex flex-col items-center px-2 py-4">
                            <Img src="images/img_bank.svg" alt="bank" className="h-[24px] w-[24px]" />
                          </div>
                          <div className="flex w-[55%] justify-center p-2">
                            <div className="flex pb-[17px] pt-[19px]">
                              <Text size="7xl" as="p" className="!font-roboto tracking-[0.50px]">
                                Savings
                              </Text>
                            </div>
                          </div>
                          <div className="flex pb-[19px] pl-4 pr-2 pt-[17px]">
                            <Text size="7xl" as="p" className="!font-roboto tracking-[0.50px]">
                              $400
                            </Text>
                          </div>
                          <div className="flex flex-col items-center px-2 py-4">
                            <Img
                              src="images/img_arrow_up_black_900.svg"
                              alt="arrowdown"
                              className="h-[24px] w-[24px]"
                            />
                          </div>
                        </div>
                        <div className="flex flex-1">
                          <div className="flex flex-col items-center px-2 py-4">
                            <Img
                              src="images/img_credit_card_02.svg"
                              alt="creditcardtwo"
                              className="h-[24px] w-[24px]"
                            />
                          </div>
                          <div className="flex w-[53%] justify-center p-2">
                            <div className="flex pb-[11px] pt-[9px]">
                              <Text size="7xl" as="p" className="relative right-[18px] !font-roboto tracking-[0.50px]">
                                Credit Card
                              </Text>
                            </div>
                          </div>
                          <div className="flex pb-[19px] pl-4 pr-2 pt-[17px]">
                            <Text
                              size="7xl"
                              as="p"
                              className="relative left-2.5 w-[50px] !font-roboto tracking-[0.50px] !text-red-500"
                            >
                              -$400
                            </Text>
                          </div>
                          <div className="flex flex-col items-center px-2 py-4">
                            <Img
                              src="images/img_arrow_up_black_900.svg"
                              alt="arrowdown"
                              className="h-[24px] w-[24px]"
                            />
                          </div>
                        </div>
                        <div className="flex flex-1">
                          <div className="flex flex-col items-center px-2 py-4">
                            <Img src="images/img_bank_note_01.svg" alt="banknoteone" className="h-[24px] w-[24px]" />
                          </div>
                          <div className="flex w-[55%] justify-center p-2">
                            <div className="flex pb-[11px] pt-[9px]">
                              <Text size="7xl" as="p" className="relative right-5 !font-roboto tracking-[0.50px]">
                                Net Cash
                              </Text>
                            </div>
                          </div>
                          <div className="flex pb-[19px] pl-4 pr-2 pt-[17px]">
                            <Text size="7xl" as="p" className="!font-roboto tracking-[0.50px]">
                              $400
                            </Text>
                          </div>
                          <div className="flex flex-col items-center px-2 py-4">
                            <Img
                              src="images/img_arrow_up_black_900.svg"
                              alt="arrowdown"
                              className="h-[24px] w-[24px]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="rounded-lg border border-solid border-blue_gray-100">
                      <div className="flex items-center justify-between gap-5 border-b border-solid border-blue_gray-100 py-2 pl-4 pr-2">
                        <Heading size="4xl" as="p" className="!text-blue_gray-700">
                          UPCOMING EVENTS
                        </Heading>
                        <div className="flex rounded-lg">
                          <div className="flex rounded-lg p-[11px]">
                            <Img src="images/img_search_blue_gray_700.svg" alt="search" className="h-[18px] w-[18px]" />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-center px-4">
                        <div className="flex self-start pt-4">
                          <Heading size="6xl" as="h6" className="!text-blue_gray-400_01">
                            This Week
                          </Heading>
                        </div>
                        <div className="flex self-stretch">
                          <div className="flex flex-col items-center bg-white-A700 px-2 py-4">
                            <Button color="blue_50_04" className="w-[24px] rounded">
                              <Img src="images/img_home_01.svg" />
                            </Button>
                          </div>
                          <Input
                            size="2xl"
                            shape="square"
                            name="list_one"
                            placeholder={`Rent`}
                            className="flex-grow font-roboto tracking-[0.50px] !text-gray-900_07 sm:pr-5"
                          />
                          <Button
                            color="white_A700"
                            size="6xl"
                            shape="square"
                            className="min-w-[67px] justify-end font-roboto tracking-[0.50px] !text-blue_gray-500_02"
                          >
                            -$400
                          </Button>
                        </div>
                        <div className="flex self-stretch">
                          <div className="flex flex-col items-center px-2 py-4">
                            <Button color="blue_50_04" className="w-[24px] rounded">
                              <Img src="images/img_car_02_blue_500.svg" />
                            </Button>
                          </div>
                          <div className="relative left-2.5 flex flex-1 justify-start p-2">
                            <div className="flex pb-[11px] pt-[9px]">
                              <Text size="7xl" as="p" className="!font-roboto tracking-[0.50px]">
                                Gas
                              </Text>
                            </div>
                          </div>
                          <div className="flex pb-[19px] pl-4 pr-2 pt-[17px]">
                            <Text size="7xl" as="p" className="!font-roboto tracking-[0.50px] !text-blue_gray-500_02">
                              -$400
                            </Text>
                          </div>
                        </div>
                        <div className="flex self-start pt-4">
                          <Heading size="6xl" as="h6" className="!text-blue_gray-400_01">
                            This Month
                          </Heading>
                        </div>
                        <div className="flex self-stretch">
                          <div className="flex flex-col items-center bg-white-A700 px-2 py-4">
                            <Button color="teal_50" className="w-[24px] rounded">
                              <Img src="images/img_currency_dollar_green_a700_01.svg" />
                            </Button>
                          </div>
                          <div className="relative left-2.5 flex flex-1 justify-start bg-white-A700 p-2">
                            <div className="flex pb-[9px] pt-[11px]">
                              <Text size="7xl" as="p" className="!font-roboto tracking-[0.50px]">
                                Salary
                              </Text>
                            </div>
                          </div>
                          <Button
                            color="white_A700"
                            size="6xl"
                            shape="square"
                            className="min-w-[72px] justify-end font-roboto tracking-[0.50px] !text-blue_gray-500_02"
                          >
                            +$400
                          </Button>
                        </div>
                        <div className="flex self-stretch">
                          <div className="flex flex-col items-center bg-white-A700 px-2 py-4">
                            <Button color="gray_100_01" className="w-[24px] rounded">
                              <Img src="images/img_thumbs_up.svg" />
                            </Button>
                          </div>
                          <div className="relative left-2.5 flex flex-1 justify-start bg-white-A700 p-2">
                            <div className="flex pb-[11px] pt-[9px]">
                              <Text size="7xl" as="p" className="!font-roboto tracking-[0.50px]">
                                Food
                              </Text>
                            </div>
                          </div>
                          <Button
                            color="white_A700"
                            size="6xl"
                            shape="square"
                            className="min-w-[67px] justify-end font-roboto tracking-[0.50px] !text-blue_gray-500_02"
                          >
                            -$400
                          </Button>
                        </div>
                        <div className="flex self-stretch">
                          <div className="flex flex-col items-center px-2 py-4">
                            <Img src="images/img_component_45.svg" alt="image" className="h-[24px] w-[24px]" />
                          </div>
                          <div className="flex flex-1 justify-center p-2">
                            <Text size="7xl" as="p" className="w-[87%] !font-roboto leading-6 tracking-[0.50px]">
                              Road Trip with friends
                            </Text>
                          </div>
                          <div className="flex pb-[19px] pl-4 pr-2 pt-[17px]">
                            <Text size="7xl" as="p" className="!font-roboto tracking-[0.50px] !text-blue_gray-500_02">
                              -$400
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
