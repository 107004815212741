import React from "react";
import { Img, Text, Heading } from "../../components";
import { useNavigate } from "react-router-dom";

export default function LearningViewTopBar({ courseId, studentId, unitInfo }) {
    const navigate = useNavigate();

    const handleBacktoCourses = () => {
        navigate(`/courses/course/${courseId}/student/${studentId}`);
    };

    return (
        <>
            <div className="flex w-full h-full relative items-center rounded-lg border p-2">

                <div onClick={handleBacktoCourses} className="flex items-center cursor-pointer">
                    <Img
                        src="/images/img_arrow_left_blue_a200.svg"
                        alt="arrowleft"

                        className="ml-5"
                    />
                    <Text size="7xl" as="p" className="!text-blue-500">

                        Back to all Courses
                    </Text>
                </div>

                <div className="flex items-center gap-6 ml-auto mr-auto">
                    <div className="flex items-center rounded border border-solid border-deep_purple-A200_01 bg-gray-50 p-[4px]">
                        <Heading as="p" className="!text-deep_purple-A200_01">
                            Unit {unitInfo !== undefined && unitInfo.sequenceNumber}
                        </Heading>
                    </div>
                    <Heading size="13xl" as="h2" className="text-center">
                        {unitInfo !== undefined && unitInfo.name}
                    </Heading>
                </div>
            </div>
        </>
    );
}