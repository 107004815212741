import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Text, Heading, Img, Button } from "../../components";
import CourseCard from "../../components/CourseCard";
import HomeAssignmentCard from "../../components/HomeAssignmentCard";
import HomeDate from "../../components/HomeDate";
import StudentSidebar from "../../components/StudentSidebar";
import DonutChart from "../../components/DounutChart";
import { useNavigate } from 'react-router-dom';
import { ConvertDuration } from "utils";
import PortfolioChart from "components/PortfolioChart";
import { fetchInfo, fetchStudentId, fetchStudentPortfolioGraph } from "SimulatorAPIService";
import DashboardCalendar from "components/DashboardCalendar";
import Sidebar1 from "components/Sidebar1";
import { UserContext } from 'UserContext';

const categories = [
  { name: "Gas", value: 15, color: "#9ECCFE" },
  { name: "Rent", value: 15, color: "#FDEFC5" },
  { name: "Food", value: 15, color: "#FBE6F5" },
  { name: "Utilities", value: 15, color: "pink" },
  { name: "Misc", value: 15, color: "#FE94FC" }
];

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;


export default function TeachersDashboardPage() {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [studentId, setStudentId] = useState();
  const [info, setInfo] = useState();
  const { userInfo, setUserInfo } = useContext(UserContext);

  
  const [portfolioGraph, setPortfolioGraph] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  const handleCardClick = () => {
    navigate('/courseview');
  };

  const handleViewAllCourses = () => {
    navigate('/courses');
  };

  return (
    <>
      <Helmet>
        <title>Home | Intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>

      <div className="relative w-full h-full">
        <div className="absolute inset-0 -z-10">
          <div className="relative h-[400px] w-full">
            <Img src="images/img_rectangle_9_309x1352.png" alt="image" className="h-full w-full object-fill" />
            <div className="absolute inset-0">
              <div className="h-full w-full relative">
                <div className="absolute bottom-0 left-0 right-0 h-[100px] bg-gradient-to-t from-white to-transparent"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full h-screen overflow-hidden m-auto">

          <div><Sidebar1/></div>

          <div className="flex flex-col w-full m-4 gap-4">
            <HomeDate className="w-full px-4 rounded-lg" />
            <div className="flex w-full rounded-lg gap-4 overflow-y-auto">

              <div className="flex flex-col w-[65%] h-full gap-4">

                <div className="p-4 rounded-lg md:hidden" style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>

                  <div className="flex items-start justify-start gap-1 rounded md:w-full">
                    <Img
                      src="images/img_new_releases.svg"
                      alt="newreleases"
                      className="h-[16px] w-[16px] self-start"
                    />
                    <Heading as="h1" className="uppercase">
                      DID You KNOW?
                    </Heading>
                  </div>


                  <div className="flex flex-col items-end gap-6 ">
                    <Heading size="12xl" as="h2" className="w-full !text-blue_gray-900_01">
                      Nearly 9 in 10 U.S. adults said high school did not leave them “fully prepared” for handling money in the real world.
                    </Heading>
                    <Text as="p" className="!text-gray-600_01">
                      - Financial Literacy Crisis Report 2023
                    </Text>
                  </div>

                </div>


              

              </div>

              <div className="flex flex-col flex-grow h-full gap-4">

                <div className="h-full w-full border border-solid border-gray-300 rounded-lg">
                  {studentId && <DashboardCalendar studentId={studentId} />}
                </div>


              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  );
}
