import React from "react";
import { Img, Text, Button, Heading } from "./..";

export default function CalendarComponent({ ...props }) {
  return (
    <div {...props} className={`${props.className} border-blue_gray-50_01 border border-solid rounded-lg`}>
      <div className="w-full rounded-lg shadow-md">
        <div className="flex items-center justify-between gap-5 px-4 py-2">
          <Heading size="5xl" as="p" className="mb-[13px] self-end !font-inter !text-[15.4px] !text-gray-700_02">
            May 2024
          </Heading>
          <div className="flex w-[16%] items-center justify-center rounded-lg bg-blue-A200 p-3">
            <div className="flex">
              <Text size="7xl" as="p" className="!font-inter !font-medium tracking-[0.50px] !text-white-A700">
                Next Day
              </Text>
            </div>
            <Img src="images/img_arrowright_white_a700_16x16.svg" alt="arrowright" className="h-[16px] w-[16px]" />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-wrap self-start bg-gray-50_03">
            <Text
              size="2xl"
              as="p"
              className="flex items-center justify-center border-[0.5px] border-solid border-gray-200_01 bg-white-A700 py-[7px] pl-[7px] pr-[35px] !font-inter !text-[10.56px] !text-gray-500_03 sm:pr-5"
            >
              SUN
            </Text>
            <Text
              size="2xl"
              as="p"
              className="flex items-center justify-center border-[0.5px] border-solid border-gray-200_01 bg-white-A700 py-[7px] pl-[7px] pr-[35px] !font-inter !text-[10.56px] !text-gray-500_03 sm:pr-5"
            >
              MON
            </Text>
            <Text
              size="2xl"
              as="p"
              className="flex items-center justify-center border-[0.5px] border-solid border-gray-200_01 bg-white-A700 py-[7px] pl-[7px] pr-[35px] !font-inter !text-[10.56px] !text-gray-500_03 sm:pr-5"
            >
              TUE
            </Text>
            <Text
              size="2xl"
              as="p"
              className="flex items-center justify-center border-[0.5px] border-solid border-gray-200_01 bg-white-A700 py-[7px] pl-[7px] pr-[35px] !font-inter !text-[10.56px] !text-gray-500_03 sm:pr-5"
            >
              WED
            </Text>
            <Text
              size="2xl"
              as="p"
              className="flex items-center justify-center border-[0.5px] border-solid border-gray-200_01 bg-white-A700 py-[7px] pl-[7px] pr-[35px] !font-inter !text-[10.56px] !text-gray-500_03 sm:pr-5"
            >
              THUR
            </Text>
            <Text
              size="2xl"
              as="p"
              className="flex items-center justify-center border-[0.5px] border-solid border-gray-200_01 bg-white-A700 py-[7px] pl-[7px] pr-[35px] !font-inter !text-[10.56px] !text-gray-500_03 sm:pr-5"
            >
              FRI
            </Text>
            <Text
              size="2xl"
              as="p"
              className="flex items-center justify-center border-[0.5px] border-solid border-gray-200_01 bg-white-A700 py-[7px] pl-[7px] pr-[35px] !font-inter !text-[10.56px] !text-gray-500_03 sm:pr-5"
            >
              SAT
            </Text>
          </div>
          <div className="flex flex-col gap-px">
            <div className="flex flex-1 md:flex-col">
              <div className="flex w-full flex-col items-start gap-2 border-[0.5px] border-solid border-gray-200_01 bg-yellow-100_01 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="!font-inter !text-[13.86px] !text-gray-500_03">
                  1
                </Text>
                <div className="flex flex-col items-end gap-1.5">
                  <div className="flex gap-1.5">
                    <Button color="blue_50_04" className="w-[26px] rounded">
                      <Img src="images/img_phone.svg" />
                    </Button>
                    <Button color="blue_50_04" className="w-[26px] rounded">
                      <Img src="images/img_icon_wrench.svg" />
                    </Button>
                    <Button color="blue_50_04" className="w-[26px] rounded">
                      <Img src="images/img_car_02.svg" />
                    </Button>
                  </div>
                  <div className="flex gap-1.5">
                    <Button color="gray_100_01" className="w-[26px] rounded">
                      <Img src="images/img_credit_card_minus.svg" />
                    </Button>
                    <Button color="gray_100_01" className="w-[26px] rounded">
                      <Img src="images/img_thumbs_up.svg" />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[41px] border-[0.5px] border-solid border-gray-200_01 bg-yellow-100_01 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="!font-inter !text-[13.86px] !text-gray-500_03">
                  2
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="image" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="image" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[41px] border-[0.5px] border-solid border-gray-200_01 bg-yellow-100_01 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="!font-inter !text-[13.86px] !text-gray-500_03">
                  3
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="image" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="image" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-end gap-3.5 border-2 border-solid border-deep_orange-900_01 bg-white-A700 px-[7px] pb-[7px] pt-2">
                <Text size="5xl" as="p" className="!font-inter !text-[13.86px] !text-gray-500_03">
                  4
                </Text>
                <div className="flex flex-col items-end gap-1.5">
                  <div className="flex flex-col items-center rounded border border-solid border-blue_gray-100 bg-white-A700 p-1">
                    <div className="flex flex-col items-center rounded bg-white-A700 p-0.5">
                      <Img src="images/img_user_red_500.svg" alt="image" className="h-[12px] w-[12px]" />
                    </div>
                  </div>
                  <div className="flex gap-1.5 self-start">
                    <Button color="gray_100_01" className="w-[24px] rounded">
                      <Img src="images/img_credit_card_minus.svg" />
                    </Button>
                    <Button color="gray_100_01" className="w-[24px] rounded">
                      <Img src="images/img_thumbs_up.svg" />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[41px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="!font-inter !text-[13.86px] !text-gray-500_03">
                  5
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="image" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="image" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-11 border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-2.5 pl-[7px] pr-1.5 pt-2">
                <Text size="5xl" as="p" className="!font-inter !text-[13.86px] !text-gray-500_03">
                  6
                </Text>
                <div className="flex gap-1.5 self-end">
                  <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                    <Img src="images/img_credit_card_minus.svg" alt="image" className="h-[17px] w-[17px]" />
                  </div>
                  <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                    <Img src="images/img_thumbs_up.svg" alt="image" className="h-[15px]" />
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[41px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pt-2">
                <Text size="5xl" as="p" className="!font-inter !text-[13.86px] !text-gray-500_03">
                  7
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="image" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="image_ten" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-1 md:flex-col">
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="!font-inter !text-[13.86px] !text-gray-500_03">
                  8
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="!font-inter !text-[13.86px] !text-gray-500_03">
                  9
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="h-[17px] w-[16px] !font-inter !text-[13.86px] !text-gray-500_03">
                  10
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="!font-inter !text-[13.86px] !text-gray-500_03">
                  11
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-2.5 border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="!font-inter !text-[13.86px] !text-gray-500_03">
                  12
                </Text>
                <div className="flex flex-col items-end gap-1.5">
                  <Button color="blue_50_04" className="w-[26px] rounded">
                    <Img src="images/img_car_02.svg" />
                  </Button>
                  <div className="flex gap-1.5">
                    <Button color="gray_100_01" className="w-[26px] rounded">
                      <Img src="images/img_credit_card_minus.svg" />
                    </Button>
                    <Button color="gray_100_01" className="w-[26px] rounded">
                      <Img src="images/img_thumbs_up.svg" />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-2.5 border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="h-[17px] w-[16px] !font-inter !text-[13.86px] !text-gray-500_03">
                  13
                </Text>
                <div className="flex flex-col items-end gap-1.5">
                  <Button color="teal_50" className="w-[26px] rounded">
                    <Img src="images/img_currency_dollar_green_a700_01.svg" />
                  </Button>
                  <div className="flex gap-1.5">
                    <Button color="gray_100_01" className="w-[26px] rounded">
                      <Img src="images/img_credit_card_minus.svg" />
                    </Button>
                    <Button color="gray_100_01" className="w-[26px] rounded">
                      <Img src="images/img_thumbs_up.svg" />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pt-2">
                <Text size="5xl" as="p" className="h-[17px] w-[16px] !font-inter !text-[13.86px] !text-gray-500_03">
                  14
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-1 md:flex-col">
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="h-[17px] w-[16px] !font-inter !text-[13.86px] !text-gray-500_03">
                  15
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="h-[17px] w-[16px] !font-inter !text-[13.86px] !text-gray-500_03">
                  16
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="!font-inter !text-[13.86px] !text-gray-500_03">
                  17
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="h-[17px] w-[16px] !font-inter !text-[13.86px] !text-gray-500_03">
                  18
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="h-[17px] w-[16px] !font-inter !text-[13.86px] !text-gray-500_03">
                  19
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="h-[17px] w-[18px] !font-inter !text-[13.86px] !text-gray-500_03">
                  20
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup_eleven" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[15px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pt-2">
                <Text size="5xl" as="p" className="!font-inter !text-[13.86px] !text-gray-500_03">
                  21
                </Text>
                <div className="flex flex-col items-center pr-px pt-px">
                  <Text size="xl" as="p" className="!font-inter !text-[9.24px] !font-medium !text-gray-200_01">
                    -
                  </Text>
                </div>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <Button color="gray_100_01" className="w-[26px] rounded">
                      <Img src="images/img_credit_card_minus.svg" />
                    </Button>
                    <Button color="gray_100_01" className="w-[26px] rounded">
                      <Img src="images/img_thumbs_up.svg" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-1 md:flex-col">
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="h-[17px] w-[17px] !font-inter !text-[13.86px] !text-gray-500_03">
                  22
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="h-[17px] w-[18px] !font-inter !text-[13.86px] !text-gray-500_03">
                  23
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="h-[17px] w-[18px] !font-inter !text-[13.86px] !text-gray-500_03">
                  24
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="h-[17px] w-[18px] !font-inter !text-[13.86px] !text-gray-500_03">
                  25
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="h-[17px] w-[18px] !font-inter !text-[13.86px] !text-gray-500_03">
                  26
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-2.5 border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="h-[17px] w-[17px] !font-inter !text-[13.86px] !text-gray-500_03">
                  27
                </Text>
                <div className="flex flex-col items-end gap-1.5">
                  <Button color="teal_50" className="w-[26px] rounded">
                    <Img src="images/img_currency_dollar_green_a700_01.svg" />
                  </Button>
                  <div className="flex gap-1.5">
                    <Button color="gray_100_01" className="w-[26px] rounded">
                      <Img src="images/img_credit_card_minus.svg" />
                    </Button>
                    <Button color="gray_100_01" className="w-[26px] rounded">
                      <Img src="images/img_thumbs_up.svg" />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[43px] rounded-br-[5px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pt-2">
                <Text size="5xl" as="p" className="h-[17px] w-[18px] !font-inter !text-[13.86px] !text-gray-500_03">
                  28
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-1 md:flex-col">
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="h-[17px] w-[18px] !font-inter !text-[13.86px] !text-gray-500_03">
                  29
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="h-[17px] w-[18px] !font-inter !text-[13.86px] !text-gray-500_03">
                  30
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_01 bg-white-A700 pb-[7px] pl-[7px] pr-1 pt-2">
                <Text size="5xl" as="p" className="!font-inter !text-[13.86px] !text-gray-500_03">
                  31
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_87 bg-white-A700_87 pb-[7px] pl-[7px] pr-1 pt-2 opacity-0.5">
                <Text size="5xl" as="p" className="!font-inter !text-[13.86px] !text-gray-500_03">
                  1
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[43px] border-[0.5px] border-solid border-gray-200_87 bg-white-A700_87 pb-[7px] pl-[7px] pr-1 pt-2 opacity-0.5">
                <Text size="5xl" as="p" className="!font-inter !text-[13.86px] !text-gray-500_03">
                  2
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-2.5 border-[0.5px] border-solid border-gray-200_87 bg-white-A700_87 pb-[7px] pl-[7px] pr-1 pt-2 opacity-0.5">
                <Text size="5xl" as="p" className="!font-inter !text-[13.86px] !text-gray-500_03">
                  3
                </Text>
                <div className="flex flex-col items-end gap-1.5">
                  <Button color="teal_50" className="w-[26px] rounded">
                    <Img src="images/img_currency_dollar_green_a700_01.svg" />
                  </Button>
                  <div className="flex gap-1.5">
                    <Button color="gray_100_01" className="w-[26px] rounded">
                      <Img src="images/img_credit_card_minus.svg" />
                    </Button>
                    <Button color="gray_100_01" className="w-[26px] rounded">
                      <Img src="images/img_thumbs_up.svg" />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col items-start gap-[43px] rounded-br-[5px] border-[0.5px] border-solid border-gray-200_87 bg-white-A700_87 pb-[7px] pl-[7px] pt-2 opacity-0.5">
                <Text size="5xl" as="p" className="!font-inter !text-[13.86px] !text-gray-500_03">
                  4
                </Text>
                <div className="flex self-end">
                  <div className="flex gap-1.5">
                    <div className="flex flex-col items-center rounded bg-gray-100_01 p-1">
                      <Img src="images/img_credit_card_minus.svg" alt="creditcard" className="h-[17px] w-[17px]" />
                    </div>
                    <div className="flex flex-col items-center rounded bg-gray-100_01 px-1 py-[5px]">
                      <Img src="images/img_thumbs_up.svg" alt="thumbsup" className="h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
