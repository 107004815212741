import React from "react";
import { Helmet } from "react-helmet";
import { Text, Heading } from "../../../../components";
import RegisterColumniAmA from "../../../../components/RegisterColumniAmA";
import { useNavigate } from 'react-router-dom';
import TopNavLogin from "components/TopNavLogin";
import LoginIPadOne from "components/LoginIPadOne";

export default function RegisterPage() {
  const navigate = useNavigate();

  const handleRegisterClickStudent = () => {
    navigate('/register/student');
  };
  const handleRegisterClickTeacher = () => {
    navigate('/register/teacher');
  };
  const handleLoginClickLogin = () => {
    navigate('/LoginPage');
  };

  return (
    <>
      <Helmet>
        <title>intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full flex-col gap-2 bg-white-A700">
        <TopNavLogin />
        <div className="flex items-center gap-7 md:flex-col">
          <div className="flex flex-1 flex-col items-center gap-[87px] pb-64 md:gap-[65px] md:self-stretch md:p-5 sm:gap-[43px]">
            <div className="flex flex-col items-center gap-[58px] self-stretch sm:gap-[29px]">
              <div className="flex flex-col items-center gap-[7px] self-stretch px-[123px] md:px-5">
                <Heading size="15xl" as="h1" className="text-center !font-poppins !text-gray-900_05">
                  Ready to Join Us?
                </Heading>
                <Text as="p" className="w-full text-center leading-[130%] !text-gray-900_05">
                  It all begins with you. Create an online classroom environment that cultivates learning, curiosity,
                  and collaboration.
                </Text>
              </div>
              <div className="flex w-[91%] gap-16 md:w-full md:flex-col">
                <RegisterColumniAmA
                  userimage="/images/img_avatars_3d_avatar_26.png"
                  label="I am a Student"
                  header="Class code might be required. Contact your teacher"
                  onClick={handleRegisterClickStudent}
                />
                <RegisterColumniAmA
                  userimage="/images/img_avatars_3d_avatar_20.png"
                  label="I am a Teacher"
                  header="Set up your classroom here"
                  onClick={handleRegisterClickTeacher}
                />
              </div>
            </div>
              <Text as="p" className="!font-inter !font-medium">
                <span className="text-gray-900_07">&nbsp;</span>
                <a onClick={handleLoginClickLogin} href="/loginpage" className="cursor-pointer">
                  <Text as="p" className="!font-inter !font-medium">
                    <span className="text-gray-900_07">Already have an account?</span>
                    <span className="text-gray-900_07">&nbsp;</span>
                    <span className="text-blue-A200">Login&nbsp;</span>
                  </Text>
                </a>

              </Text>
          </div>
          <LoginIPadOne />
        </div>
      </div>
    </>
  );
}
