import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { Img } from 'components';
import { useNavigate } from 'react-router-dom';
import { createClassroom, createShareCode } from 'TeacherAPIService';

const ClassPopup = ({ open, onClose, onClassCreated }) => {
    const [isClassCreated, setIsClassCreated] = useState(false);
    const [classDetails, setClassDetails] = useState({});
    const [className, setClassName] = useState('');
    const [grade, setGrade] = useState('');
    const [studentBalance, setStudentBalance] = useState('');
    const [loading, setLoading] = useState(false);
    const [classCode, setClassCode] = useState('');

    const navigate = useNavigate(); // Get the navigate function from react-router

    const handleCreateClass = async () => {
        setLoading(true);
        try {
            const classData = {
                studentBalance: parseInt(studentBalance, 10),
                name: className,
                grade: grade,
                status: "Active"
            };

            const response = await createClassroom(classData);

            // Handle the case where the response is unauthorized
            if (response.status === 401) {
                navigate('/timeout');
                return;
            }

            const classCodeResponse = await createShareCode(response.id);

            if (classCodeResponse.status === 401) {
                navigate('/timeout');
                return;
            }

            setClassDetails(response);
            setClassCode(classCodeResponse.token);
            setIsClassCreated(true);
            onClassCreated();
        } catch (error) {
            console.error('Error creating class:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleBackToCreateClass = () => {
        setIsClassCreated(false);
        setClassName('');
        setGrade('');
        setStudentBalance('');
        setClassCode('');
        onClose();
    };

    const handleStudentBalanceChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setStudentBalance(value);
        }
    };

    return (
        <Popup open={open} closeOnDocumentClick onClose={onClose} modal contentStyle={{ width: '400px', height: '450px', borderRadius: '1rem' }}>
            <div className="flex flex-col items-center justify-center h-full p-4">
                {!isClassCreated ? (
                    <div className='flex flex-col gap-4'>
                        <div className='flex flex-col bg-blue-500 items-center rounded-lg p-4 gap-2'>
                            <Img src="/images/img_avatars_3d_avatar_26.png" alt="Create Class" className="h-36 w-36" />
                            <span className='text-white-A700 text-2xl'>Create a Class</span>
                        </div>
                        <div className='border rounded-lg border-gray-300'>
                            <input
                                type="text"
                                placeholder="Enter Class Name"
                                value={className}
                                onChange={(e) => setClassName(e.target.value)}
                                className="w-full border rounded-lg placeholder-gray-600 m-3"
                            />
                            <input
                                type="text"
                                placeholder="Enter Grade"
                                value={grade}
                                onChange={(e) => setGrade(e.target.value)}
                                className="w-full border rounded-lg placeholder-gray-600 m-3"
                            />
                            <input
                                type="text"
                                placeholder="Enter Starting Stock Simulator Balance"
                                value={studentBalance}
                                onChange={handleStudentBalanceChange}
                                className="w-full border rounded-lg placeholder-gray-600 m-3"
                            />
                        </div>
                        <div className="flex justify-end">
                            <button onClick={onClose} className="mr-2 px-4 py-2 border border-blue-500 rounded text-blue-500">Cancel</button>
                            <button onClick={handleCreateClass} className="px-4 py-2 bg-blue-500 text-white-A700 rounded" disabled={loading}>
                                {loading ? 'Creating...' : 'Create Class'}
                            </button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className='flex flex-col gap-4 items-center justify-center'>
                            <span className='text-5xl text-green-600 rounded-full border p-4 w-[85px] text-center'>✓</span>
                            <h2 className="text-center font-semibold">Class Created</h2>
                        </div>
                        <div className="mt-4 border p-4 rounded">
                            <span className='text-xl'>Class Details</span>
                            <p><strong>Class Name:</strong> {classDetails.name}</p>
                            <p><strong>Grade:</strong> {classDetails.grade}</p>
                            <p><strong>Student Balance:</strong> {classDetails.studentBalance}</p>
                            <p><strong>Status:</strong> {classDetails.status}</p>
                            <p><strong>Class Code:</strong> {classCode}</p>
                        </div>
                        <div className="mt-4 flex justify-between">
                            <button onClick={handleBackToCreateClass} className="px-4 py-2 border border-gray-300 rounded">Close</button>
                        </div>
                    </div>
                )}
            </div>
        </Popup>
    );
};

export default ClassPopup;
