import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { Text, Heading } from "../../../../components";
import TopNavLogin from "../../../../components/TopNavLogin";
import LoginIPadOne from "components/LoginIPadOne";
import Form from "./LoginForm";

export default function LoginPagePage() {
  const navigate = useNavigate();

  const handleClickRegister = () => {
    navigate('/Register');
  };
  const handleLoginClick = () => {
    navigate('/home1');
  };

  return (
    <>
      <Helmet>
        <title>Login | Intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="w-full bg-white-A700">
        <TopNavLogin />
        <div className="flex justify-center">
          <div className="flex items-center justify-between gap-5 pl-[15px] md:flex-col md:p-5">
            <div className="flex w-[42%] flex-col pb-80 gap-9 md:w-full">
              <div className="flex flex-col items-start gap-[9px]">
                <Heading size="15xl" as="h1" className="!text-gray-900_05">
                  Welcome Back!
                </Heading>
                  <a onClick={handleClickRegister} className="cursor-pointer">
                    <Text as="p" className="!font-inter !font-medium">
                      <span className="text-gray-900_07">Don't have an account?</span>
                      <span className="text-gray-900_07">&nbsp;</span>
                      <span className="text-blue-A200">Sign up&nbsp;</span>
                    </Text>
                  </a>
              </div>
              <Form />
            </div>
            <LoginIPadOne />
          </div>
        </div>
      </div>
    </>
  );
}
