import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Img, Text, Heading } from "../../components";
import { useNavigate } from "react-router-dom";
import {
  AccordionItemPanel,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemState,
  Accordion,
  AccordionItem,
} from "react-accessible-accordion";

export default function LearningViewTextPage() {
  const navigate = useNavigate();
  const [expandedItems, setExpandedItems] = useState([]);

  const handleBacktoCourses = () => {
    navigate('/courses');
  };

  const handleFinancialResponsibilityClick = () => {
    navigate('/learningview');
  };


  const handleLessonQuizClick = () => {
    navigate('/learningviewquestionone');
  };

  const toggleItem = (index) => {
    const currentIndex = expandedItems.indexOf(index);
    const newExpandedItems = [...expandedItems];

    if (currentIndex === -1) {
      newExpandedItems.push(index);
    } else {
      newExpandedItems.splice(currentIndex, 1);
    }

    setExpandedItems(newExpandedItems);
  };

  return (
    <>
      <Helmet>
        <title>intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="w-full bg-white-A700">
        <div className="bg-white-A700">
          <header className="flex items-center justify-center rounded-lg px-4 pt-4 w-full">
            <div className="container-lg flex rounded-lg border border-solid border-indigo-50 py-2.5 justify-between">
              <div className="flex w-full items-center justify-start gap-6 pt-2.5">
                <div onClick={handleBacktoCourses} className="flex items-center cursor-pointer">
                  <Img
                    src="images/img_arrow_left_blue_a200.svg"
                    alt="arrowleft"
                    className="relative right-px top-0.5 h-[20px] mr-[-20px] ml-5"
                  />
                  <Text size="7xl" as="p" className="!text-blue-500 mt-1">
                    Back to all Courses
                  </Text>
                </div>
                <div className="flex items-center gap-6 ml-auto mr-auto">
                  <div className="flex items-center rounded border border-solid border-deep_purple-A200_01 bg-gray-50 p-[4px]">
                    <Heading as="p" className="!text-deep_purple-A200_01">
                      Unit 1
                    </Heading>
                  </div>
                  <Heading size="13xl" as="h2" className="text-center">
                    Financial Responsibility
                  </Heading>
                </div>
              </div>
            </div>
          </header>
          <div className="container-lg flex gap-2.5 md:flex-col md:p-5 pt-5">
            <div className="w-[26%] rounded-lg border border-solid border-indigo-50 bg-white-300_01 md:w-full">
              <Accordion allowZeroExpanded preExpanded={expandedItems} onChange={setExpandedItems} className="flex flex-col gap-2 rounded-lg bg-white-A700">
                {[...Array(5)].map((_, i) => (
                  <AccordionItem uuid={i} key={`row${i}`} className="border-b border-solid border-gray-300">
                    <div className="flex flex-1 flex-col gap-2">
                      <AccordionItemHeading className="w-full" onClick={() => toggleItem(i)}>
                        <AccordionItemButton className="py-4">
                          <AccordionItemState>
                            {(props) => (
                              <>
                                <div className="bg-white-A700 flex items-center justify-between px-4">
                                  <Img
                                    src="images/img_arrow_up_black_900.svg"
                                    alt="arrowdown"
                                    className={`h-[24px] w-[24px] ${props.expanded ? "" : "rotate-180"} mr-2`}
                                  />
                                  <Heading size="6xl" as="h1" className="w-auto leading-[130%]">
                                    Definition of Financial Responsibility
                                  </Heading>
                                  {props.expanded ? (
                                    <Img
                                      src="images/img_check_circle.svg"
                                      alt="image"
                                      className="h-[24px] w-[24px] ml-auto"
                                    />
                                  ) : (
                                    <Img
                                      src="images/img_check_circle_blue_gray_100_03.svg"
                                      alt="image"
                                      className="h-[24px] w-[24px] ml-auto"
                                    />
                                  )}
                                </div>
                              </>
                            )}
                          </AccordionItemState>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="bg-white-A700 px-4">
                          <div className="rounded-tl-lg rounded-tr-lg bg-indigo-50 px-4">
                            <div className="flex items-center justify-between gap-5 border-b border-solid border-indigo-50 py-5">
                              <Heading size="6xl" as="h2" className="!font-bold">
                                Introduction
                              </Heading>
                              <Img
                                src="images/img_ondemand_video.svg"
                                alt="ondemandvideo"
                                className="h-[24px] w-[24px]"
                              />
                            </div>
                          </div>
                          <a target="_blank">
                            <div className="bg-gray-50_01 px-4 cursor-pointer" onClick={handleFinancialResponsibilityClick}>
                              <div className="flex items-center justify-between gap-5 border-b border-solid border-indigo-50 pb-[19px] pt-5 cursor-pointer">
                                <Text size="7xl" as="p" className="self-end !text-black-900">
                                  Financial Responsibility
                                </Text>
                                <Img
                                  src="images/img_library_books_gray_800_01.svg"
                                  alt="librarybooks"
                                  className="h-[24px] w-[24px] self-start"
                                />
                              </div>
                            </div>
                          </a>
                          <div className="bg-gray-50_01 px-4">
                            <Button
                              color="indigo_50"
                              size="7xl"
                              variant="outline"
                              shape="square"
                              rightIcon={
                                <Img
                                  src="images/img_library_books_gray_800_01.svg"
                                  alt="library books"
                                  className="h-[24px] w-[24px]"
                                />
                              }
                              className="w-full gap-[11px]"
                            >
                              Benefits of Financial Responsibility
                            </Button>
                          </div>
                          <div onClick={handleLessonQuizClick} className="flex items-center cursor-pointer justify-between gap-5 rounded-bl-lg rounded-br-lg bg-gray-50_01 px-4 pb-[19px] pt-5">
                            <Text size="7xl" as="p" className="self-end !text-black-900">
                              Lesson Quiz
                            </Text>
                            <Img src="images/img_bookmark.svg" alt="bookmark" className="h-[24px] w-[24px]" />
                          </div>
                          <div style={{ marginBottom: '20px' }}></div>
                        </div>
                      </AccordionItemPanel>
                    </div>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
            <div className="flex flex-1 flex-col items-start gap-4 rounded-lg px-2 md:self-stretch">
              <Button
                size="3xl"
                variant="outline"
                leftIcon={<Img src="images/img_megaphone.svg" alt="megaphone" className="h-[18px] w-[18px]" />}
                className="min-w-[146px] gap-2 rounded-[20px] font-medium tracking-[0.10px] sm:pr-5"
                onClick={() => setExpandedItems([])}
              >
                Close Menu
              </Button>
              <div className="self-stretch rounded-lg border border-solid border-indigo-50 bg-white-A700 p-2">
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col items-start gap-3.5 rounded-lg p-2">
                    <div className="flex">
                      <Heading size="11xl" as="h3" className="!font-poppins">
                        Benefits of Financial Responsibility
                      </Heading>
                    </div>
                    <Text as="p" className="!font-poppins !text-gray-900_07">
                      Why is this Important ?
                    </Text>
                    <Text size="6xl" as="p" className="w-full leading-[170%] tracking-[0.25px] !text-blue_gray-900">
                      <>
                        Financial responsibility is an essential skill for individuals, families, and businesses to
                        ensure a secure and prosperous future. It is the process of managing money and assets in a way
                        that aligns with the best interests of an individual, family, or business. It involves making
                        informed decisions about spending, saving, investing, and budgeting. By exercising financial
                        responsibility, individuals can achieve financial well-being and prepare for their future
                        needs.
                        <br />
                        <br />
                        Financial responsibility goes beyond meeting the immediate needs and desires of the present.
                        It requires individuals to consider their long-term goals and plan accordingly. By taking a
                        proactive approach to managing finances, individuals can build a solid foundation for their
                        financial future and navigate potential challenges more effectively.
                      </>
                    </Text>
                  </div>
                  <div className="rounded-lg p-2">
                    <Text size="6xl" as="p" className="leading-[170%] tracking-[0.25px] !text-blue_gray-900">
                      <a href="#" className="font-bold text-blue_gray-900 underline">
                        <>
                          Key Concepts of Financial Responsibility
                          <br />
                        </>
                      </a>
                      <span className="font-bold text-blue_gray-900">Budgeting</span>
                      <span className="text-blue_gray-900">
                        <>
                          : Creating a budget helps individuals track their income and expenses, allowing them to make
                          conscious decisions about their spending habits.
                          <br />
                        </>
                      </span>
                      <span className="font-bold text-blue_gray-900">Saving</span>
                      <span className="text-blue_gray-900">
                        <>
                          : Setting aside a portion of income regularly helps build an emergency fund and work towards
                          long-term financial goals.
                          <br />
                        </>
                      </span>
                      <span className="font-bold text-blue_gray-900">Investing</span>
                      <span className="text-blue_gray-900">
                        <>
                          : Investing money can generate additional income and grow wealth over time. It is important
                          to understand different investment options and risks involved.
                          <br />
                        </>
                      </span>
                      <span className="font-bold text-blue_gray-900">
                        <>
                          Debt Management: Effectively managing debt involves understanding interest rates, repayment
                          plans, and avoiding unnecessary debt whenever possible.
                          <br />
                          <br />
                          Financial responsibility is crucial for individuals of all ages. It empowers them to make
                          informed decisions and avoid financial pitfalls. Whether you&#39;re a student, a young adult
                          starting your career, or a parent managing a household budget, practicing financial
                          responsibility can lead to a more stable and fulfilling life. For families, financial
                          responsibility includes teaching children about money management from an early age. By
                          instilling good financial habits and values, parents can equip their children with essential
                          skills for their future financial well-being.
                        </>
                      </span>
                    </Text>
                  </div>
                </div>
                <div className="flex justify-between gap-5 bg-white-A700 p-2.5">
                  <Button
                    size="3xl"
                    variant="outline"
                    leftIcon={
                      <Img
                        src="images/img_arrowleft_blue_a200_1.svg"
                        alt="arrow_left"
                        className="h-[18px] w-[18px]"
                      />
                    }
                    className="min-w-[99px] gap-2 rounded-[20px] font-medium tracking-[0.10px] !text-blue-A400 sm:pr-5 cursor-pointer" onClick={handleFinancialResponsibilityClick}
                  >
                    Back
                  </Button>
                  <Button
                    size="3xl"
                    rightIcon={
                      <Img
                        src="images/img_arrowright_white_a700.svg"
                        alt="arrow_right"
                        className="h-[18px] w-[18px]"
                      />
                    }
                    className="min-w-[98px] gap-2 rounded-[20px] font-medium tracking-[0.10px] sm:pr-5 cursor-pointer" onClick={handleLessonQuizClick}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
