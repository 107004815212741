import React from "react";
import { Text, Img, Heading } from "./..";

export default function PerformanceCoursesAnalytics({
  titleSix = "Average Performance",
  labelTwelve = "Marcus Schleifer",
  labelThirteen = "Class Average",
  moduletitle = "Module",
  modulenumber1 = "1",
  modulenumber2 = "2",
  modulenumber3 = "3",
  modulenumber4 = "4",
  modulenumber5 = "5",
  modulenumber6 = "6",
  modulenumber7 = "7",
  modulenumber8 = "8",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col pt-[11px] pb-[13px] gap-[7px] px-[11px] border-blue_gray-50_01 border border-solid bg-white-A700 rounded-lg`}
    >
      <div className="flex items-start justify-between gap-5 self-stretch sm:flex-col">
        <Heading size="6xl" as="h6" className="!text-gray-900_cc">
          {titleSix}
        </Heading>
        <div className="flex w-[26%] justify-center gap-3 sm:w-full">
          <div className="flex w-full items-center justify-between gap-5">
            <div className="h-[17px] w-[17px] bg-blue-900_01" />
            <div className="flex py-2">
              <Text size="2xl" as="p" className="!font-roboto !text-[10.09px] tracking-[0.07px] !text-gray-800_01">
                {labelTwelve}
              </Text>
            </div>
          </div>
          <div className="flex w-full items-center justify-between gap-5">
            <div className="h-[17px] w-[17px] bg-blue-50_02" />
            <div className="flex pb-[7px] pt-[9px]">
              <Text size="2xl" as="p" className="!font-roboto !text-[10.09px] tracking-[0.07px] !text-gray-800_01">
                {labelThirteen}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-10 self-stretch">
        <Img src="images/img_group_45.svg" alt="image" className="h-[111px] w-[95%]" />
        <div className="flex flex-wrap items-center justify-between gap-5">
          <Text size="4xl" as="p" className="!font-roboto !font-medium !text-black-900">
            {moduletitle}
          </Text>
          <Text size="lg" as="p" className="self-end !font-roboto !font-medium !text-black-900">
            {modulenumber1}
          </Text>
          <Text size="lg" as="p" className="self-end !font-roboto !font-medium !text-black-900">
            {modulenumber2}
          </Text>
          <Text size="lg" as="p" className="self-end !font-roboto !font-medium !text-black-900">
            {modulenumber3}
          </Text>
          <Text size="lg" as="p" className="self-end !font-roboto !font-medium !text-black-900">
            {modulenumber4}
          </Text>
          <Text size="lg" as="p" className="self-end !font-roboto !font-medium !text-black-900">
            {modulenumber5}
          </Text>
          <Text size="lg" as="p" className="self-end !font-roboto !font-medium !text-black-900">
            {modulenumber6}
          </Text>
          <Text size="lg" as="p" className="self-end !font-roboto !font-medium !text-black-900">
            {modulenumber7}
          </Text>
          <Text size="lg" as="p" className="self-end !font-roboto !font-medium !text-black-900">
            {modulenumber8}
          </Text>
        </div>
      </div>
    </div>
  );
}
