import React from "react";
import { CloseSVG } from "../../assets/images";
import { Text, Heading, Img, Button, Input } from "../../components";
import LoginPageColumnTwo from "../../components/LoginPageColumnTwo";

const data = [
    { prevclose: "Prev Close", text: "292.00" },
    { prevclose: "High", text: "292.00" },
    { prevclose: "Avg. Volume", text: "16.1M" },
    { prevclose: "P/E Ratio (TTM)", text: "292.00" },
    { prevclose: "52 Week High", text: "292.00" },
];

export default function LoginIPadOne() {
    return <div className="w-[46%] bg-deep_purple-100 pb-[203px] pl-14 pt-[169px] md:w-full md:p-5 md:py-5 md:pl-5">
        <div className="flex rounded-lg border-[16px] border-solid border-gray-900_06 bg-white-A700 shadow-lg sm:flex-col">
            <div className="flex w-[9%] flex-col gap-[125px] border-r border-solid border-blue_gray-100 bg-white-A700 pb-[13px] pt-2 md:gap-[93px] sm:w-full sm:gap-[62px] sm:p-5">
                <div className="flex flex-col items-center justify-center gap-[13px] pb-[146px] md:pb-5">
                    <Img src="/images/img_brand.svg" alt="brand" className="h-[17px] w-[17px]" />
                    <div className="flex flex-col gap-2 self-stretch">
                        <div className="flex flex-col items-center px-1 pb-px opacity-0.8">
                            <Img src="/images/img_container.svg" alt="container" className="h-[22px] w-[22px] rounded-[50%]" />
                            <Heading size="s" as="h2" className="!text-[6.61px] !text-gray-800_01">
                                Dashboard
                            </Heading>
                        </div>
                        <div className="flex flex-col items-center px-[9px] pb-px">
                            <Button color="blue_50_04" size="sm" className="w-[22px] rounded">
                                <Img src="/images/img_user.svg" />
                            </Button>
                            <Heading size="s" as="h3" className="!text-[6.61px]">
                                Courses
                            </Heading>
                        </div>
                        <div className="flex flex-col items-center gap-0.5 px-px pb-0.5 opacity-0.8">
                            <Img
                                src="/images/img_container_gray_800_01.svg"
                                alt="container"
                                className="h-[22px] w-[22px] rounded-[50%]"
                            />
                            <Heading
                                size="s"
                                as="h4"
                                className="w-full text-center !text-[6.61px] leading-[9px] !text-gray-800_01"
                            >
                                Stock Simulator
                            </Heading>
                        </div>
                        <div className="flex flex-col items-center gap-0.5 px-px pb-0.5 opacity-0.8">
                            <Img
                                src="/images/img_container_gray_800_01_22x22.svg"
                                alt="container"
                                className="h-[22px] w-[22px] rounded-[50%]"
                            />
                            <Heading
                                size="s"
                                as="h5"
                                className="w-full text-center !text-[6.61px] leading-[9px] !text-gray-800_01"
                            >
                                Budget Simulator
                            </Heading>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center gap-2 px-1.5 opacity-0.8">
                    <Img src="/images/img_icon.svg" alt="icon" className="h-[13px] w-[13px]" />
                    <div className="flex flex-col items-center gap-0.5 self-stretch">
                        <Img
                            src="/images/img_avatars_3d_avatar_13.png"
                            alt="avatars3d"
                            className="h-[17px] w-[17px] object-cover"
                        />
                        <div className="h-[9px] w-[35px] self-stretch rounded bg-blue_gray-50" />
                    </div>
                </div>
            </div>
            <div className="w-[91%] sm:w-full sm:p-5">
                <div>
                    <div className="flex border-b border-solid border-blue_gray-100 bg-white-A700 px-2">
                        <div className="flex bg-white-A700">
                            <Heading size="8xl" as="h6">
                                Good evening 🎑 Lets make student take informed Financial decisions 👏
                            </Heading>
                        </div>
                    </div>
                    <div className="flex items-start gap-1 sm:flex-col">
                        <div className="w-[42%] sm:w-full">
                            <Input
                                disabled={true}
                                size="xs"
                                name="search"
                                placeholder={`Search Stock eg. META, MSFT ...`}
                                prefix={
                                    <div className="flex h-[22px] w-[22px] items-center justify-center rounded-[50%]">
                                        <Img
                                            src="/images/img_search_blue_gray_100_13x13.svg"
                                            alt="search"
                                            className="h-[13px] w-[13px] cursor-pointer"
                                        />
                                    </div>
                                }
                                className="gap-1 rounded-tl rounded-tr border border-solid border-blue_gray-100 font-roboto tracking-[0.50px] !text-blue_gray-100 sm:pr-5"
                            />
                            <div className="flex flex-col justify-center gap-[5px] rounded-bl rounded-br border-b border-l border-r border-solid border-blue_gray-100 pb-[272px] md:pb-5">
                                <div className="flex self-start pb-[9px] pt-2">
                                    <Heading size="lg" as="p" className="!text-[8.81px]">
                                        Recent Searches
                                    </Heading>
                                </div>
                                <div className="flex flex-col gap-[5px]">
                                    {/* Green */}
                                    <div className="flex flex-1 border-b border-solid border-blue_gray-100">
                                        <div className="flex-1 bg-white-A700">
                                            <Img src="/images/img_television.svg" alt="image" className="h-[30px] w-full md:h-auto" />
                                        </div>
                                        <div className="flex flex-col bg-white-A700">
                                            <Img src="/images/img_television.svg" alt="image" className="h-[30px]" />
                                        </div>
                                        <div className="flex flex-col bg-white-A700">
                                            <Img src="/images/img_television.svg" alt="image" className="h-[30px]" />
                                        </div>
                                        <div className="flex flex-1 flex-col bg-white-A700">
                                            <div className="flex gap-2 py-2 pr-2">
                                                <Img src="/images/img_arrow_up.svg" alt="image" className="h-[13px] w-[13px]" />
                                                <div className="h-[13px] w-[26px] flex-1 rounded-md bg-green-A700_01" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-1 border-b border-solid border-blue_gray-100">
                                        <div className="flex-1 bg-white-A700">
                                            <Img
                                                src="/images/img_television.svg"
                                                alt="television"
                                                className="h-[30px] w-full md:h-auto"
                                            />
                                        </div>
                                        <div className="flex flex-col bg-white-A700">
                                            <Img src="/images/img_television.svg" alt="television" className="h-[30px]" />
                                        </div>
                                        <div className="flex flex-col bg-white-A700">
                                            <Img src="/images/img_television.svg" alt="television" className="h-[30px]" />
                                        </div>
                                        <div className="flex-1 bg-white-A700">
                                            <div className="flex justify-center gap-2 py-2 pr-2">
                                                <Img
                                                    src="/images/img_arrow_up_red_500.svg"
                                                    alt="arrowup"
                                                    className="h-[13px] w-[13px]"
                                                />
                                                <div className="h-[13px] w-[26px] flex-1 rounded-md bg-red-500" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-1 border-b border-solid border-blue_gray-100">
                                        <div className="flex-1 bg-white-A700">
                                            <Img
                                                src="/images/img_television.svg"
                                                alt="television"
                                                className="h-[30px] w-full md:h-auto"
                                            />
                                        </div>
                                        <div className="flex flex-col bg-white-A700">
                                            <Img src="/images/img_television.svg" alt="television" className="h-[30px]" />
                                        </div>
                                        <div className="flex flex-col bg-white-A700">
                                            <Img src="/images/img_television.svg" alt="television" className="h-[30px]" />
                                        </div>
                                        <div className="flex-1 bg-white-A700">
                                            <div className="flex justify-center gap-2 py-2 pr-2">
                                                <Img
                                                    src="/images/img_arrow_up_red_500.svg"
                                                    alt="arrowup"
                                                    className="h-[13px] w-[13px]"
                                                />
                                                <div className="h-[13px] w-[26px] flex-1 rounded-md bg-red-500" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Green */}
                                    <div className="flex flex-1 border-b border-solid border-blue_gray-100">
                                        <div className="flex-1 bg-white-A700">
                                            <Img src="/images/img_television.svg" alt="image" className="h-[30px] w-full md:h-auto" />
                                        </div>
                                        <div className="flex flex-col bg-white-A700">
                                            <Img src="/images/img_television.svg" alt="image" className="h-[30px]" />
                                        </div>
                                        <div className="flex flex-col bg-white-A700">
                                            <Img src="/images/img_television.svg" alt="image" className="h-[30px]" />
                                        </div>
                                        <div className="flex flex-1 flex-col bg-white-A700">
                                            <div className="flex gap-2 py-2 pr-2">
                                                <Img src="/images/img_arrow_up.svg" alt="image" className="h-[13px] w-[13px]" />
                                                <div className="h-[13px] w-[26px] flex-1 rounded-md bg-green-A700_01" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-[58%] bg-white-A700 sm:w-full">
                            <div className="flex flex-col gap-2 rounded border border-solid border-blue_gray-100 pb-2">
                                <div className="flex justify-end border-b border-solid border-blue_gray-100">
                                    <div className="flex w-[60%] justify-end md:w-full">
                                        <Img src="/images/img_tab_2.svg" alt="tabtwo" className="h-[30px] w-[32%]" />
                                        <Img src="/images/img_state_layer.svg" alt="statelayer" className="h-[30px]" />
                                        <Img src="/images/img_state_layer_blue_gray_50.svg" alt="statelayer" className="h-[30px]" />
                                        <div className="w-[17%] pl-2">
                                            <div className="py-[9px]">
                                                <Img
                                                    src="/images/img_content_12x21.png"
                                                    alt="content"
                                                    className="h-[12px] w-full rounded-md object-cover"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="relative z-[8] flex flex-col items-start gap-0.5 px-2">
                                        <div className="flex">
                                            <Heading size="lg" as="p" className="!text-[8.81px]">
                                                Meta Inc (META)
                                            </Heading>
                                        </div>
                                        <div className="flex w-[48%] items-center md:w-full">
                                            <Heading size="7xl" as="h6" className="!text-[17.63px]">
                                                172.01
                                            </Heading>
                                            <Text
                                                size="lg"
                                                as="p"
                                                className="ml-[7px] w-[19%] !text-[8.81px] leading-[13px] tracking-[0.28px]"
                                            >
                                                -0.2%
                                            </Text>
                                            <div className="flex items-center gap-1">
                                                <Img
                                                    src="/images/img_arrow_down_red_500.svg"
                                                    alt="arrowdown"
                                                    className="h-[13px] w-[13px]"
                                                />
                                                <div className="flex py-px pr-px">
                                                    <Text size="lg" as="p" className="!text-[8.81px] tracking-[0.28px] !text-red-500">
                                                        -250.2
                                                    </Text>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative mt-[-44px] flex flex-col gap-[13px] rounded bg-white-A700 pb-2 pt-[13px]">
                                        <div className="flex flex-col items-start gap-2 blur-[4.00px] backdrop-opacity-[0.5]">
                                            <Img
                                                src="/images/img_image_146.png"
                                                alt="image146"
                                                className="h-[122px] w-full object-cover md:h-auto"
                                            />
                                            <div className="flex w-[67%] gap-2 md:w-full">
                                                <Button
                                                    color="gray_100_01"
                                                    size="xs"
                                                    className="w-full flex-1 rounded font-semibold tracking-[0.06px]"
                                                >
                                                    1D
                                                </Button>
                                                <div className="flex rounded-[11px]">
                                                    <div className="flex rounded-[11px] px-1 pb-1.5 pt-1">
                                                        <Text
                                                            size="md"
                                                            as="p"
                                                            className="h-[11px] w-[12px] !text-[7.71px] tracking-[0.06px]"
                                                        >
                                                            1W
                                                        </Text>
                                                    </div>
                                                </div>
                                                <div className="flex rounded-[11px]">
                                                    <div className="flex rounded-[11px] px-1 pb-1.5 pt-1">
                                                        <Text
                                                            size="md"
                                                            as="p"
                                                            className="h-[11px] w-[11px] !text-[7.71px] tracking-[0.06px]"
                                                        >
                                                            1M
                                                        </Text>
                                                    </div>
                                                </div>
                                                <div className="flex rounded-[11px]">
                                                    <div className="flex rounded-[11px] px-1 pb-1.5 pt-1">
                                                        <Text size="md" as="p" className="!text-[7.71px] tracking-[0.06px]">
                                                            1Y
                                                        </Text>
                                                    </div>
                                                </div>
                                                <div className="flex rounded-[11px]">
                                                    <div className="flex rounded-[11px] px-1 pb-1.5 pt-1">
                                                        <Text size="md" as="p" className="!text-[7.71px] tracking-[0.06px]">
                                                            5Y
                                                        </Text>
                                                    </div>
                                                </div>
                                                <div className="flex rounded-[11px]">
                                                    <div className="flex rounded-[11px] px-1 pb-1.5 pt-1">
                                                        <Text size="md" as="p" className="!text-[7.71px] tracking-[0.06px]">
                                                            All
                                                        </Text>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex py-2 sm:flex-col">
                                            <div className="flex flex-1 flex-col items-end gap-[5px] overflow-auto border-r border-solid border-blue_gray-100 pb-[9px] pt-2 blur-[4.00px] backdrop-opacity-[0.5] sm:self-stretch">
                                                <div className="flex self-start">
                                                    <Heading size="lg" as="p" className="!text-[8.81px]">
                                                        Day’s Range
                                                    </Heading>
                                                </div>
                                                <Img
                                                    src="/images/img_image_157.png"
                                                    alt="image157"
                                                    className="mr-2 h-[22px] w-full object-cover md:mr-0 md:h-auto"
                                                />
                                                <div className="flex w-full items-start justify-between gap-5 pb-[7px] pt-2">
                                                    <div className="flex w-[25%] flex-col gap-2">
                                                        <div className="flex flex-wrap justify-between gap-5">
                                                            <Heading size="s" as="p" className="self-end !text-[6.61px] !text-gray-600_a8">
                                                                Open
                                                            </Heading>
                                                            <Heading size="s" as="p" className="self-start !text-[6.61px] !text-gray-900_03">
                                                                292.00
                                                            </Heading>
                                                        </div>
                                                        <div className="flex flex-wrap justify-between gap-5">
                                                            <Heading size="s" as="p" className="!text-[6.61px] !text-gray-600_a8">
                                                                Low
                                                            </Heading>
                                                            <Heading size="s" as="p" className="!text-[6.61px] !text-gray-900_03">
                                                                292.00
                                                            </Heading>
                                                        </div>
                                                        <div className="flex flex-wrap justify-between gap-5">
                                                            <Heading size="s" as="p" className="!text-[6.61px] !text-gray-600_a8">
                                                                Volume
                                                            </Heading>
                                                            <Heading size="s" as="p" className="!text-[6.61px] !text-gray-900_03">
                                                                3.8M
                                                            </Heading>
                                                        </div>
                                                        <div className="flex flex-wrap justify-between gap-5">
                                                            <Heading size="s" as="p" className="self-end !text-[6.61px] !text-gray-600_a8">
                                                                Avg. Price
                                                            </Heading>
                                                            <Heading size="s" as="p" className="self-start !text-[6.61px] !text-gray-900_03">
                                                                292.00
                                                            </Heading>
                                                        </div>
                                                        <div className="flex flex-wrap gap-[22px] self-start">
                                                            <Heading size="s" as="p" className="!text-[6.61px] !text-gray-600_a8">
                                                                52 Week Low
                                                            </Heading>
                                                            <Heading size="s" as="p" className="!text-[6.61px] !text-gray-900_03">
                                                                292.00
                                                            </Heading>
                                                        </div>
                                                        <div className="flex flex-wrap justify-between gap-5">
                                                            <Heading size="s" as="p" className="self-end !text-[6.61px] !text-gray-600_a8">
                                                                Market Cap
                                                            </Heading>
                                                            <Heading size="s" as="p" className="self-start !text-[6.61px] !text-gray-900_03">
                                                                1.5T
                                                            </Heading>
                                                        </div>
                                                    </div>
                                                    <div className="flex w-[63%] flex-col gap-[7px]">
                                                        <div className="flex w-[43%] flex-col gap-2 md:w-full md:flex-row sm:flex-col">
                                                            {data.map((d, index) => (
                                                                <div key={"listprevclose" + index} className="flex flex-1">
                                                                    <div className="flex">
                                                                        <Heading size="s" as="p" className="!text-[6.61px] !text-gray-600_a8">
                                                                            {d.prevclose}
                                                                        </Heading>
                                                                    </div>
                                                                    <div className="flex py-px">
                                                                        <Heading size="s" as="p" className="!text-[6.61px] !text-gray-900_03">
                                                                            {d.text}
                                                                        </Heading>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="flex items-start self-end">
                                                            <Heading
                                                                size="s"
                                                                as="p"
                                                                className="w-[16%] !text-[6.61px] leading-[11px] !text-gray-600_a8"
                                                            >
                                                                <>
                                                                    Ex- Dividend
                                                                    <br />
                                                                    Date
                                                                </>
                                                            </Heading>
                                                            <Heading size="s" as="p" className="ml-3.5 !text-[6.61px] !text-gray-900_03">
                                                                Feb 21, 2024
                                                            </Heading>
                                                            <div className="ml-[33px] flex flex-1 items-center justify-between gap-5">
                                                                <div className="flex flex-col items-start">
                                                                    <Text
                                                                        size="4xl"
                                                                        as="p"
                                                                        className="!font-adellesans tracking-[0.20px] !text-gray-700_01"
                                                                    >
                                                                        Transaction Total
                                                                    </Text>
                                                                    <Text
                                                                        as="p"
                                                                        className="relative mt-[-5px] h-[19px] w-[18px] !font-adellesans tracking-[0.25px] !text-gray-800_02"
                                                                    >
                                                                        $400
                                                                    </Text>
                                                                </div>
                                                                <Text
                                                                    as="p"
                                                                    className="self-end !font-roboto !font-medium tracking-[0.10px] !text-white-A700"
                                                                >
                                                                    Buy
                                                                </Text>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <LoginPageColumnTwo
                                                checkmarkimage="/images/img_checkmark.svg"
                                                buytext="Buy"
                                                masterradioOne={true}
                                                marketlabel="Market"
                                                viewOne={true}
                                                limitlabel="Limit"
                                                masterradio={true}
                                                stoplabel="Stop"
                                                labelEleven="Quantity of Stock"
                                                createfromOne={true}
                                                quantityvalue="20"
                                                createfromone1={true}
                                                pricevalue="$20"
                                                createfromone2={true}
                                                limitpricevalue="$20"
                                                labelTwelve="Explain your trade"
                                                createfrom={true}
                                                explaintradevalue="Price is low and stock at bottom, good buy"
                                                buttonOne={true}
                                                className="w-[14%] overflow-auto rounded py-2 blur-[4.00px] backdrop-opacity-[0.5] sm:w-full"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}