
const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

export const fetchInfo = async () => {

    try {
        const response = await fetch(`${API_ENDPOINT}/manage/info`, {
            method: 'GET',
            credentials: 'include',
            mode: "cors",
            headers: {
                'accept': 'application/json', // Adjust the 'accept' header as needed
            },
        })

        if (!response.ok) {
            const errorText = await response.text();
            throw { status: response.status, message: errorText};
        }

        const data = await response.json();
        return data;
    }
    catch {
        console.error("Couldn't get info")
    }
}

export const fetchStudentId = async () => {
    try {
        const response = await fetch(`${API_ENDPOINT}/api/Students/`, {
            method: 'GET',
            credentials: 'include',
            mode: "cors",
            headers: {
                'accept': 'application/json', // Adjust the 'accept' header as needed
            },
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw { status: response.status, message: errorText};
        }

        const data = await response.json();
        return data[0]?.studentId; // Adjust as needed
    } catch (error) {
        console.error('Error fetching student ID:', error.message);
        console.error('Error details:', error);
        throw error; // Re-throw the error for further handling if needed
    }
};

export const fetchStudentTradeOrders = async (studentId) => {
    const response = await fetch(`${API_ENDPOINT}/api/Simulator/Student/${studentId}/TradeOrders`, {
        credentials: 'include',
        mode: "cors",
        headers: {
            'accept': 'application/json', // Adjust the 'accept' header as needed
        },
    });

    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch student trade orders"};
    }

    const data = await response.json();
    return data;
};

export const fetchStudentPortfolioGraph = async (studentId) => {
    const response = await fetch(`${API_ENDPOINT}/api/Simulator/Student/${studentId}/PortfolioGraph`, {
        credentials: 'include',
        mode: "cors",
        headers: {
            'accept': 'application/json', // Adjust the 'accept' header as needed
        },
    });

    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch student portfolio graph"};
    }

    const data = await response.json();
    return data;
};

export const fetchStudentStocks = async (studentId) => {
    const response = await fetch(`${API_ENDPOINT}/api/Simulator/Student/${studentId}/Stocks`, {
        credentials: 'include',
        mode: "cors",
        headers: {
            'accept': 'application/json', // Adjust the 'accept' header as needed
        },
    });

    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch student stocks"};
    }

    const data = await response.json();
    return data;
};

export const fetchStockInfo = async (symbol) => {
    const response = await fetch(`${API_ENDPOINT}/api/Simulator/Stock/${symbol}`, {
        credentials: 'include',
        mode: "cors",
        headers: {
            'accept': 'application/json', // Adjust the 'accept' header as needed
        },
    });


    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch stock info"};
    }

    const data = await response.json();
    return data;
};

export async function postTrade(studentId, tradeData) {

    const url = `${API_ENDPOINT}/api/Simulator/Student/${studentId}/Trade`;

    const response = await fetch(url, {
        method: 'POST',
        mode: "cors",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(tradeData),
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw { status: response.status, message: errorText};
    }

    const responseData = await response.json();
    return responseData;
}

