import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Img, Text, Button, Heading } from "../../components";
import QuickLink from "../../components/QuickLink";
import { ReactTable } from "../../components/ReactTable";
import { createColumnHelper } from "@tanstack/react-table";
import { Link, useNavigate } from 'react-router-dom';
import StudentSidebar from "components/StudentSidebar";
import HomeDate from "components/HomeDate";
import { createClassroom, fetchClasses } from "TeacherAPIService";
import { ReactTableHoldings } from "components/ReactTableHoldings";
import ClassPopup from "components/CreateClassPopup";
import { UserContext } from 'UserContext';
import Sidebar1 from "components/Sidebar1";
import { formatDate } from "utils";

export default function TeacherDashboard() {
  const { userInfo, setUserInfo } = useContext(UserContext);

  const [classrooms, setClassrooms] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const navigate = useNavigate();

  const fetchClassrooms = async () => {
    try {
      const fetchedClassrooms = await fetchClasses();
      setClassrooms(fetchedClassrooms);
    } catch (error) {
      if (error.status === 401) {
        navigate("/timeout");
      } else {
        console.error('Error fetching data:', error);
      }
    }
  };

  useEffect(() => {
    fetchClassrooms();
  }, []);

  const handleClassCreated = () => {
    fetchClassrooms();
  };

  const table6Columns = React.useMemo(() => {
    const table6ColumnHelper = createColumnHelper();
    return [
      table6ColumnHelper.accessor("name", {
        cell: (info) => (
          <div className="w-full text-center items-center !text-blue-500 border-solid border-blue_gray-100">
            <Link to={`/classroom/${info.row.original.id}`} className="text-blue-500 hover:text-blue-600">
              {info.getValue()}
            </Link>
          </div>
        ),
        header: () => "Name",
        meta: { width: "full" },
      }),
      table6ColumnHelper.accessor("status", {
        cell: (info) => {
          let colorClass = "";
          switch (info.getValue()) {
            case "Active":
              colorClass = "text-yellow-500";
              break;
            case "Completed":
              colorClass = "text-green-500";
              break;
            case "Archived":
              colorClass = "text-gray-500";
              break;
            default:
              colorClass = "text-black";
          }
          return (
            <div className={` w-full  ${colorClass}`}>
              {info.getValue()}
            </div>
          );
        },
        header: () => "Status",
        meta: { width: "full" },
      }),
      table6ColumnHelper.accessor("numStudents", {
        cell: (info) => <div className="w-full ">{info.getValue()}</div>,
        header: () => "Students",
        meta: { width: "full" },
      }),
      table6ColumnHelper.accessor("createdDate", {
        cell: (info) => <div className=" w-full ">{formatDate(info.getValue())}</div>,
        header: () => "Date Created",
        meta: { width: "full" },
      }),
      table6ColumnHelper.accessor("grade", {
        cell: (info) => <div className=" w-full">{info.getValue()}</div>,
        header: () => "Grade",
        meta: { width: "full" },
      }),
    ];
  }, []);

  return (
    <>
      <div className="flex h-full w-full">
        <Sidebar1 />

        <div className="flex flex-col w-full h-full">
          <HomeDate className="w-full px-4" />

          <div className="flex w-full h-16 items-center bg-gray-200 justify-between px-6 border-b border-gray-300">
            <span className="text-2xl font-semibold">All Classes</span>

            {/* <button className="bg-white-A700 px-4 py-1 items-center rounded-lg border border-gray-300">
              <span className="text-2xl">⚙</span>
            </button> */}
          </div>

          <div className="flex w-full h-full md:flex-col">
            <div className="flex flex-col w-full h-full md:w-full m-4 gap-4 ">
              <div className="flex w-full gap-2 justify-end">
                <Button
                  size="3xl"
                  variant="outline"
                  shape="round"
                  className="min-w-[166px] font-roboto font-medium tracking-[0.10px] sm:px-5"
                  onClick={() => setPopupOpen(true)}
                >
                  Add Class
                </Button>
              </div>

              <div className="flex flex-col h-full w-full border border-gray-300 rounded-lg">
                <div className="flex justify-end px-4 border-b">
                  <button>
                    <p className="p-2 font-bold text-xl">⋮</p>
                  </button>
                </div>

                <ReactTable
                  size="xl"
                  bodyProps={{ className: "w-full" }}
                  headerProps={{
                    className: "w-full border-blue_gray-100 border-b border-solid bg-gray-50_01 md:flex-col",
                  }}
                  rowDataProps={{ className: "w-full border-t border-b border-solid border-blue_gray-100" }}
                  className="w-full mb-20"
                  columns={table6Columns}
                  data={classrooms}
                />
              </div>
            </div>

            <ClassPopup
              open={isPopupOpen}
              onClose={() => setPopupOpen(false)}
              onClassCreated={handleClassCreated} // Pass the callback to the popup
            />
          </div>
        </div>
      </div>
    </>
  );
}
