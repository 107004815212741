import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { CloseSVG } from "../../assets/images";
import { Img, Heading, Text, Input } from "../../components";
import DashboardWatchlist from "../../components/DashboardWatchlist";
import StudentSidebar from "../../components/StudentSidebar";
import { TabPanel, TabList, Tab, Tabs } from "react-tabs";
import { Link } from 'react-router-dom';
import TickerTape from "components/TickerTape";
import StockMarketOverviewWatchlist from "components/StockMarketOverviewWatchlist";
import MarketDataWatchlist from "components/MarketDataWatchlist";
import StockMarketWatchlist from "components/StockMarketWatchlist";
import StockSearch from "components/StockSearch";
import StockSimulatorTabs from "components/StockSimulatorTabs";
import WatchlistHeatmap from "components/HeatmapComponent";
import EconomicCalendarComponent from "components/EconomicCalendarComponent";

export default function EconomicCalendarPage() {
    const [searchBarValue35, setSearchBarValue35] = React.useState("");

    return (
        <>
            <Helmet>
                <title>intertwined</title>
                <meta name="description" content="Web site created using create-react-app" />
            </Helmet>

            <div className="flex w-full h-screen overflow-hidden m-auto">

                <div><StudentSidebar /></div>

                <div className="flex flex-col w-full">
                    <TickerTape />

                    <div className="flex w-full h-full">

                        <div className="flex flex-grow flex-shrink-0 w-[25%] ml-4 mt-4 h-[94%] border rounded-lg md:hidden">
                            <StockSearch />
                        </div>

                        <div className="flex flex-col justify-start  w-full h-[94%] gap-4 m-4 border border-solid rounded-lg ">

                            <div className="flex w-full gap-6 border-b p-4 justify-end">
                                <StockSimulatorTabs currentTab={"economiccalendar"} />
                            </div>

                            <div className="flex flex-col gap-4 p-4 overflow-auto">

                                <div className="flex flex-col items-stretch w-full gap-6">
                                    <div className="flex self-start">
                                        <Heading size="11xl" as="h2">
                                            Economic Calendar
                                        </Heading>
                                    </div>
                                    <div className="flex h-full items-center justify-evenly gap-4 min-h-[500px]">
                                        <EconomicCalendarComponent />
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>


            </div>


        </>
    );
}


