import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Heading, Text, Input, Img } from "..";
import SpendingPopup3 from "../../components/SpendingPopup3";

const data = [
  { label: "Going out with friends", price: "$70/week" },
  { label: "Hobbies", price: "$30/week" },
  { label: "Low-cost activities", price: "$20/week" },
];



const SpendingStartComponent = ({ closePopup }) => {

  const [selectedOption, setSelectedOption] = useState(null);
  const [showNextPopup, setShowNextPopup] = useState(false);

  if (showNextPopup) {
    return <SpendingPopup3 />;
  }

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000
    }}>
      <div style={{
        position: 'relative',
        width: '60%',
        maxHeight: '80%',
        padding: '20px',
        backgroundColor: 'white',
        borderRadius: '10px',
        overflowY: 'auto',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
      }}>
        <Helmet>
          <title>Meta title</title>
          <meta name="description" content="Web site created using create-react-app" />
        </Helmet>
        <button onClick={closePopup} style={{
          position: 'absolute',
          top: '8px',
          right: '18px',
          background: 'none',
          border: 'none',
          fontSize: '24px',
          cursor: 'pointer'
        }}>&times;</button>
        <div style={{ textAlign: 'center', padding: '10px' }}>
          <p style={{ fontSize: '12px', fontWeight: 'normal', marginBottom: '10px' }}>2 of 6: Basic spending habits</p>
          <div style={{ width: '50%', margin: '0 auto', display: 'flex', justifyContent: 'center' }}>
            <hr style={{ border: '2px solid blue', flex: 1, margin: '0 2px', borderRadius: '5px' }} />
            <hr style={{ border: '2px solid blue', flex: 1, margin: '0 2px', borderRadius: '5px' }} />
            <hr style={{ border: '2px solid gray', flex: 1, margin: '0 2px', borderRadius: '5px' }} />
            <hr style={{ border: '2px solid gray', flex: 1, margin: '0 2px', borderRadius: '5px' }} />
            <hr style={{ border: '2px solid gray', flex: 1, margin: '0 2px', borderRadius: '5px' }} />
            <hr style={{ border: '2px solid gray', flex: 1, margin: '0 2px', borderRadius: '5px' }} />
          </div>
          <h1 style={{ padding: '10px', fontSize: '18px', paddingTop: '50px' }}>Let's learn more about your spending habits</h1>
          <hr style={{ border: '1px solid gray', margin: '5px 0', borderRadius: '5px' }} />
          <h2 style={{ fontWeight: 'bold', marginTop: '30px', fontSize: '22px', marginBottom: "30px" }}>How do you typically spend your leisure time?</h2>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '20px' }}>
            {data.map((d, index) => (
              <button
                key={"mealOption" + index}
                style={{
                  flex: '1',
                  margin: '0 40px',
                  padding: '20px 20px',
                  fontSize: '18px',
                  fontWeight: 'normal',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  border: '1px solid #ccc',
                  backgroundColor: selectedOption === index ? 'blue' : '#f8f8f8',
                  color: selectedOption === index ? 'white' : 'black'
                }}
                onClick={() => setSelectedOption(index)}
              >
                {d.label} - {d.price}
              </button>
            ))}
          </div>
          <div style={{ marginBottom: '60px' }}></div>
          <button
            style={{
              position: 'absolute',
              bottom: '20px',
              right: '20px',
              padding: '8px 16px',
              fontSize: '16px',
              fontWeight: 'bold',
              borderRadius: '10px',
              backgroundColor: 'blue',
              color: 'white',
              cursor: 'pointer',
              border: 'none'
            }}
            onClick={() => setShowNextPopup(true)}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SpendingStartComponent;