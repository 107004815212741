import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Button, Img, Heading } from "components";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import TeacherLearningViewTopBar from "components/LearningView/TeacherLearningViewTopBar";
import TeacherUnitTree from "components/LearningView/TeacherUnitTree";
import { UserContext } from 'UserContext';

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

export default function TeacherLearningViewQuizPage() {
    const { state } = useLocation();
    const { courseId, unitId, quizId } = useParams();
    const [tree, setTree] = useState(undefined);
    const [sectionContent, setSection] = useState({ lessons: [] });
    const { userInfo } = useContext(UserContext);
    const navigate = useNavigate();
    const [menuState, setMenuState] = useState(true);

    useEffect(() => {
        if (!userInfo || !["Teacher", "Administrator"].includes(userInfo.role)) {
            navigate("/unauthorized");
            return;
        }

        const fetchTree = async () => {
            try {
                const response = await fetch(`${API_ENDPOINT}/api/Courses/Units/${unitId}/tree/08dc5bd3-a845-4194-8282-5cda1fd3e64e`, {
                    credentials: "include",
                    headers: {
                        accept: 'text/plain',
                    },
                });

                if (response.status === 401) {
                    navigate("/timeout");
                } else {
                    const data = await response.json();
                    setTree(data);
                }
            } catch (error) {
                console.error("Fetching unit tree failed", error);
            }
        };

        if (!state?.tree) {
            fetchTree();
        } else {
            setTree(state.tree);
        }
    }, [unitId, userInfo, navigate, state]);

    useEffect(() => {
        const fetchSection = async () => {
            try {
                if (!tree) return;

                const section = tree.sections.find(s => s.quiz && s.quiz.id === quizId);

                if (!section || !section.quiz) {
                    console.error("Invalid section Id.");
                    return;
                }

                const response = await fetch(`${API_ENDPOINT}/api/Courses/Units/Sections/${section.id}`, {
                    credentials: "include",
                    headers: {
                        accept: 'text/plain',
                    },
                });

                if (response.status === 401) {
                    navigate("/timeout");
                } else {
                    const sectionData = await response.json();
                    setSection(sectionData);
                }
            } catch (error) {
                console.error("Fetching section failed", error);
            }
        };

        if (tree && (!state?.section || state.section.quiz.id !== quizId)) {
            fetchSection();
        } else if (state?.section) {
            setSection(state.section);
        }
    }, [unitId, quizId, tree, state]);

    const isLastQuizInCourse = () => {
        if (!tree) return false;
        const currentSectionIndex = tree.sections.findIndex(section => section.quiz && section.quiz.id === quizId);
        if (currentSectionIndex === -1) return false;
        for (let i = currentSectionIndex + 1; i < tree.sections.length; i++) {
            if (tree.sections[i].quiz) {
                return false;
            }
        }
        return true;
    };

    const handleNextClick = () => {
        if (!tree) return;
        if (isLastQuizInCourse()) {
            navigate(`/course/${courseId}/`, { state: { section: sectionContent } });
            return;
        }
        for (let i = 0; i < tree.sections.length; i++) {
            const section = tree.sections[i];
            if (section.quiz && section.quiz.id === quizId) {
                if (i < tree.sections.length - 1) {
                    const nextSection = tree.sections[i + 1];
                    const nextLesson = nextSection.lessons[0];
                    navigate(`/course/${courseId}/unit/${unitId}/lesson/${nextLesson.id}`, { state: { section: sectionContent } });
                }
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>intertwined</title>
                <meta name="description" content="Web site created using create-react-app" />
            </Helmet>
            <div className="h-fit p-2 w-full flex flex-col overflow-hidden">
                <div className="w-full">
                    <TeacherLearningViewTopBar courseId={courseId}
                        studentId={"08dc5bd3-a845-4194-8282-5cda1fd3e64e"}
                        unitInfo={tree && { name: tree.name && tree.name, sequenceNumber: tree.number ? tree.number : 0 }}
                    />
                </div>

                <div className="flex-1 flex max-h-[calc(100vh-100px)] gap-2">
                    <div className={`${menuState ? 'w-[30%]' : 'w-[0%]'} overflow-auto py-4`}>
                        {menuState && (
                            <TeacherUnitTree courseId={courseId}
                                data={tree}
                                content={sectionContent}
                                active={quizId} />
                        )}
                    </div>

                    <div className={`${menuState ? 'w-[70%]' : 'w-[100%]'} flex flex-col gap-4 py-4`}>
                        <Button
                            size="3xl"
                            variant="outline"
                            leftIcon={<Img src="/images/img_megaphone.svg" alt="megaphone" className="h-[18px] w-[18px]" />}
                            className="max-w-[180px] gap-2 rounded-[20px] font-medium tracking-[0.10px] p-4 sm:pr-5"
                            onClick={() => setMenuState(!menuState)} 
                        >
                            {menuState ? "Close Menu" : "Open Menu"}
                        </Button>

                        <div className="flex flex-wrap items-stretch gap-4 border justify-center rounded-lg p-2 overflow-auto md:flex-col md:flex-nowrap">
                            {sectionContent.quiz && sectionContent.quiz.questions.map((question, qIndex) => (
                                <div key={qIndex} className="w-[48%] md:w-full border bg-blue-200 bg-opacity-50 border-solid border-blue-400 rounded-lg mb-8 p-4">
                                    <Heading size="8xl" className="mb-4 font-bold">
                                        Question {qIndex + 1}
                                    </Heading>
                                    {question.questionVariants.map((variant, vIndex) => (
                                        <div key={vIndex} className="mb-6">
                                            <h3 className="mb-2 text-md">{variant.text}</h3>
                                            <ul className="pl-5">
                                                {variant.answers
                                                    .sort((a, b) => b.isCorrect - a.isCorrect)
                                                    .map((answer, aIndex) => (
                                                        <li key={aIndex} className="mb-1 text-sm">
                                                            <p className={answer.isCorrect ? "text-green-600" : "text-red-600"}><span>{answer.text}</span></p>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            ))}

                            <button
                                className="bg-blue-500 text-white-A700 px-4 py-2 rounded hover:bg-blue-600 mt-4"
                                onClick={handleNextClick}
                            >
                                Next Lesson
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
