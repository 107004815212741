import React, { useState, useEffect } from "react";
import { Text, Heading, Button } from "../../components";
import { IconSortAscending, IconSortDescending } from '@tabler/icons-react';
import { format } from 'date-fns';
import { fetchSectionDetails } from "TeacherAPIService"; // Import the new API function
import { useNavigate } from "react-router-dom";
import QuickLink from "components/QuickLink";

const Assignments = ({ classroomData }) => {
    const [isAscending, setIsAscending] = useState(true); // For sorting by due date
    const [assignmentsWithSections, setAssignmentsWithSections] = useState([]); // To store assignments with section titles
    const navigate = useNavigate();

    useEffect(() => {
        const loadAssignmentsWithSections = async () => {
            const assignmentsWithSections = await Promise.all(
                classroomData.assignments.map(async (assignment) => {
                    try {
                        const section = await fetchSectionDetails(assignment.sectionId);
                        return {
                            ...assignment,
                            sectionName: section.name, // Assuming the section name is in `section.name`
                        };
                    } catch (error) {
                        if (error.status === 401) {
                            navigate("/timeout")
                        }
                        console.error(`Failed to fetch section for assignment ${assignment.id}`, error);
                        return {
                            ...assignment,
                            sectionName: 'Unknown Section', // Fallback if fetching the section fails
                        };
                    }
                })
            );
            setAssignmentsWithSections(assignmentsWithSections);
        };

        loadAssignmentsWithSections();
    }, [classroomData.assignments]);

    const sortedAssignments = assignmentsWithSections.sort((a, b) => {
        const dateA = new Date(a.dueUTC);
        const dateB = new Date(b.dueUTC);
        return isAscending ? dateA - dateB : dateB - dateA;
    });

    const handleSortToggle = () => {
        setIsAscending(!isAscending);
    };

    return (
        <div className="flex">
            <div className="flex flex-col h-[75vh] w-full p-6">
                <div className="flex justify-between items-center mb-4">
                    <Text size="4xl" as="p" className="font-lg font-semibold">
                        {assignmentsWithSections.length} Assignments
                    </Text>
                    <Button
                        onClick={handleSortToggle}
                        className="flex items-center justify-center p-2 bg-blue-500 text-white rounded-md"
                    >
                        Sort by Due Date
                        {isAscending ? (
                            <IconSortAscending className="ml-2" />
                        ) : (
                            <IconSortDescending className="ml-2" />
                        )}
                    </Button>
                </div>

                <div className="flex-1 overflow-auto">
                    <table className="table-auto w-full border-collapse border border-gray-300 rounded-lg">
                        <thead>
                            <tr className="bg-gray-100 border-b border-gray-300">
                                <th className="text-left p-4">Section</th>
                                <th className="text-left p-4">Due Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedAssignments.map((assignment) => (
                                <tr key={assignment.id} className="border-b border-gray-300">
                                    <td className="p-4">{assignment.sectionName}</td>
                                    <td className="p-4">
                                        {format(new Date(assignment.dueUTC), 'MMM dd, yyyy')}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>

            <div class="w-px min-h-[90vh] bg-gray-300"></div>

            <div className="flex flex-col w-[25%] h-full m-4 gap-5 md:w-[0%] md:hidden">
                <QuickLink classroomData={classroomData} />
            </div>
        </div>

    );
};

export default Assignments;
