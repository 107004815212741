import React, { useEffect, useRef } from 'react';

const HeatmapComponent = () => {
    const widgetContainerRef = useRef(null);

    useEffect(() => {
        const widgetConfig = {
            "exchanges": [],
            "dataSource": "SPX500",
            "grouping": "sector",
            "blockSize": "market_cap_basic",
            "blockColor": "change",
            "locale": "en",
            "symbolUrl": "",
            "colorTheme": "light",
            "hasTopBar": false,
            "isDataSetEnabled": false,
            "isZoomEnabled": true,
            "hasSymbolTooltip": true,
            "isMonoSize": false,
            "width": "900",
            "height": "650"
        };

        if (widgetContainerRef.current) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-stock-heatmap.js';
            script.innerHTML = JSON.stringify(widgetConfig);
            widgetContainerRef.current.innerHTML = ''; // Clear the container
            widgetContainerRef.current.appendChild(script);
        }
    }, []);

    return (
        <div class="tradingview-widget-container" ref={widgetContainerRef}>
            <div class="tradingview-widget-container__widget"></div>
            <div class="tradingview-widget-copyright">
            </div>
        </div>
    );
};

export default HeatmapComponent;

