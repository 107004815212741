import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { Text, Img, Button, Heading } from "../../components";
import DashboardWatchlist from "../../components/DashboardWatchlist";
import LoginPageColumnTwo from "../../components/LoginPageColumnTwo";
import StudentSidebar from "../../components/StudentSidebar";
import { UserContext } from "UserContext";
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import TickerTape from "components/TickerTape";
import { fetchStockInfo, fetchStudentId } from "SimulatorAPIService";
import { formatNumber, formatDate } from "utils";
import TechnicalAnalysis from "components/TechnicalAnalysis";
import SymbolOverview from "components/SymbolOverview";
import Skeleton from "react-loading-skeleton";
import classNames from 'classnames';
import StockSearch from "components/StockSearch";
import StockViewTabs from "components/StockViewTabs";
import StockSimulatorTabs from "components/StockSimulatorTabs";
import AdvancedChart from "components/AdvancedChart";
import KeyData from "components/KeyData";
import Sidebar1 from "components/Sidebar1";

export default function StockViewPage() {
  const [searchBarValue34, setSearchBarValue34] = React.useState("");
  const { stockSymbol } = useParams();
  const [stockInfo, setStockInfo] = useState(null);
  const [studentId, setStudentId] = useState(null);
  const [chartType, setChartType] = useState("area");
  const [showSymbolOverview, setShowSymbolOverview] = useState(false); // State to toggle components
  const [loading, setLoading] = useState(true); // New loading state
  const { userInfo } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const id = await fetchStudentId();
        setStudentId(id);

        const stockInfo = await fetchStockInfo(stockSymbol);
        setStockInfo(stockInfo);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/timeout");
        } else {
          console.error('Error fetching data:', error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [stockSymbol, navigate]);

  const toggleComponent = () => {
    setShowSymbolOverview((prevShow) => !prevShow); // Toggle the state
  };

  useEffect(() => {}, [chartType, stockSymbol]);

  return (
    <>
      <Helmet>
        <title>Stock | Intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>

      <div className="flex w-full h-screen overflow-hidden m-auto">
        {userInfo?.role === 'Teacher' ? <Sidebar1 /> : <StudentSidebar />}

        <div className="flex flex-col w-full">
          <TickerTape />

          <div className="flex w-full h-full">
            <div className="flex flex-grow flex-shrink-0 w-[25%] ml-4 mt-4 h-[90%] border rounded-lg md:hidden">
              <StockSearch />
            </div>

            <div className="flex flex-col justify-start w-full h-[90%] gap-4 m-4 border border-solid rounded-lg ">
              <div className="flex w-full gap-6 border-b p-4 justify-end">
                <StockSimulatorTabs />
              </div>

              <div className="flex flex-col overflow-auto gap-4 p-4">
                {loading ? (
                  <Skeleton height="450px" />
                ) : (
                  <>
                    <div className="flex flex-col flex-grow flex-shrink min-h-[450px]">
                      {showSymbolOverview ? (
                        <AdvancedChart stockInfo={stockInfo} />
                      ) : (
                        <SymbolOverview stockSymbol={stockSymbol} key={chartType} />
                      )}
                    </div>

                    <div className="flex justify-end">
                      <Button onClick={toggleComponent} className="flex items-center justify-center bg-transparent">
                        <div className="flex gap-2 border border-gray-300 p-1 rounded-lg">
                          <div className={`${showSymbolOverview ? 'bg-white-A700' : 'bg-gray-50'} rounded-lg`}>
                            <Img src="/images/img_line_chart_up_01.svg" alt="Area Chart" className="h-6 m-1" />
                          </div>

                          <div className={`${showSymbolOverview ? 'bg-gray-50' : 'bg-white-A700'} rounded-lg`}>
                            <Img src="/images/img_candlestick_chart.svg" alt="Candle Chart" className="h-6 m-1" />
                          </div>
                        </div>
                      </Button>
                    </div>

                    <div className="flex border rounded-lg border-gray-300">
                      <div className="w-1/2 h-full">
                        <KeyData stockInfo={stockInfo} />
                      </div>

                      <div className="w-px bg-gray-300 h-full mx-4"></div>

                      <div className="w-1/2">
                        <LoginPageColumnTwo
                          studentId={studentId}
                          stockName={stockInfo?.profile?.name}
                          stockSymbol={stockSymbol}
                          stockPrice={stockInfo?.summary?.current}
                          buttonOne="Buy"
                          className="flex w-full flex-col gap-3 rounded-lg px-4 pb-4 pt-5"
                        />
                      </div>
                    </div>

                    <div
                      className={classNames("flex flex-col flex-grow h-full rounded-lg md:h-full", {
                        "border border-solid border-blue_gray-100 h-full": stockInfo?.profile?.exchange && stockSymbol
                      })}
                    >
                      {stockInfo?.profile?.exchange && stockSymbol ? (
                        <TechnicalAnalysis stockExchange={stockInfo.profile.exchange} stockSymbol={stockSymbol} key={stockSymbol} />
                      ) : (
                        <Skeleton height="450px" />
                      )}
                    </div>

                    <div className="p-4">
                      {stockInfo?.profile?.exchange && stockSymbol ? (
                        <StockViewTabs stockInfo={stockInfo} />
                      ) : (
                        <Skeleton height="450px" />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
