import React from "react";

const sizes = {
  "3xl": "text-xs font-semibold",
  "2xl": "text-[10px] font-semibold",
  "5xl": "text-[15px] font-bold",
  "4xl": "text-sm font-semibold",
  "7xl": "text-[17px] font-semibold",
  "6xl": "text-base font-semibold",
  "9xl": "text-xl font-semibold",
  "8xl": "text-lg font-semibold",
  "15xl": "text-[40px] font-semibold md:text-[38px] sm:text-4xl",
  "14xl": "text-[33px] font-semibold md:text-[31px] sm:text-[29px]",
  "13xl": "text-[32px] font-semibold md:text-3xl sm:text-[28px]",
  xl: "text-[9px] font-semibold",
  md: "text-[7px] font-semibold",
  s: "text-[6px] font-semibold",
  "12xl": "text-[28px] font-extrabold md:text-[26px] sm:text-2xl",
  xs: "text-[5px] font-semibold",
  lg: "text-[8px] font-semibold",
  "11xl": "text-2xl font-semibold md:text-[22px]",
  "10xl": "text-[22px] font-semibold",
};

const Heading = ({ children, className = "", size = "3xl", as, ...restProps }) => {
  const Component = as || "h6";

  return (
    <Component className={`text-gray-900_07 font-notosans ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
