import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components'; // Adjust the import according to your project structure

const UnauthorizedPage = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); // Navigate to the previous page
    };

    const handleHome = () => {
        navigate('/'); // Navigate to the home page
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen p-4 bg-gray-100">
            <h1 className="text-4xl font-bold mb-4">Unauthorized Access</h1>
            <p className="text-lg mb-8">You do not have permission to view this page.</p>
            <div className="flex gap-4">
                <Button onClick={handleGoBack} className="bg-blue-500 text-white p-4 rounded">
                    Go Back
                </Button>
                <Button onClick={handleHome} className="bg-green-500 text-white p-4 rounded">
                    Home
                </Button>
            </div>
        </div>
    );
};

export default UnauthorizedPage;
