
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Img, Text, Heading } from "../../components";
import parse from "html-react-parser";

import 'styles/lesson.css' // So that we can match the css in the other thing!

export default function ArticleContent({ lesson, ...props }) {
    return <div className="">
        {parse(lesson.lessonContent)}
    </div>
}