import React, { useEffect, useRef } from "react";

export default function TechnicalAnalysis({ stockSymbol, stockExchange }) {
  const containerRef = useRef(null);

  useEffect(() => {
    containerRef.current.innerHTML = '';
    
    if (stockSymbol && stockExchange) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js';
      script.innerHTML = JSON.stringify({
        "interval": "1m",
        "width": "100%",
        "isTransparent": true,
        "height": 450,
        "symbol": `${stockExchange}:${stockSymbol}`,
        "showIntervalTabs": true,
        "displayMode": "multiple",
        "locale": "en",
        "colorTheme": "light"
      });

      if (containerRef.current) {
        containerRef.current.appendChild(script);
      }
    }
  }, [stockSymbol, stockExchange]);

  return (
    <div className="tradingview-widget-container" ref={containerRef} style={{ height: "100%", width: "100%" }}>
      <div className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright">
      </div>
    </div>
  );
}