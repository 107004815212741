import React, { useState, useEffect, useContext } from "react";
import { Img, Text } from "./..";
import { MenuItem, Menu, Sidebar, sidebarClasses } from "react-pro-sidebar";
import { Link, useLocation } from 'react-router-dom';
import { fetchStudentInfo } from "StudentAPIService";
import { fetchInfo } from "SimulatorAPIService";
import { UserContext } from "UserContext";

export default function StudentSidebar({ ...props }) {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  const initialActiveItem = location.pathname.split('/')[1] || 'studenthome';
  const [activeItem, setActiveItem] = useState(initialActiveItem);
  const { userInfo, setUserInfo } = useContext(UserContext);

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  return (
    <Sidebar
      {...props}
      width="90px !important"
      collapsedWidth="80px !important"
      collapsed={collapsed}
      rootStyles={{ [`.${sidebarClasses.container}`]: { gap: 24, overflow: 'hidden' } }}
      className={`${props.className} flex flex-col gap-6 justify-between top-0 md:p-5 border-blue_gray-100 border-r border-solid bg-white-A700 !sticky md:hidden h-screen sidebar-overflow relative`}
    >
      <div className="flex pt-4 flex-col h-screen justify-between">
        <div>
          <Link to="/studenthome" onClick={() => handleItemClick("studenthome")}>
            <Img src="/images/img_brand.svg" alt="brand" className="mx-auto h-[32px] w-[32px]" />
          </Link>
          <Menu
            menuItemStyles={{
              button: {
                padding: "22px",
                opacity: 0.8,
                flexDirection: "column",
                color: "#49454f",
                fontWeight: 600,
                fontSize: "12px",
                [`&:hover, &.ps-active`]: {
                  color: "#1976d2",
                  [`& img`]: {
                    backgroundColor: "#dbeafe",
                  },
                },
              },
            }}
            rootStyles={{ ["&>ul"]: { gap: "" } }}
            className="flex flex-col self-stretch pt-4"
          >
            <div className="flex flex-col gap-[0.62px]">
              <MenuItem
                icon={
                  <Link to="/studenthome" onClick={() => handleItemClick("studenthome")}>
                    <Img
                      src="/images/img_container.svg"
                      alt="container"
                      className={`h-[40px] w-[40px] rounded-[5px] ${activeItem == "studenthome"
                        ? " bg-blue-100 saturate-100 invert-33 sepia-94 saturate-1863 hue-rotate-196 brightness-101 contrast-101"
                        : ""
                        }`}
                    />
                  </Link>


                }

              >
                <Link to="/studenthome" onClick={() => handleItemClick("studenthome")}>
                  <div className={`p-1 ${activeItem == "studenthome" ? "!text-blue-500" : ""}`}>Dashboard</div>
                </Link>
              </MenuItem>
              <MenuItem
                icon={
                  <Link to="/courses" onClick={() => handleItemClick("courses")}>
                    <Img
                      src="/images/img_container_gray_800_01_40x40.svg"
                      alt="container"
                      className={`h-[40px] w-[40px] rounded-[5px] ${activeItem === "courses"
                        ? " bg-blue-100 saturate-100 invert-33 sepia-94 saturate-1863 hue-rotate-196 brightness-101 contrast-101"
                        : ""
                        }`}
                    />
                  </Link>
                }
              >
                <Link to="/courses" onClick={() => handleItemClick("courses")}>
                  <div className={`p-1 ${activeItem == "courses" ? "!text-blue-500" : ""}`}>Courses</div>
                </Link>
              </MenuItem>
              <MenuItem
                icon={
                  <Link to="/dashboard" onClick={() => handleItemClick("dashboard")}>
                    <Img
                      src="/images/img_container_gray_800_01.svg"
                      alt="container"
                      className={`h-[40px] w-[40px] rounded-[5px] ${activeItem === "dashboard"
                        ? " bg-blue-100 saturate-100 invert-33 sepia-94 saturate-1863 hue-rotate-196 brightness-101 contrast-101"
                        : ""
                        }`}
                    />
                  </Link>
                }
              >
                <Link to="/dashboard" onClick={() => handleItemClick("dashboard")}>
                  <div className={`p-1 ${activeItem == ("dashboard" || "watchlist" || "orders" || "stockview") ? "!text-blue-500" : ""}`}>
                    <div className="flex flex-col items-center">
                      <span className="whitespace-normal text-center">Stock Simulator</span>
                    </div>
                  </div>
                </Link>
              </MenuItem>
            </div>
          </Menu>
        </div>


        <Menu
          menuItemStyles={{
            button: {
              padding: "22px",
              opacity: 0.8,
              flexDirection: "column",
              color: "#49454f",
              fontWeight: 600,
              fontSize: "12px",
              [`&:hover, &.ps-active`]: {
                color: "#1976d2",
                [`& img`]: {
                  backgroundColor: "#dbeafe",
                },
              },
            },
          }}
          rootStyles={{ ["&>ul"]: { gap: "" } }}
          className="flex flex-col self-stretch pt-4"
        >
          <MenuItem
            icon={
              <Link to="/myprofile" onClick={() => handleItemClick("settings")}>
                <div className="flex flex-col items-center justify-center w-full ">
                  <Img
                    src={userInfo?.profileImage.url}
                    alt="avatars3d"
                    className={`h-[40px] w-[40px] rounded-[20px] object-cover`}
                  />
                  <p>{userInfo?.firstName} {userInfo?.lastName}</p>
                </div>
              </Link>
            }
          >
          </MenuItem>
        </Menu>
      </div>
      <div className="absolute top-0 right-0 h-full w-px bg-gray-300"></div>
    </Sidebar>
  );
}
