import React from 'react';
import { Helmet } from 'react-helmet';
import { Button, Img, Text, TextArea, Input, SelectBox, Heading } from '../../components';

const dropDownOptions = [
  { label: 'Option1', value: 'option1' },
  { label: 'Option2', value: 'option2' },
  { label: 'Option3', value: 'option3' },
];

export default function TransferMoney({ onClose }) {
  
  function transferButtonClicked() {
    onClose();
  }

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center" onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}>
        <div className="bg-white p-2 md:p-3 rounded-xl max-w-lg mx-auto px-6" style={{ maxHeight: '80vh', maxWidth: '80vh' }}>
          <div className="flex w-full justify-center bg-white-A700 pb-5 md:flex-col" style={{ borderRadius: '10px', width: '650px' }}>
            <div className="flex w-full flex-col items-center gap-[35px] rounded-lg bg-white-A700 py-6 shadow-sm md:p-5 sm:py-5">
              <div className="flex flex-col items-start">
                <Heading size="6xl" as="h1" style={{ marginLeft: '-200px' }} className="!text-gray-900_02" font-bold>
                  Transfer Money
                </Heading>
                <Text as="p" style={{ marginLeft: '-200px' }} className="!text-gray-900_02">
                  Choose the account and the amount you want to transfer
                </Text>
              </div>
              <div className="flex flex-col items-center gap-8 self-stretch px-6 sm:px-5">
                <div className="flex flex-col gap-[23px] self-stretch">
                  <div className="flex gap-8 md:flex-col">
                    <div className="flex w-full flex-col items-start gap-1.5">
                      <Text as="p" className="!font-medium !text-blue_gray-800_01">
                        <span className="text-blue_gray-800_01">Transfer from</span>
                        <span className="text-red-500">*</span>
                      </Text>
                      <SelectBox
                        shape="round"
                        indicator={
                          <Img src="images/img_arrow_down_black_900_16x16.svg" alt="arrow_down" className="h-[16px] w-[16px]" style={{ transform: 'rotate(90deg) scale(1.7)' }} />
                        }
                        name="arrowdown"
                        placeholder="Checking Account - 1234"
                        options={dropDownOptions}
                        className="gap-px self-stretch border border-solid border-blue_gray-100_02 !text-gray-900_04 sm:pr-5"
                      />
                    </div>
                    <div className="flex w-full flex-col items-start gap-1.5">
                      <Text as="p" className="!font-medium !text-blue_gray-800_01">
                        <span className="text-blue_gray-800_01">Transfer to</span>
                        <span className="text-red-500">*</span>
                      </Text>
                      <SelectBox
                        shape="round"
                        indicator={
                          <Img src="images/img_arrow_down_black_900_16x16.svg" alt="arrow_down" className="h-[16px] w-[16px]" style={{ transform: 'rotate(90deg) scale(1.7)' }} />
                        }
                        name="arrowdown_one"
                        placeholder="Savings Account - 8887"
                        options={dropDownOptions}
                        className="gap-px self-stretch border border-solid border-blue_gray-100_02 sm:pr-5"
                        style={{ width: '200px' }} // Set a fixed width for the dropdown
                      />
                    </div>
                  </div>
                  <div className="flex flex-col items-start gap-1.5">
                    <Text as="p" className="!font-medium !text-blue_gray-800_01">
                      Amount
                      <span className="text-red-500">*</span>
                    </Text>
                    <Input
                      size="lg"
                      variant="fill"
                      shape="round"
                      name="amount"
                      placeholder="$400.25"
                      className="self-stretch border border-solid border-blue_gray-100_02 !text-gray-900_04 pt-3 sm:pr-5"
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>
                  <div className="flex flex-col items-start gap-[5px]">
                    <div className="flex flex-col items-start gap-1.5 self-stretch">
                      <Text as="p" className="!font-medium !text-blue_gray-800_01">
                        Note to self
                        <span className="text-red-500">*</span>
                      </Text>
                      <TextArea
                        shape="round"
                        name="input_one"
                        placeholder="Saving for Spring Break!"
                        className="self-stretch !border-blue_gray-100_02 text-blue_gray-500 pt-[12px] sm:pb-5 sm:pr-5 mt-[-5px] ml-[-5px]"
                        onClick={(e) => e.stopPropagation()}
                      />
                    </div>
                    <Text size="xl" as="p" className="!text-blue_gray-800">
                      Minimum 32 characters required
                    </Text>
                  </div>
                </div>
                <div className="flex gap-4 self-start">
                  <Button
                    color="blue_A200"
                    size="3xl"
                    variant="outline"
                    shape="round"
                    leftIcon={<Img src={"images/img_arrow_down_black_900_16x16.svg"} alt="arrow_down" className="h-[16px] w-[16px]" style={{ transform: 'rotate(180deg)' }} />}
                    className="min-w-[161px] gap-2 font-semibold shadow-xs sm:px-5"
                    onClick={onClose}
                  >
                    Back
                  </Button>
                  <Button
                    color="blue_A200"
                    size="3xl"
                    shape="round"
                    className="min-w-[225px] border border-solid border-blue-A200 font-semibold shadow-xs sm:px-3 pl-[4rem] text-center"
                    onClick={transferButtonClicked}
                  >
                    Transfer now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
