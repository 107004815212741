import React from "react";
import { Helmet } from "react-helmet";
import { Text, Img, Heading, Button, Input, SelectBox } from "../../components";
import { ReactTable } from "../../components/ReactTable";
import Sidebar1 from "../../components/Sidebar1";
import { createColumnHelper } from "@tanstack/react-table";
import { TabPanel, TabList, Tab, Tabs } from "react-tabs";
import { useNavigate } from 'react-router-dom';

const data = [
  { name: "Name", meta: "Meta", apple: "Apple", tesla: "TESLA" },
  { name: "Symbol", meta: "META", apple: "AAPL", tesla: "TES" },
];
const data1 = [
  { tablecell: "24.34", tablecellone: "24.34", tablecelltwo: "24.34", avgcost: "Avg cost." },
  { tablecell: "Jan 22, 2024", tablecellone: "Jan 22, 2024", tablecelltwo: "Jan 22, 2024", avgcost: "Total Amount" },
];
const dropDownOptions = [
  { label: "Option1", value: "option1" },
  { label: "Option2", value: "option2" },
  { label: "Option3", value: "option3" },
];
const table3Data = [
  { stock: "Apple", tableheader: "SPY", qty: "20", avgcost: "24.34", ltp: "20", curval: "20", pl: "20" },
  { stock: "TESLA", tableheader: "TES", qty: "20", avgcost: "24.34", ltp: "20", curval: "20", pl: "20" },
];

export default function PerformancestockPage() {
   const navigate = useNavigate();

  const handleAllClassesClick = () => {
    navigate('/teacherhome');
  };

  const handleAssignedCoursesClick = () => {
    navigate('/assignedcourses');
  };

  const handleStudentsClick = () => {
    navigate('/students');
  };

  const handleInstructorClick = () => {
    navigate('/instructor');
  };

  const handleAssignmentClick = () => {
    navigate('/assignments');
  };

  const handleStudentPerformanceClick = () => {
    navigate('/performancecourses');
  };

  const table3Columns = React.useMemo(() => {
    const table3ColumnHelper = createColumnHelper();
    return [
      table3ColumnHelper.accessor("stock", {
        cell: (info) => (
          <div className="flex self-end border-solid border-blue_gray-100">
            <Text size="7xl" as="p" className="tracking-[0.10px] !text-blue-A400">
              {info?.getValue?.()}
            </Text>
          </div>
        ),
        header: (info) => (
          <Heading size="4xl" as="p" className="py-3 pl-6 !text-blue_gray-500 md:p-5 sm:pl-5">
            Stock
          </Heading>
        ),
        meta: { width: "149px" },
      }),
      table3ColumnHelper.accessor("tableheader", {
        cell: (info) => (
          <div className="flex self-end border-solid border-blue_gray-100">
            <Text size="7xl" as="p" className="tracking-[0.10px] !text-blue-A400">
              {info?.getValue?.()}
            </Text>
          </div>
        ),
        header: (info) => (
          <Heading
            size="4xl"
            as="p"
            className="flex items-center justify-center border-blue_gray-100 bg-gray-50_01 !text-blue_gray-500 md:p-5"
          >
            Symbol
          </Heading>
        ),
        meta: { width: "149px" },
      }),
      table3ColumnHelper.accessor("qty", {
        cell: (info) => (
          <div>
            <Button
              color="blue_gray_100"
              size="8xl"
              variant="outline"
              shape="square"
              className="w-full tracking-[0.10px] sm:px-5"
              style={{ borderBottom: 'none' }}
            >
              {info?.getValue?.()}
            </Button>
          </div>
        ),
        header: (info) => (
          <Button
            color="gray_50_01"
            size="4xl"
            shape="square"
            className="min-w-[149px] font-semibold md:p-5 sm:px-5"
          >
            Qty
          </Button>
        ),
        meta: { width: "149px" },
      }),
      table3ColumnHelper.accessor("avgcost", {
        cell: (info) => (
          <Text
            size="7xl"
            as="p"
            className="tracking-[0.10px] !text-blue_gray-500"
            style={{ borderBottom: 'none' }}
          >
            {info?.getValue?.()}
          </Text>
        ),
        header: (info) => (
          <Heading size="4xl" as="p" className="py-3 pl-6 !text-blue_gray-500 md:p-5 sm:pl-5">
            Avg cost.
          </Heading>
        ),
        meta: { width: "149px" },
      }),
      table3ColumnHelper.accessor("ltp", {
        cell: (info) => (
          <div>
            <Button
              color="blue_gray_100"
              size="8xl"
              variant="outline"
              shape="square"
              className="w-full tracking-[0.10px] sm:px-5"
              style={{ borderBottom: 'none' }}
            >
              {info?.getValue?.()}
            </Button>
          </div>
        ),
        header: (info) => (
          <Button
            color="gray_50_01"
            size="4xl"
            shape="square"
            className="min-w-[149px] border-blue_gray-100 font-semibold md:p-5 sm:px-5"
          >
            Ltp
          </Button>
        ),
        meta: { width: "149px" },
      }),
      table3ColumnHelper.accessor("curval", {
        cell: (info) => (
          <div>
            <Button
              color="blue_gray_100"
              size="8xl"
              variant="outline"
              shape="square"
              className="w-full tracking-[0.10px] sm:px-5"
              style={{ borderBottom: 'none' }}
            >
              {info?.getValue?.()}
            </Button>
          </div>
        ),
        header: (info) => (
          <Button
            color="gray_50_01"
            size="4xl"
            shape="square"
            className="min-w-[149px] border-blue_gray-100 font-semibold md:p-5 sm:px-5"
          >
            Cur. val
          </Button>
        ),
        meta: { width: "149px" },
      }),
      table3ColumnHelper.accessor("pl", {
        cell: (info) => (
          <div>
            <Button
              color="blue_gray_100"
              size="8xl"
              variant="outline"
              shape="square"
              className="w-full tracking-[0.10px] !text-green-A700_01 sm:px-5"
              style={{ borderBottom: 'none' }}
            >
              {info?.getValue?.()}
            </Button>
          </div>
        ),
        header: (info) => (
          <Button
            color="gray_50_01"
            size="4xl"
            shape="square"
            className="min-w-[149px] border-blue_gray-100 font-semibold md:p-5 sm:px-5"
            style={{ borderBottom: 'none' }}
          >
            P&L
          </Button>
        ),
        meta: { width: "149px" },
      }),
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-center bg-white-A700 md:flex-col">
        <Sidebar1 />
        <div className="container-md md:p-5">
          <div>
            <header className="flex items-center border-b border-solid border-blue_gray-100 bg-white-A700 px-4 pb-2 pt-[7px]">
              <div className="flex bg-white-A700 pb-1 pt-2.5">
                <Heading size="11xl" as="h4" className="!text-gray-800_01">
                  Monday, March 4, 2024
                </Heading>
              </div>
            </header>
            <div className="flex flex-col">
              <div className="flex self-start px-4 py-3">
                <div className="flex items-center gap-2 cursor-pointer" onClick={handleAllClassesClick}>
                  <Img src="images/img_arrow_left_blue_a200.svg" alt="arrowleft" className="h-[20px]" />
                  <div className="flex">
                    <Heading size="4xl" as="h1" className="!text-blue-500">
                      All Classes
                    </Heading>
                  </div>
                </div>
              </div>
              <div className="relative mt-[-3px] bg-white-A700">
                <div className="flex flex-col gap-[3px]">
                  <div className="flex w-[98%] items-center justify-between gap-5 md:w-full sm:flex-col">
                    <div className="m-5 flex flex-col items-start gap-0.5 pb-[5px]">
                      <Heading size="11xl" as="h2" className="!font-poppins !text-gray-900_05">
                        Class Financial 101
                      </Heading>
                      <div className="flex flex-wrap self-center">
                        <Text as="p" className="self-start !font-roboto tracking-[0.25px] !text-gray-800_01">
                          20 students
                        </Text>
                        <Text as="p" className="ml-[13px] self-end !font-roboto tracking-[0.25px] !text-gray-800_01">
                          |
                        </Text>
                        <Text as="p" className="ml-[22px] self-start !font-roboto tracking-[0.25px] !text-gray-800_01">
                          Grade 9
                        </Text>
                        <Text as="p" className="ml-[22px] self-end !font-roboto tracking-[0.25px] !text-gray-800_01">
                          |
                        </Text>
                        <Text as="p" className="ml-[5px] self-end !font-roboto tracking-[0.25px] !text-gray-800_01">
                          Jan, 24 2024
                        </Text>
                      </div>
                    </div>
                    <div className="mb-2 flex gap-4 self-end">
                      <Button
                        color="blue_gray_100"
                        size="2xl"
                        variant="outline"
                        shape="round"
                        className="w-[40px] border"
                      >
                        <Img src="images/img_search_blue_gray_100.svg" />
                      </Button>
                      <Button
                        size="3xl"
                        shape="round"
                        leftIcon={<Img src="images/img_icon_white_a700.svg" alt="icon" className="h-[18px] w-[18px]" />}
                        className="min-w-[176px] gap-2 font-roboto font-medium tracking-[0.10px] sm:pr-5"
                      >
                        Download Report
                      </Button>
                    </div>
                  </div>
                  <div className="rounded-lg bg-white-A700 pb-[179px] md:pb-5">
                    <Tabs
                      className="flex flex-col gap-2"
                      selectedTabClassName=""
                      selectedTabPanelClassName="relative tab-panel--selected"
                    >
                      <TabList className="flex items-center gap-4 border-b border-solid border-blue_gray-100 px-8 sm:flex-col sm:px-5">
                        <Tab className="flex items-center mb-3.5 self-end font-roboto text-sm font-medium tracking-[0.10px] text-gray-800_01 sm:mb-0 cursor-pointer" onClick={handleAssignedCoursesClick}>
                          Assigned courses
                        </Tab>
                        <Tab className="flex items-center font-roboto text-sm font-medium tracking-[0.10px] text-gray-800_01 cursor-pointer" onClick={handleStudentsClick}>
                          Students
                        </Tab>
                        <Tab className="flex items-center font-roboto text-sm font-medium tracking-[0.10px] text-gray-800_01 cursor-pointer" onClick={handleInstructorClick}>
                          Instructors
                        </Tab>
                        <Tab className="flex items-center mb-3.5 self-end font-roboto text-sm font-medium tracking-[0.10px] text-gray-800_01 sm:mb-0 cursor-pointer" onClick={handleAssignmentClick}>
                          Assignment
                        </Tab>
                        <Tab className="flex flex-col items-center w-[14%] px-[15px] pt-[15px] sm:w-full">
                          <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-blue-A200">
                            Student Performance
                          </Text>
                          <Img src="images/img_indicator.svg" alt="indicator" className="h-[14px] w-full" />
                        </Tab>
                      </TabList>
                      <div className="pb-4">
                        <div className="flex items-start md:flex-col">
                          <div className="w-[22%] px-2 pb-[67px] pt-2 md:w-full md:pb-5">
                            <div className="flex flex-col items-start justify-center rounded-lg border border-solid border-blue_gray-50_01 pb-[747px] md:pb-5">
                              <Input
                                color="gray_50_01"
                                size="md"
                                shape="square"
                                type="text"
                                name="name"
                                placeholder={`STUDENT NAME`}
                                className="self-stretch border-b border-solid border-blue_gray-50_01 pr-[35px] font-semibold sm:px-5"
                              />
                              <Text as="p" className="ml-[22px] mt-[21px] !text-black-900 md:ml-0">
                                John Doe
                              </Text>
                              <Text as="p" className="ml-[22px] mt-[21px] !text-black-900 md:ml-0">
                                John Doe
                              </Text>
                              <Text as="p" className="ml-[22px] mt-[21px] !text-black-900 md:ml-0">
                                John Doe
                              </Text>
                              <Text as="p" className="ml-[22px] mt-[21px] !text-black-900 md:ml-0">
                                John Doe
                              </Text>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col gap-4 bg-white-A700 p-2 md:self-stretch">
                            {[...Array(5)].map((_, index) => (
                              <TabPanel key={`tab-panel${index}`} className="absolute items-center">
                                <div className="w-full">
                                  <div className="flex flex-col gap-4">
                                    <div className="flex items-center justify-between gap-5 sm:flex-col">
                                      <SelectBox
                                        shape="round"
                                        indicator={
                                          <Img
                                            src="images/img_arrowdropdown.svg"
                                            alt="arrow_drop_down"
                                            className="h-[24px] w-[24px]"
                                          />
                                        }
                                        name="value"
                                        placeholder={`Course A`}
                                        options={dropDownOptions}
                                        className="w-[20%] gap-px border border-solid border-blue_gray-100 font-adellesans tracking-[0.25px] sm:w-full sm:px-5"
                                      />
                                      <div className="flex w-[35%] items-center justify-center sm:w-full">
                                        <Button
                                          color="blue_gray_50_01"
                                          size="3xl"
                                          variant="outline"
                                          className="min-w-[104px] rounded-bl-[20px] rounded-tl-[20px] border font-roboto font-medium tracking-[0.10px] !text-gray-900_05 sm:px-5"
                                        >
                                          Courses
                                        </Button>
                                        <div className="relative ml-[-1px] flex">
                                          <Button
                                            color="blue_50_04"
                                            size="3xl"
                                            shape="square"
                                            className="min-w-[125px] border border-solid border-blue_gray-50_01 font-roboto font-medium tracking-[0.10px]"
                                          >
                                            Stock Simulator
                                          </Button>
                                        </div>
                                        <div className="relative ml-[-1px] flex-1">
                                          <Button
                                            color="blue_gray_50_01"
                                            size="3xl"
                                            variant="outline"
                                            className="w-full rounded-br-[20px] rounded-tr-[20px] border font-roboto font-medium tracking-[0.10px] !text-gray-900_05"
                                          >
                                            Budget Simulator
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex flex-col items-center gap-[7px] rounded-lg border border-solid border-blue_gray-50_01 bg-white-A700 px-2 pb-[7px] pt-2">
                                      <div className="flex w-[96%] items-start justify-between gap-5 md:w-full sm:flex-col">
                                        <Heading size="6xl" as="h3" className="!text-gray-900_cc">
                                          Portfolio Performance
                                        </Heading>
                                        <div className="flex w-[20%] justify-center gap-3 sm:w-full">
                                          <div className="flex w-full items-center justify-between gap-5">
                                            <div className="h-[8px] w-[17px] rounded bg-deep_purple-A200_02" />
                                            <div className="flex py-2">
                                              <Text
                                                size="2xl"
                                                as="p"
                                                className="!font-roboto !text-[10.09px] tracking-[0.07px] !text-gray-800_01"
                                              >
                                                Marcus Schleifer
                                              </Text>
                                            </div>
                                          </div>
                                          <div className="flex w-full items-center justify-between gap-5">
                                            <div className="h-[8px] w-[17px] rounded bg-indigo-200" />
                                            <div className="flex pb-[7px] pt-[9px]">
                                              <Text
                                                size="2xl"
                                                as="p"
                                                className="!font-roboto !text-[10.09px] tracking-[0.07px] !text-gray-800_01"
                                              >
                                                Class Average
                                              </Text>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex items-start justify-center self-stretch px-[5px] pt-[5px] md:flex-col">
                                        <div className="relative z-[3] flex w-[5%] flex-col items-end gap-5 md:w-full">
                                          <div className="flex items-start gap-[7px] self-stretch">
                                            <Text size="4xl" as="p" className="!font-inter !text-black-900">
                                              $6000
                                            </Text>
                                            <div className="h-px w-[9px] bg-black-900" />
                                          </div>
                                          <div className="flex items-center gap-[7px] self-stretch">
                                            <Text size="4xl" as="p" className="!font-inter !text-black-900">
                                              $4500
                                            </Text>
                                            <div className="mb-1.5 h-px w-[9px] self-end bg-black-900" />
                                          </div>
                                          <div className="flex items-start gap-[7px] self-stretch">
                                            <Text size="4xl" as="p" className="!font-inter !text-black-900">
                                              $3000
                                            </Text>
                                            <div className="mt-1.5 h-px w-[9px] bg-black-900" />
                                          </div>
                                          <div className="flex items-start gap-[7px] self-stretch">
                                            <Text size="4xl" as="p" className="!font-inter !text-black-900">
                                              $1500
                                            </Text>
                                            <div className="mt-1.5 h-px w-[9px] bg-black-900" />
                                          </div>
                                          <div className="flex w-[45%] items-center gap-[7px] md:w-full">
                                            <Text size="4xl" as="p" className="!font-inter !text-black-900">
                                              0
                                            </Text>
                                            <div className="h-px w-[9px] bg-black-900" />
                                          </div>
                                        </div>
                                        <div className="relative ml-[-3px] w-[91%] md:ml-0 md:w-full">
                                          <div className="relative z-[2] h-[147px]">
                                            <Img
                                              src="images/img_group.svg"
                                              alt="image"
                                              className="absolute bottom-0 left-0 right-0 top-0 m-auto h-[147px] w-full"
                                            />
                                            <div className="absolute bottom-[2.86px] left-0 right-0 m-auto h-[112px] w-[97%] md:h-auto">
                                              <Img
                                                src="images/img_group_deep_purple_a200_02.svg"
                                                alt="image"
                                                className="h-[108px] w-full"
                                              />
                                              <Img
                                                src="images/img_group_indigo_200.svg"
                                                alt="image"
                                                className="absolute bottom-0 left-0 right-0 top-0 m-auto h-[112px] w-full"
                                              />
                                            </div>
                                          </div>
                                          <div className="flex justify-between gap-5">
                                            <div className="flex w-[7px] items-start">
                                              <Text
                                                size="4xl"
                                                as="p"
                                                className="relative z-[1] mt-[13px] !font-inter !text-black-900"
                                              >
                                                0
                                              </Text>
                                              <div className="relative ml-[-3px] h-[5px] w-px bg-black-900" />
                                            </div>
                                            <div className="flex w-[4px] flex-col items-start gap-2">
                                              <div className="h-[5px] w-px self-end bg-black-900" />
                                              <Text
                                                size="4xl"
                                                as="p"
                                                className="self-center !font-inter !text-black-900"
                                              >
                                                1
                                              </Text>
                                            </div>
                                            <div className="flex w-[7px] flex-col items-end gap-[7px]">
                                              <div className="h-[5px] w-px bg-black-900" />
                                              <Text size="4xl" as="p" className="!font-inter !text-black-900">
                                                2
                                              </Text>
                                            </div>
                                            <div className="flex w-[7px] flex-col items-end gap-2">
                                              <div className="h-[5px] w-px bg-black-900" />
                                              <Text size="4xl" as="p" className="!font-inter !text-black-900">
                                                3
                                              </Text>
                                            </div>
                                            <div className="flex w-[7px] flex-col items-end gap-2">
                                              <div className="h-[5px] w-px bg-black-900" />
                                              <Text size="4xl" as="p" className="!font-inter !text-black-900">
                                                4
                                              </Text>
                                            </div>
                                            <div className="flex w-[7px] flex-col items-end gap-2">
                                              <div className="h-[5px] w-px bg-black-900" />
                                              <Text size="4xl" as="p" className="!font-inter !text-black-900">
                                                5
                                              </Text>
                                            </div>
                                            <div className="flex w-[7px] flex-col items-end gap-2">
                                              <div className="h-[5px] w-px bg-black-900" />
                                              <Text size="4xl" as="p" className="!font-inter !text-black-900">
                                                6
                                              </Text>
                                            </div>
                                            <div className="flex w-[7px] flex-col items-end gap-2">
                                              <div className="h-[5px] w-px bg-black-900" />
                                              <Text size="4xl" as="p" className="!font-inter !text-black-900">
                                                7
                                              </Text>
                                            </div>
                                            <div className="flex w-[7px] flex-col items-end gap-2">
                                              <div className="h-[5px] w-px bg-black-900" />
                                              <Text size="4xl" as="p" className="!font-inter !text-black-900">
                                                8
                                              </Text>
                                            </div>
                                            <div className="flex w-[7px] flex-col items-end gap-[7px]">
                                              <div className="h-[5px] w-px bg-black-900" />
                                              <Text size="4xl" as="p" className="!font-inter !text-black-900">
                                                9
                                              </Text>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex flex-col">
                                      <div className="flex self-start px-2 pb-3 pt-[13px]">
                                        <Heading size="6xl" as="h4" className="!text-gray-900_cc">
                                          Portfolio{" "}
                                        </Heading>
                                      </div>
                                      <div className="flex rounded-lg border border-solid border-blue_gray-50_01 bg-white-A700 px-4 py-[19px] sm:flex-col">
                                        <div className="flex flex-col items-center gap-2 border-r border-solid border-blue_gray-100 px-[42px] md:px-5">
                                          <div className="flex self-start">
                                            <Heading as="h5" className="!font-poppins !text-gray-500_02">
                                              Balance Funds
                                            </Heading>
                                          </div>
                                          <div className="flex self-start">
                                            <Heading size="7xl" as="h6" className="!text-gray-800_02">
                                              $ 10,000.00
                                            </Heading>
                                          </div>
                                        </div>
                                        <div className="flex flex-col items-center gap-2 border-l border-r border-solid border-blue_gray-100 px-[54px] md:px-5">
                                          <div className="flex self-start">
                                            <Heading as="p" className="!font-poppins !text-gray-500_02">
                                              Total Investment
                                            </Heading>
                                          </div>
                                          <div className="flex self-start">
                                            <Heading size="7xl" as="h6" className="!text-gray-800_02">
                                              $ 30,000.00
                                            </Heading>
                                          </div>
                                        </div>
                                        <div className="flex flex-col items-center gap-2 border-l border-r border-solid border-blue_gray-100 px-[60px] md:px-5">
                                          <div className="flex self-start">
                                            <Heading as="p" className="!font-poppins !text-gray-500_02">
                                              Current Value
                                            </Heading>
                                          </div>
                                          <div className="flex self-start">
                                            <Heading size="7xl" as="h6" className="!text-gray-800_02">
                                              $ 60,000.00
                                            </Heading>
                                          </div>
                                        </div>
                                        <div className="flex flex-col items-center gap-2 border-l border-r border-solid border-blue_gray-100 px-[47px] md:px-5">
                                          <div className="flex">
                                            <Heading as="p" className="!font-poppins !text-gray-500_02">
                                              Day’s P&L
                                            </Heading>
                                          </div>
                                          <div className="flex self-start">
                                            <Heading size="7xl" as="h6" className="!text-deep_orange-600">
                                              $-24.29 (-1.2%)
                                            </Heading>
                                          </div>
                                        </div>
                                        <div className="flex flex-col items-center gap-2 border-l border-solid border-blue_gray-100 px-[35px] sm:px-5">
                                          <div className="flex">
                                            <Heading as="p" className="!font-poppins !text-gray-500_02">
                                              Total P&L
                                            </Heading>
                                          </div>
                                          <div className="flex self-start">
                                            <Heading size="7xl" as="h6" className="!font-poppins !text-gray-800_02">
                                              <span className="font-notosans text-gray-800_02">$20.00&nbsp;</span>
                                              <span className="font-notosans text-green-A700_01">(+100%)</span>
                                            </Heading>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex flex-col">
                                      <div className="flex self-start px-2 pb-2.5 pt-[15px]">
                                        <Heading size="6xl" as="h6" className="!text-gray-900_cc">
                                          Holdings
                                        </Heading>
                                      </div>
                                      <ReactTable
                                        size="2xl"
                                        bodyProps={{ className: "" }}
                                        headerProps={{
                                          className:
                                            "border-blue_gray-100 border-b border-solid bg-gray-50_01 md:flex-col",
                                        }}
                                        rowDataProps={{ className: "md:flex-col" }}
                                        className="rounded-lg border border-solid border-blue_gray-50_01"
                                        columns={table3Columns}
                                        data={table3Data}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </TabPanel>
                            ))}
                            <div className="flex flex-col">
                              <div className="flex self-start px-2 py-[13px]">
                                <Heading size="6xl" as="h6" className="!text-gray-900_cc">
                                  Transactions
                                </Heading>
                              </div>
                              <div className="rounded-lg border border-solid border-blue_gray-50_01">
                                <div className="rounded-tl-lg rounded-tr-lg border border-solid border-blue_gray-50_01">
                                <div className="flex gap-4 border border-solid border-blue_gray-50_01 font-medium tracking-[0.50px] !text-gray-900_07 sm:pr-5">
                                  <Img
                                    src="images/img_arrow_down_gray_900_07.svg"
                                    alt="arrow_down"
                                    className="h-[16px] w-[16px]"
                                  />
                                  <Text size="6xl" as="p" className="text-left">
                                    Week 1 (Jan 1 - Jan 7)
                                  </Text>
                                </div>
                                  <div className="p-2">
                                    <div className="flex rounded-lg border border-solid border-blue_gray-50_01 md:flex-col">
                                      <div className="flex flex-1 md:flex-row md:self-stretch">
                                        {data.map((d, index) => (
                                          <div key={"listname" + index} className="flex w-full flex-col">
                                            <div className="flex border-b border-solid border-blue_gray-50_01 bg-gray-50_01 px-6 py-[13px] sm:px-5">
                                              <Heading size="4xl" as="p" className="!text-blue_gray-500">
                                                {d.name}
                                              </Heading>
                                            </div>
                                            <a  target="_blank">
                                              <div className="flex border-b border-solid border-blue_gray-50_01 px-6 py-[26px] sm:p-5">
                                                <Text size="7xl" as="p" className="tracking-[0.10px] !text-blue-A400">
                                                  {d.meta}
                                                </Text>
                                              </div>
                                            </a>
                                            <div className="flex border-b border-solid border-blue_gray-50_01 px-6 py-[26px] sm:p-5">
                                              <Text size="7xl" as="p" className="tracking-[0.10px] !text-blue-A400">
                                                {d.apple}
                                              </Text>
                                            </div>
                                            <div className="flex border-b border-solid border-blue_gray-50_01 px-6 py-[26px] sm:p-5">
                                              <Text size="7xl" as="p" className="tracking-[0.10px] !text-blue-A400">
                                                {d.tesla}
                                              </Text>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                      <div className="w-[17%] md:w-full">
                                        <div className="flex border-b border-solid border-blue_gray-50_01 bg-gray-50_01 px-6 py-[13px] sm:px-5">
                                          <div className="flex items-center gap-1">
                                            <Heading size="4xl" as="p" className="!text-blue_gray-500">
                                              Action
                                            </Heading>
                                            <Img
                                              src="images/img_arrow_down_blue_gray_500_1.svg"
                                              alt="arrowdown"
                                              className="h-[16px] w-[16px]"
                                            />
                                          </div>
                                        </div>
                                        <div className="flex border-b border-solid border-blue_gray-50_01 px-6 py-5 sm:px-5">
                                          <Button
                                            color="gray_500"
                                            size="lg"
                                            shape="round"
                                            className="min-w-[55px] tracking-[0.10px]"
                                          >
                                            SELL
                                          </Button>
                                        </div>
                                        <div className="flex border-b border-solid border-blue_gray-50_01 px-6 py-5 sm:px-5">
                                          <Button
                                            color="gray_100_02"
                                            size="lg"
                                            shape="round"
                                            className="min-w-[52px] tracking-[0.10px]"
                                          >
                                            BUY
                                          </Button>
                                        </div>
                                        <div className="flex border-b border-solid border-blue_gray-50_01 px-6 py-5 sm:px-5">
                                          <Button
                                            color="gray_100_03"
                                            size="lg"
                                            shape="round"
                                            className="min-w-[93px] tracking-[0.10px]"
                                          >
                                            PENDING
                                          </Button>
                                        </div>
                                      </div>
                                      <div className="w-[17%] md:w-full">
                                        <Button
                                          color="gray_50_01"
                                          size="4xl"
                                          shape="square"
                                          className="w-full border-b border-solid border-blue_gray-50_01 font-semibold sm:px-5"
                                        >
                                          Qty
                                        </Button>
                                        <Button
                                          color="blue_gray_50_01"
                                          size="8xl"
                                          variant="outline"
                                          shape="square"
                                          className="w-full tracking-[0.10px] sm:px-5"
                                        >
                                          20
                                        </Button>
                                        <Button
                                          color="blue_gray_50_01"
                                          size="8xl"
                                          variant="outline"
                                          shape="square"
                                          className="w-full tracking-[0.10px] sm:px-5"
                                        >
                                          20
                                        </Button>
                                        <Button
                                          color="blue_gray_50_01"
                                          size="8xl"
                                          variant="outline"
                                          shape="square"
                                          className="w-full tracking-[0.10px] sm:px-5"
                                        >
                                          20
                                        </Button>
                                      </div>
                                      <div className="flex flex-1 md:flex-row md:self-stretch">
                                        {data1.map((d, index) => (
                                          <div key={"listavgcost" + index} className="flex w-full flex-col">
                                            <div className="flex border-b border-solid border-blue_gray-50_01 bg-gray-50_01 px-6 py-[13px] sm:px-5">
                                              <Heading size="4xl" as="p" className="!text-blue_gray-500">
                                                {d.avgcost}
                                              </Heading>
                                            </div>
                                            <Text
                                              size="7xl"
                                              as="p"
                                              className="border-b border-solid border-blue_gray-50_01 py-[26px] pl-6 pr-[35px] tracking-[0.10px] !text-blue_gray-500 sm:p-5"
                                            >
                                              {d.tablecell}
                                            </Text>
                                            <Text
                                              size="7xl"
                                              as="p"
                                              className="border-b border-solid border-blue_gray-50_01 py-[26px] pl-6 pr-[35px] tracking-[0.10px] !text-blue_gray-500 sm:p-5"
                                            >
                                              {d.tablecellone}
                                            </Text>
                                            <Text
                                              size="7xl"
                                              as="p"
                                              className="border-b border-solid border-blue_gray-50_01 py-[26px] pl-6 pr-[35px] tracking-[0.10px] !text-blue_gray-500 sm:p-5"
                                            >
                                              {d.tablecelltwo}
                                            </Text>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex justify-start gap-4 border border-solid border-blue_gray-50_01 bg-white-A700 p-2">
                                  <div className="flex rotate-[180deg] flex-col py-3">
                                    <Img
                                      src="images/img_checkmark_gray_900_07.svg"
                                      alt="checkmark"
                                      className="h-[16px]"
                                    />
                                  </div>
                                  <div className="flex py-2">
                                    <Text as="p" className="!font-medium tracking-[0.50px]">
                                      Week 3 (Jan 1 - Jan 7)
                                    </Text>
                                  </div>
                                </div>
                                <div className="flex justify-start gap-4 border border-solid border-blue_gray-50_01 bg-white-A700 p-2">
                                  <div className="flex rotate-[180deg] flex-col py-3">
                                    <Img
                                      src="images/img_checkmark_gray_900_07.svg"
                                      alt="checkmark"
                                      className="h-[16px]"
                                    />
                                  </div>
                                  <div className="flex py-2">
                                    <Text as="p" className="!font-medium tracking-[0.50px]">
                                      Week 3 (Jan 1 - Jan 7)
                                    </Text>
                                  </div>
                                </div>
                                <div className="flex justify-start gap-4 border border-solid border-blue_gray-50_01 bg-white-A700 p-2">
                                  <div className="flex rotate-[180deg] flex-col py-3">
                                    <Img
                                      src="images/img_checkmark_gray_900_07.svg"
                                      alt="checkmark"
                                      className="h-[16px]"
                                    />
                                  </div>
                                  <div className="flex py-2">
                                    <Text as="p" className="!font-medium tracking-[0.50px]">
                                      Week 3 (Jan 1 - Jan 7)
                                    </Text>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
