import React from 'react';
import ReactApexChart from 'react-apexcharts';

const IncomeStatementChart = ({ data }) => {
  const years = data.map(point => point.year);
  const revenue = data.map(point => point.revenue);
  const operatingExpense = data.map(point => point.operatingExpense);
  const operatingIncome = data.map(point => point.operatingIncome);

  const chartOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [3, 3],
      curve: 'smooth',
    },
    markers: {
      size: 5,
      hover: {
        sizeOffset: 2,
      },
    },
    xaxis: {
      categories: years,
    },
    yaxis: {
      labels: {
        formatter: val => val.toLocaleString(),
      },
    },
    tooltip: {
      y: {
        formatter: val => val.toLocaleString(),
      },
    },
    legend: {
      position: 'top',
    },
    colors: ['#5a56e9', '#d896ff'], // Adjust colors as needed
  };

  const chartSeries = [
    {
      name: 'Revenue',
      data: revenue,
    },
    {
      name: 'Operating Expense',
      data: operatingExpense,
    },
    {
        name: 'Operating Income',
        data: operatingIncome,
      },
  ];

  return (
    <div id="chart">
      <ReactApexChart
        options={chartOptions}
        series={chartSeries}
        type="line"
        height={350}
      />
    </div>
  );
};

export default IncomeStatementChart;