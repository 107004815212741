import { Helmet } from "react-helmet";
import { Text, Heading } from "../../../../components";
import TopNavLogin from "components/TopNavLogin";
import LoginIPadOne from "components/LoginIPadOne";
import ResendConfirmationForm from "./ResendConfirmationForm";

export default function ResendConfirmationPage() {
    return (
        <>
            <Helmet>
                <title>intertwined</title>
                <meta name="description" content="Web site created using create-react-app" />
            </Helmet>
            <div className="flex w-full flex-col gap-2 bg-white-A700">
                <TopNavLogin />
                <div className="flex items-start gap-7 md:flex-col">
                    <div className="mt-6 flex flex-1 flex-col items-center gap-[35px] md:self-stretch md:p-5">
                        <div className="flex flex-col items-center">
                            <Heading size="15xl" as="h1" className="!text-gray-900_05">
                                Resend Email Confirmation
                            </Heading>
                            <Text as="p" className="!text-gray-900_05">
                                Enter your account email to get a link to confirm your account.
                            </Text>
                        </div>
                        <ResendConfirmationForm />
                    </div>
                    <LoginIPadOne />
                </div>
            </div>
        </>
    );
}
