import { Helmet } from "react-helmet";
import { CloseSVG } from "./../../../../assets/images";
import { Text, Heading, Img, Button, Input, CheckBox, TextArea, SelectBox } from "components";
import LoginPageColumnTwo from "components/LoginPageColumnTwo";
import LoginIPadOne from "components/LoginIPadOne";
import TopNavLogin from "components/TopNavLogin";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;


const dropDownOptions = [
    { label: "Dr.", value: "Dr." },
    { label: "Ms.", value: "Ms." },
    { label: "Miss", value: "Miss" },
    { label: "Mrs.", value: "Mrs." },
    { label: "Mr.", value: "Mr." },
    { label: "Mister", value: "Mister" },
    { label: "Professor", value: "Professor" },
    { label: "None", value: "" },
];

export default function TeacherRegisterForm() {

    const [school, setSchool] = useState("");
    const [prefix, setPrefix] = useState("");
    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [username, setUsername] = useState("");
    const [date, setDate] = useState(new Date());
    const [message, setMessage] = useState("");
    const [privacy, setPrivacy] = useState(false);


    const [error, setError] = useState("");

    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate('/Register');
      };

    // handle submit event for the form
    const handleSubmit = (e) => {
        e.preventDefault();
        // validate fields
        setError("");
        
        const prefixValue = prefix.value;

        // send an email to kerry!
        fetch(API_ENDPOINT + "/registerteacher", {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                prefix: prefixValue,
                firstName: first,
                middleName: "",
                lastName: last,
                highSchool: school,
                username: username,
                email: email,
                phone: phone,
                accessDate: date,
                message: message
            }),
        }).then(data => {
            if (data.ok) {
                navigate("/register/teacher/success");
            } else {
                setError("There was a problem. Please contact support.");
            }
        }).catch(err => {
            console.error(err);
            setError("There was a problem. Please contact support.");
        })
    };



    return <div className="flex flex-col gap-8 self-stretch">
        <form
            onSubmit={handleSubmit}>
                <div className="text-red-500 text-sm">
                {error !== "" ? error : ""}
            </div>
            <div className="flex flex-col gap-6">
                <div className="flex flex-col items-start gap-1.5">
                    <Text as="p" className="!font-medium !text-blue_gray-800_01">
                        Institution Name
                    </Text>
                    <Input
                        shape="round"
                        id="school"
                        name="school"
                        value={school}
                        onChange={setSchool}
                        placeholder={`i.e., Indiana High School`}
                        className="self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                    />
                </div>
                <div className="flex items-center gap-8 sm:flex-col">
                    <div className="flex flex-col items-start gap-1.5 self-start sm:w-full">
                        <Text as="p" className="!font-medium !text-blue_gray-800_01">
                            <span className="text-blue_gray-800_01">Prefix</span>
                            <span className="text-red-500">*</span>
                        </Text>
                        <SelectBox
                            required
                            size="xl"
                            shape="round"
                            id="prefix"
                            name="prefix"
                            value={prefix}
                            onChange={setPrefix}
                            indicator={
                                <Img
                                    src="/images/img_arrowdown_blue_gray_500.svg"
                                    alt="arrow_down"
                                    className="h-[40px] w-[48px]"
                                />
                            }
                            placeholder={`Ms.`}
                            options={dropDownOptions}
                            className="self-stretch h-[48px] w-[130px] sm:w-full border border-solid border-blue_gray-100_05 !text-blue_gray-500 shadow-sm sm:pr-5"
                        />
                    </div>
                    <div className="flex flex-1 flex-col items-start gap-1.5 sm:self-stretch">
                        <Text as="p" className="!font-medium !text-blue_gray-800_01">
                            <span className="text-blue_gray-800_01">First Name</span>
                            <span className="text-red-500">*</span>
                        </Text>
                        <Input
                            required
                            shape="round"
                            type="text"
                            id="first"
                            name="first"
                            value={first}
                            onChange={setFirst}
                            placeholder={`First name`}
                            className="self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                        />
                    </div>
                    <div className="flex flex-1 flex-col items-start gap-1.5 sm:self-stretch">
                        <Text as="p" className="!font-medium !text-blue_gray-800_01">
                            <span className="text-blue_gray-800_01">Last Name</span>
                            <span className="text-red-500">*</span>
                        </Text>
                        <Input
                            required
                            shape="round"
                            type="text"
                            id="last"
                            name="last"
                            value={last}
                            onChange={setLast}
                            placeholder={`Last name`}
                            className="self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                        />
                    </div>
                </div>
                <div className="flex flex-col items-start gap-1.5">
                    <Text as="p" className="!font-medium !text-blue_gray-800_01">
                        <span className="text-blue_gray-800_01">Email</span>
                        <span className="text-red-500">*</span>
                    </Text>
                    <Input
                        required
                        shape="round"
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={setEmail}
                        placeholder={`you@company.com`}
                        className="self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                    />
                </div>
                <div className="flex flex-col items-start gap-1.5">
                    <Text as="p" className="!font-medium !text-blue_gray-800_01">
                        <span className="text-blue_gray-800_01">Phone Number</span>
                    </Text>
                    <PhoneInputWithCountrySelect
                        defaultCountry="US"
                        placeholder="(555) 555-5555"
                        id="phone"
                        name="phone"
                        value={phone}
                        className="h-[48px] self-stretch border rounded-xl border-solid border-blue_gray-100_05 sm:pr-5 focus:border-none"
                        onChange={setPhone}
                    />
                </div>
                <div className="flex items-center gap-8 sm:flex-col">

                    <div className="w-full flex flex-col items-start gap-1.5">
                        <Text as="p" className="!font-medium !text-blue_gray-800_01">
                            <span className="text-blue_gray-800_01">Username</span>
                            <span className="text-red-500">*</span>
                        </Text>
                        <Input
                            required
                            shape="round"
                            type="text"
                            name="username"
                            id="username"
                            value={username}
                            onChange={setUsername}
                            placeholder={``
                                // TODO!
                            }
                            className="self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                        />
                    </div>
                    <div className="w-full flex flex-col items-start gap-1.5">
                        <Text as="p" className="!font-medium !text-blue_gray-800_01">
                            <span className="text-blue_gray-800_01">Preferred Date of Access</span>
                            <span className="text-red-500">*</span>
                        </Text>
                        <div
                            className="gap-[35px] self-stretch border rounded-md h-[48px] flex items-center border-solid border-blue_gray-100_05 font-inter"
                        >
                            <ReactDatePicker
                                showIcon
                                onChange={setDate}
                                id="date" 
                                name="date"
                                selected={date}
                                icon={<Img src="/images/img_calendar.svg" alt="dropdown" className="h-[1em] w-[1em]" />}
                            > </ReactDatePicker>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-start gap-1">
                    <Text as="p" className="!font-medium !text-blue_gray-800_01">
                        Message
                    </Text>
                    <TextArea
                        shape="round"
                        name="message"
                        id="message"
                        value={message}
                        onChange={setMessage}
                        className="self-stretch !border-blue_gray-100_05" />
                </div>
                <CheckBox
                    required
                    label="You agree to our friendly privacy policy."
                    id="privacy"
                    name="privacy"
                    value={privacy}
                    onChange={setPrivacy}
                    className="gap-3 p-px font-inter text-base text-blue_gray-500"
                />
            </div>
            <div className="flex gap-8 justify-between">
            <Button
                    size="5xl"
                    variant="outline"
                    shape="round"
                    leftIcon={<Img src="/images/img_arrowdown.svg" alt="arrow_down" className="h-[16px] w-[16px]" />}
                    className="min-w-[161px] gap-2 font-semibold shadow-sm sm:px-5 pl-[1.25rem]"
                    onClick={handleBackClick}
                  >
                    Back
                  </Button>
                <Button
                    size="5xl"
                    shape="round"
                    className="w-full border border-solid border-blue-A200 font-semibold shadow-sm sm:px-5"
                >
                    Submit Request
                </Button>
            </div>
        </form>
    </div>
}