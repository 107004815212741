import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { Text, Img, Button, Heading } from "../../components";
import Header from "../../components/Header";
import StudentSidebar from "../../components/StudentSidebar";
import Sidebar1 from "components/Sidebar1";
import { UserContext } from "UserContext";
import { useNavigate } from 'react-router-dom';

export default function MyProfilePage() {
  const { userInfo, logout } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/loginpage');
  };

  return (
    <>
      <Helmet>
        <title>My Profile | Intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-center bg-white-A700 md:flex-col">
        {userInfo?.role === 'Teacher' ? <Sidebar1 /> : <StudentSidebar />} {/* Conditionally render sidebar */}
        <div className="container-md md:p-5">
          <div className="flex flex-col items-start gap-6 pb-12 md:pb-5">
            <Header className="self-stretch px-4 pb-2 pt-[7px]" />
            <div className="flex w-[83%] pt-[15px] md:w-full">
              <div className="flex w-full flex-col items-start justify-center gap-[13px] px-6 pb-[310px] md:pb-5 sm:px-5">
                <div className="flex">
                  <Heading size="11xl" as="h1" className="!font-poppins !text-gray-900_05">
                    My Profile
                  </Heading>
                </div>
                <div className="flex w-[51%] items-center justify-between gap-5 rounded-lg border border-solid border-blue_gray-100 p-4 md:w-full md:px-5 sm:p-5">
                  <Img
                    src={userInfo?.profileImage?.url}
                    alt="avatars3d"
                    className="h-[126px] w-[126px] rounded-[50%]"
                  />
                  <div className="flex flex-col items-start w-full">
                    <div className="flex w-full items-center gap-2">
                      <Text as="p" className="!font-medium tracking-[0.10px] !text-gray-900_02">
                        <h1 className="text-xl">{userInfo?.firstName} {userInfo?.lastName}</h1>
                      </Text>
                    </div>
                    <h2 className="text-sm">{userInfo?.username}</h2>
                    <h2 className="text-sm">{userInfo?.email}</h2>
                    <h2 className="text-sm">{userInfo?.highSchool}</h2>
                  </div>
                </div>
                <div>
                  <Button
                    size="3xl"
                    variant="outline"
                    shape="round"
                    className="min-w-[166px] font-roboto font-medium tracking-[0.10px] sm:px-5"
                    onClick={handleLogout}
                  >
                    Log Out
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
