const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

export const fetchStudentInfo = async () => {
    const response = await fetch(`${API_ENDPOINT}/api/manage/info`, {
        credentials: 'include',
        headers: {
            'accept': 'application/json', // Adjust the 'accept' header as needed
        },
    });

    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch student info"};
    }

    const data = await response.json();
    return data;
};

export const fetchAssignments = async (studentId) => {
    const response = await fetch(`${API_ENDPOINT}/api/Students/${studentId}/Assignments`, {
        credentials: 'include',
        headers: {
            'accept': 'application/json',
        },
    });

    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch assignments"};
    }

    const data = await response.json();
    return data;
};

export const submitAnswer = async (sectionId, answerId, studentId) => {
    const response = await fetch(`${API_ENDPOINT}/api/Courses/Units/Sections/${sectionId}/Answer/${answerId}/Submit/${studentId}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: JSON.stringify({}), // Adjust body if necessary
    });

    if (!response.ok) {
        throw { status: response.status, message: "Failed to submit answer"};
    }

    const data = await response.json();
    return data;
};

// Fetch section details by sectionId
export const fetchSection = async (sectionId) => {
    const response = await fetch(`${API_ENDPOINT}/api/Courses/Units/Sections/${sectionId}`, {
        credentials: 'include',
        headers: {
            'accept': 'application/json',
        },
    });

    if (!response.ok) {
        throw { status: response.status, message: `Failed to fetch section with id ${sectionId}`};
    }

    const sectionData = await response.json();
    return sectionData;
};

export const fetchStudentDetails = async (studentId) => {
    try {
        const response = await fetch(`${API_ENDPOINT}/api/Students/${studentId}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw { status: response.status, message: "Failed to fetch student details"};
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching student details:', error);
        throw error;
    }
};