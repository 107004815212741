import React, { useState } from "react";
import { Img, Text, Button, Heading } from "../../components";
import { createColumnHelper } from "@tanstack/react-table";
import AddCourseModal from "components/AddCourseModal";
import QuickLink from "components/QuickLink";
import { formatDate } from "utils";
import TeacherCourseCard from "components/TeacherCourseCard";
import { ConvertDuration } from "utils";

const AssignedCourses = ({ classroomData, reloadClassroomDetails }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [isAddingCourse, setIsAddingCourse] = useState(false);

    const handleSelectRow = (id) => {
        setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.includes(id)
                ? prevSelectedRows.filter((rowId) => rowId !== id)
                : [...prevSelectedRows, id]
        );
    };

    const handleSelectAllRows = () => {
        if (selectedRows.length === classroomData.courses.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(classroomData.courses.map((course) => course.id));
        }
    };

    const table1Columns = React.useMemo(() => {
        const table1ColumnHelper = createColumnHelper();
        return [
            table1ColumnHelper.display({
                id: "selection",
                header: ({ table }) => (
                    <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                        checked={selectedRows.length === classroomData.courses.length}
                        onChange={handleSelectAllRows}
                    />
                ),
                cell: (info) => (
                    <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                        checked={selectedRows.includes(info.row.original.id)}
                        onChange={() => handleSelectRow(info.row.original.id)}
                    />
                ),
                meta: { width: "55px" },
            }),
            table1ColumnHelper.accessor("name", {
                cell: (info) => (
                    <div className=" items-center">
                        <Text size="7xl" as="p" className="tracking-[0.10px] ">
                            {info?.getValue?.()} {/* HERE IS WHERE THE LINK WILL GO */}
                        </Text>
                    </div>
                ),
                header: (info) => (
                    <Heading size="4xl" as="h3" className=" !text-blue_gray-500 items-center justify-center">
                        Name
                    </Heading>
                ),
                meta: { width: "auto" },
            }),
            table1ColumnHelper.accessor("isOpen", {
                cell: (info) => (
                    <div className="justify-center items-center md:self-stretch">
                        {info?.getValue?.()}
                    </div>
                ),
                header: (info) => (
                    <div className="flex items-center gap-1 justify-center">
                        <Heading size="4xl" as="h4" className="!text-blue_gray-500">
                            Status
                        </Heading>
                    </div>
                ),
                meta: { width: "auto" },
            }),
            table1ColumnHelper.accessor("createdDate", {
                cell: (info) => (
                    <div className="flex">
                        <Button
                            color="blue_gray_50_01"
                            size="6xl"
                            shape="square"
                            className="tracking-[0.10px]"
                        >
                            {formatDate(info?.getValue?.())}
                        </Button>
                    </div>
                ),
                header: (info) => (
                    <Heading size="4xl" as="h3" className=" !text-blue_gray-500 items-center justify-center">
                        Date Created
                    </Heading>
                ),
                meta: { width: "full" },
            }),
            table1ColumnHelper.accessor("grade", {
                cell: (info) => (
                    <div className="flex items-center justify-center">
                        <Text
                            size="6xl"
                            as="p"
                            className="tracking-[0.10px] !text-blue_gray-500"
                        >
                            {info?.getValue?.()}
                        </Text>
                    </div>
                ),
                header: (info) => (
                    <div className="flex items-center justify-center">
                        <Button
                            color="transparent"
                            size="4xl"
                            shape="square"
                            rightIcon={<Img src="/images/img_help_circle.svg" alt="help-circle" className="h-[16px] w-[16px]" />}
                            className="font-semibold"
                        >
                            <Heading size="4xl" as="h3" className=" !text-blue_gray-500 items-center">
                                Grade
                            </Heading>
                        </Button>
                    </div>
                ),
                meta: { width: "full" },
            }),
        ];
    }, [selectedRows, classroomData]);

    return (
        <div className="flex overflow-y-auto">
            <div className="flex flex-col w-[85%] gap-4 p-4 md:w-full">
                <div className="flex w-full gap-2 justify-end">
                    <Button
                        size="3xl"
                        variant="outline"
                        shape="round"
                        className="min-w-[166px] font-roboto font-medium tracking-[0.10px] sm:px-5"
                        onClick={() => setIsAddingCourse(true)}
                    >
                        Add Courses
                    </Button>
                </div>

                <div className="flex gap-6 rounded-lg md:flex-col">
                    {classroomData.courses.map((course, index) => (
                        <TeacherCourseCard
                            key={index}
                            className={course.name} // Adjust according to  data structure
                            courseName={course.name} // Example: "Not Started", "Continue"
                            expectedDuration={course.expectedDuration}
                            durationUnit={ConvertDuration(course.durationUnit)}
                            courseId={course.id}
                            to={`/course/${course.id}`} // Adjust routing as needed
                        />
                    ))}
                </div>
            </div>

            <div class="w-px min-h-[90vh] bg-gray-300"></div>

            <AddCourseModal
                open={isAddingCourse}
                onClose={() => setIsAddingCourse(false)}
                classId={classroomData.id}
                classroomData={classroomData}
                reloadClassroomDetails={reloadClassroomDetails}
            />

            <div className="flex flex-col w-[25%] h-full m-4 gap-5 md:w-[0%] md:hidden">
                <QuickLink classroomData={classroomData} />
            </div>
        </div>
    );
};

export default AssignedCourses;
