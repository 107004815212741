import React from "react";
import { Helmet } from "react-helmet";
import { Text, Img, Heading, Button, CheckBox, Input } from "../../../../components";
import RegisterOneRow from "../../../../components/RegisterOneRow";
import { useNavigate } from 'react-router-dom';
import TopNavLogin from "components/TopNavLogin";
import RegisterStudentForm from "./RegisterStudentForm";
import LoginIPadStudent from "components/LoginIPadStudent";


export default function RegisterOnePage() {
  return (
    <>
      <Helmet>
        <title>Register | Intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full flex-col gap-2 bg-white-A700">
        <TopNavLogin />
        <div className="flex items-start gap-7 md:flex-col">
          <div className="mt-6 flex flex-1 flex-col items-center gap-[35px] md:self-stretch md:p-5 pt-30">
            <div className="flex flex-col items-center">
              <Heading size="15xl" as="h1" className="!text-gray-900_05">
                Student Registration
              </Heading>
              <Text as="p" className="!text-gray-900_05">
                A class code is required to register. If you do not have a class code, please contact your teacher.{" "}
              </Text>
              <Text as="p" className="mt-1.5 !text-gray-700_01">
                <span className="text-gray-700_01">
                  A class code is an 8-character, alphanumeric code that is provided by your teacher and will look like
                </span>
                <span className="font-bold italic text-gray-700_01">GFRW-9KLH.</span>
              </Text>
            </div>
            <RegisterStudentForm />
          </div>
          <LoginIPadStudent />
        </div>
      </div>
    </>
  );
}
