import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { Img } from 'components';
import { createShareCode } from 'TeacherAPIService';
import { IconCopy } from '@tabler/icons-react'
const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

const mockClassToken = {
    id: "08dc711b-7374-484e-8be3-be0dbb31c48c",
    token: "ZJWP-GDKY",
    generatedUtc: "2024-05-10T18:03:09.3778056Z",
    classId: "08dc7119-a8f2-4311-871d-f0352116fcc5"
};

const ShareClassCode = ({ open, onClose, classTokenInfo, className, classroomID }) => {

    const [copySuccess, setCopySuccess] = useState('');
    const [createdNewCode, setCreatedNewCode] = useState(false);
    const [classCode, setClassCode] = useState('');

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(classTokenInfo.token);
            setCopySuccess('Copied!');
        } catch (err) {
            setCopySuccess('Failed to copy!');
        }
    };

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: `${className} Class Token`,
                    text: `${classTokenInfo.token}`,
                });
            } catch (error) {
                console.error('Error sharing content:', error);
            }
        } else {
            // Fallback: Copy URL to clipboard
            copyToClipboard()
            console.error('No sharing api')
        }
    };

    useEffect(() => {
        setClassCode(classTokenInfo.token)

    }, []);

    useEffect(() => {

    }, [classCode]);





    const handleNewCode = async () => {

        const fetchData = async () => {
            try {
                const newClassCode = await createShareCode(classroomID);
                setCreatedNewCode(true);
                setClassCode(newClassCode.token)
            } catch (error) {
                console.error('Error fetching classrooms:', error);
            } finally {

            }

        };

        fetchData();
    };



    return (
        <Popup open={open} closeOnDocumentClick onClose={onClose} modal contentStyle={{ width: '500px', height: '300px', borderRadius: '1rem' }}>
            <div className="flex flex-col items-center h-full p-4 gap-4">
                <h1 className='text-lg font-semibold'>{className}</h1>


                <div className='flex flex-col w-full rounded-lg h-full gap-4 p-4'>

                    <div className='flex justify-between items-center'>
                        <p>Class Code: <span className='text-gray-600'>{classCode}</span></p>

                        <button
                            onClick={copyToClipboard}
                            className="flex px-4 py-1 gap-2 bg-blue-500 text-white-A700 rounded hover:bg-blue-400"
                        >
                            <span className='text-md'>Copy Code</span>
                            <IconCopy />
                        </button>
                    </div>

                </div>

                <button
                    onClick={handleShare}
                    className="flex px-4 py-1 gap-2 bg-blue-500 text-white-A700 rounded hover:bg-blue-400"
                >
                    <span className='text-md'>Share Class Code</span>
                    <IconCopy />
                </button>

                <div className='flex flex-col'>
                    <button
                        onClick={handleNewCode}
                        className="flex px-4 py-1 gap-2 bg-blue-500 text-white-A700 rounded hover:bg-blue-400"
                    >
                        <span className='text-md'>New Class Code</span>
                    </button>


                </div>

                {createdNewCode && (
                    <div>
                        <h2 className='text-md text-green-500 font-semibold'>Code Created Successfully</h2>
                    </div>
                )}


            </div>
        </Popup>
    );
};

export default ShareClassCode;