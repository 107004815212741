// HomeDate.js
import React, { useState, useContext, useEffect } from "react";
import { Text, Heading } from "./..";
import JoinClassPopup from "../JoinClassPopup";
import CurrentDate from "components/CurrentDate";
import { UserContext } from 'UserContext';

export default function HomeDate({ ...props }) {
  const [showPopup, setShowPopup] = useState(false);
  const { userInfo, setUserInfo } = useContext(UserContext);

  const handleJoinClassClick = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && <JoinClassPopup onClose={closePopup} />}
      <header
        {...props}
        className={`${props.className} flex self-stretch items-center p-2 border-blue_gray-100 border-b border-solid bg-white-A700`}
      >
        <div className="flex w-full justify-between gap-5 ">
          <div className="flex bg-white-A700 items-center rounded-lg">
            <Heading size="11xl" as="h4" className="!text-gray-800_01">
              <CurrentDate />
            </Heading>
          </div>
          <div className="flex justify-end py-3">
            <div className="flex justify-center rounded-lg bg-white-A700 cursor-pointer">
              {userInfo?.role === 'Student' && (
                <div className="flex rounded-lg">
                  <Text
                    as="p"
                    className="!font-medium tracking-[0.10px]"
                    onClick={handleJoinClassClick}
                  >
                    Have a Class Code?
                  </Text>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
