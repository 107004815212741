import React from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Heading, Img, Input } from "../../components";
import TryAgainModalRow from "../../components/TryAgainModalRow";

export default function TryAgainModalPage() {
  return (
    <>
      <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-max w-full justify-center rounded-lg bg-gray-500_66 py-[351px] md:py-5">
        <div className="container-md flex justify-center pl-[449px] pr-[536px] md:p-5 md:px-5">
          <div className="flex w-full flex-col items-center rounded-[7px] border border-solid border-blue_gray-100 bg-white-A700_cc px-3.5 pb-4 pt-3.5">
            <Img
              src="images/img_avatars_3d_avatar_26.png"
              alt="avatars3d"
              className="h-[116px] w-[116px] object-cover"
            />
            <Heading size="10xl" as="h2" className="mt-[11px] !text-[22.19px] tracking-[0.09px]">
              Sorry, that is incorrect{" "}
            </Heading>
            <Text size="3xl" as="p" className="mt-1.5 !text-[11.1px] !text-gray-900_4c">
              Let’s change it up a bit and try that again
            </Text>
            <Button size="5xl" shape="round" className="mt-[58px] w-full font-semibold shadow-sm sm:px-5">
              Lets try that again
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
