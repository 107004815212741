import React from 'react';

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  };
}

function describeArc(x, y, radius, startAngle, endAngle){
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    const d = [
        "M", start.x, start.y, 
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");

    return d;       
}

const DonutChart = ({ size, thickness, categories }) => {
    const radius = (size - thickness) / 2;
    const center = size / 2;
    let cumulativePercentage = 0;

    return (
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
            <circle
                cx={center}
                cy={center}
                r={radius}
                fill="transparent"
                stroke="#eee"
                strokeWidth={thickness}
            />
            {categories.map((category, index) => {
                const startAngle = cumulativePercentage * 360 / 100;
                const endAngle = (cumulativePercentage + category.value) * 360 / 100;
                cumulativePercentage += category.value;
                return (
                    <path
                        key={category.name}
                        d={describeArc(center, center, radius, startAngle, endAngle)}
                        fill="transparent"
                        stroke={category.color}
                        strokeWidth={thickness}
                        strokeLinecap="round"
                    />
                );
            })}
        </svg>
    );
};

export default DonutChart;
