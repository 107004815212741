import React from "react";
import { Helmet } from "react-helmet";
import { Img, Text, Button, Heading } from "../../components";
import Header from "../../components/Header";
import { ReactTable } from "../../components/ReactTable";
import StudentSidebar from "../../components/StudentSidebar";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from 'react-router-dom'

const table1Data = [
  {
    rowname: "Financial Savings 101",
    rowstatus: "Completed",
    students: "20",
    instructors: "Olivia H, John D",
    datecreated: "Jan 22, 2024",
    grade: "9",
    tableheaderone: "images/img_notification.svg",
  },
  {
    rowname: "Financial Savings 101",
    rowstatus: "Archived",
    students: "20",
    instructors: "Olivia H, John D",
    datecreated: "Jan 22, 2024",
    grade: "9",
    tableheaderone: "images/img_notification.svg",
  },
];

export default function AssignedcoursesPage() {
  const navigate = useNavigate();

  const handleAllClassesClick = () => {
    navigate('/teacherhome');
  };

  const handleAssignedCoursesClick = () => {
    navigate('/assignedcourses');
  };

  const handleStudentsClick = () => {
    navigate('/students');
  };

  const handleInstructorClick = () => {
    navigate('/instructor');
  };

  const handleAssignmentClick = () => {
    navigate('/assignments');
  };

  const handleStudentPerformanceClick = () => {
    navigate('/performancecourses');
  };

  const table1Columns = React.useMemo(() => {
    const table1ColumnHelper = createColumnHelper();
    return [
      table1ColumnHelper.accessor("rowname", {
        cell: (info) => (
          <div className="flex items-center gap-3 self-end">
            <div className="h-[20px] w-[20px] ml-6 self-start rounded-md border border-solid border-blue_gray-100 bg-white-A700" />
            <Text size="7xl" as="p" className="tracking-[0.10px] mr-24 !text-blue-A400 underline">
              {info?.getValue?.()}
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex gap-3 px-6 pb-[11px] pt-3 md:p-5 sm:px-5">
            <div className="h-[20px] w-[20px] rounded-md border bg-white-A700" />
            <Heading size="4xl" as="h3" className=" pl-[5.55rem] !text-blue_gray-500">
              Name
            </Heading>
          </div>
        ),
        meta: { width: "246px" },
      }),
      table1ColumnHelper.accessor("rowstatus", {
        cell: (info) => (
          <div className="flex-1 self-end md:self-stretch">
            <Button
              color="gray_100_02"
              size="lg"
              shape="round"
              leftIcon={<Img src="images/img_dot_green_a700_01.svg" alt="_dot" className="h-[10px] w-[10px]" />}
              className="w-full gap-2 tracking-[0.10px]"
            >
              {info?.getValue?.()}
            </Button>
          </div>
        ),
        header: (info) => (
          <div className="flex items-center gap-1 px-6 py-3 md:p-5 sm:px-5">
            <Heading size="4xl" as="h4" className="!text-blue_gray-500">
              Status
            </Heading>
            <Img src="images/img_arrow_down_blue_gray_500_1.svg" alt="arrowdown" className="h-[16px] w-[16px]" />
          </div>
        ),
        meta: { width: "156px" },
      }),
      table1ColumnHelper.accessor("students", {
        cell: (info) => (
          <div className="flex">
            <Button
              color="white"
              size="6xl"
              shape="square"
              className="min-w-[91px] tracking-[0.10px] sm:px-5"
            >
              {info?.getValue?.()}
            </Button>
          </div>
        ),
        header: (info) => (
          <Button
            color="transparent"
            size="4xl"
            shape="square"
            className="min-w-[91px] font-semibold md:p-5"
          >
            Students
          </Button>
        ),
        meta: { width: "91px" },
      }),
      table1ColumnHelper.accessor("instructors", {
        cell: (info) => (
          <div className="flex-1 md:self-stretch ml-6">
            <Button
              color="white"
              size="6xl"
              shape="square"
              className="w-full tracking-[0.10px] sm:pl-5"
            >
              {info?.getValue?.()}
            </Button>
          </div>
        ),
        header: (info) => (
          <Heading size="4xl" as="h5" className="py-3 pl-6 !text-blue_gray-500 md:p-5 sm:pl-5">
            Instructor(s)
          </Heading>
        ),
        meta: { width: "158px" },
      }),
      table1ColumnHelper.accessor("datecreated", {
        cell: (info) => (
          <div className="flex">
            <Button
              color="blue_gray_50_01"
              size="6xl"
              shape="square"
              className="min-w-[131px] tracking-[0.10px] sm:pl-5 ml-[-10px]"
            >
              {info?.getValue?.()}
            </Button>
          </div>
        ),
        header: (info) => (
          <Button
            color="transparent"
            size="4xl"
            shape="square"
            className="min-w-[131px] font-semibold md:p-5 sm:pl-5"
          >
            Date Created
          </Button>
        ),
        meta: { width: "131px" },
      }),
      table1ColumnHelper.accessor("grade", {
        cell: (info) => (
          <div className="flex items-center justify-center">
            <Text
              size="7xl"
              as="p"
              className="tracking-[0.10px] !text-blue_gray-500"
            >
              {info?.getValue?.()}
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex items-center justify-center min-w-[104px]">
            <Button
              color="transparent"
              size="4xl"
              shape="square"
              rightIcon={<Img src="images/img_help_circle.svg" alt="help-circle" className="h-[16px] w-[16px]" />}
              className="font-semibold md:p-5 sm:pl-5"
            >
              Grade
            </Button>
          </div>
        ),
        meta: { width: "104px" },
      }),
      
      table1ColumnHelper.accessor("tableheaderone", {
        cell: (info) => (
          <div className="flex flex-col items-center self-end">
            <Img src={info?.getValue?.()} alt="notification" className="h-[24px] w-[24px]" />
          </div>
        ),
        header: (info) => <div className="h-[44px] md:p-5" />,
        meta: { width: "61px" },
      }),
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-center overflow-auto bg-white-A700 md:flex-col">
        <StudentSidebar />
        <div className="w-full pr-2 md:p-5 pt-[20px]">
          <div className="pb-2 pl-2">
            <div className="flex h-[20px] flex-col">
              <div className="mt-[20] flex self-start px-4 py-3.5">
                <div className="flex gap-2 cursor-pointer" onClick={handleAllClassesClick}>
                  <Img src="images/img_arrow_left_blue_a200.svg" alt="arrowleft" className="h-[20px]" />
                  <div className="flex">
                    <Heading as="h1" className="!text-blue-500 pt-1">
                      All Classes
                    </Heading>
                  </div>
                </div>
              </div>
              <div className="relative mt-[-3px] flex items-center justify-between gap-5 px-4 sm:flex-col">
                <div className="flex flex-col items-start gap-0.5">
                  <Heading size="11xl" as="h2" className="!font-poppins !text-gray-900_05 pb-4">
                    Class Financial 101
                  </Heading>
                  <div className="flex gap-3 self-center pb-4">
                    <Text as="p" className="self-start tracking-[0.25px] !text-gray-800_01">
                      20 students
                    </Text>
                    <Text as="p" className="self-end tracking-[0.25px] !text-gray-800_01">
                      |
                    </Text>
                    <Text as="p" className="self-start tracking-[0.25px] !text-gray-800_01">
                      Grade 4th
                    </Text>
                    <div className="flex flex-wrap gap-1.5 self-end">
                      <Text as="p" className="tracking-[0.25px] !text-gray-800_01">
                        |
                      </Text>
                      <Text as="p" className="tracking-[0.25px] !text-gray-800_01">
                        Jan, 24 2024
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="mb-2 flex gap-4 self-end">
                  <Button color="blue_gray_100" size="2xl" variant="outline" shape="round" className="w-[40px] border">
                    <Img src="images/img_search.svg" />
                  </Button>
                  <Button
                    size="3xl"
                    shape="round"
                    leftIcon={<Img src="images/img_plus_white_a700.svg" alt="plus" className="h-[18px] w-[18px]" />}
                    className="min-w-[157px] gap-2 font-roboto font-medium tracking-[0.10px] sm:pr-5"
                  >
                    Add Instructor
                  </Button>
                </div>
              </div>
              <div className="flex border-b border-solid border-blue_gray-100 px-4 sm:flex-col">
                      <div className="flex px-4 pb-3.5 pt-4 cursor-pointer" onClick={handleAssignedCoursesClick}>
                          <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-blue-A200">
                            Assigned courses
                          </Text>
                        </div>
                        <div className="flex p-[15px]">
                          <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01 cursor-pointer"  onClick={handleStudentsClick}>
                            Students
                          </Text>
                        </div>
                        <div className="flex px-4">
                          <div className="flex py-[15px]">
                            <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01 cursor-pointer"  onClick={handleInstructorClick}>
                              Instructors
                            </Text>
                          </div>
                        </div>
                        <div className="flex px-4 pb-3.5 pt-4">
                          <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01 cursor-pointer" onClick={handleAssignmentClick}>
                            Assignment
                          </Text>
                        </div>
                        <div className="flex w-[13%] flex-col items-center px-[15px] pt-[15px] sm:w-full">
                          <div className="flex w-full flex-col">
                            <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01 cursor-pointer" onClick={handleStudentPerformanceClick}>
                              Student Performance
                            </Text>
                          </div>
                        </div>
                      </div>
                <div>
                <div className="px-4 pb-[78px] md:pb-5">
                  <div className="flex items-start gap-4 pr-[91px] md:flex-col md:pr-5 pt-16">
                    <div className="flex h-screen flex-1 flex-col justify-center gap-3 pb-[777px] pt-4 md:self-stretch md:pb-5">
                      <div className="flex items-center gap-2 self-end px-4">
                        <Button
                          size="3xl"
                          variant="outline"
                          shape="round"
                          className="min-w-[166px] font-roboto font-medium tracking-[0.10px] sm:px-5"
                        >
                          Create Credentials
                        </Button>
                        <div className="flex flex-col items-center p-1">
                          <Img src="images/img_notification.svg" alt="notification" className="h-[24px] w-[24px]" />
                        </div>
                      </div>
                      <div className="p-1 w-[97%] rounded-lg border border-solid border-blue_gray-50_01 md:w-full" style={{ boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)', margin: '5px' }}>
                        <ReactTable
                          size="lg"
                          bodyProps={{ className: "" }}
                          headerProps={{
                            className: "border-blue_gray-50_01 border-b border-solid bg-gray-50_01 md:flex-col",
                          }}
                          rowDataProps={{ className: "md:flex-col" }}
                          className="w-[97%]"
                          columns={table1Columns}
                          data={table1Data}
                        />
                      </div>
                    </div>
                    <div className="flex w-[19%] flex-col gap-6 md:w-full">
                      <div className="flex px-4">
                        <div className="flex pb-3.5 pt-4">
                          <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01">
                            Quick Links
                          </Text>
                        </div>
                      </div>
                      <div className="flex flex-col gap-6">
                        <div className="flex flex-1 items-center justify-center gap-2 rounded-lg border border-solid border-blue_gray-50_01 p-2">
                          <div className="flex flex-col items-center rounded-md bg-purple-50 px-3 pb-4 pt-[15px]">
                            <Img src="images/img_user_gray_800_01.svg" alt="add_course" className="h-[10px]" />
                          </div>
                          <div className="flex flex-1 items-center justify-between gap-5">
                            <Text as="p" className="tracking-[0.50px] !text-gray-800_01">
                              Add Course
                            </Text>
                            <Img src="images/img_plus.svg" alt="add_course" className="h-[16px] w-[16px]" />
                          </div>
                        </div>
                        <div className="flex flex-1 items-center justify-center gap-2 rounded-lg border border-solid border-blue_gray-50_01 p-2">
                          <Button color="teal_50" size="2xl" className="w-[40px] rounded-md">
                            <Img src="images/img_television_gray_800_01.svg" />
                          </Button>
                          <div className="flex flex-1 items-center justify-center gap-4">
                            <Text as="p" className="w-[88%] leading-4 tracking-[0.50px] !text-gray-800_01">
                              Create Assignments
                            </Text>
                            <Img src="images/img_plus_gray_900_07.svg" alt="plus" className="h-[16px] w-[16px]" />
                          </div>
                        </div>
                        <div className="flex flex-1 items-center justify-center gap-2 rounded-lg border border-solid border-blue_gray-50_01 p-2">
                          <Img
                            src="images/img_container_light_blue_50.svg"
                            alt="container"
                            className="h-[42px] rounded-md"
                          />
                          <div className="flex flex-1 items-center justify-center gap-4">
                            <Text as="p" className="w-[88%] leading-4 tracking-[0.50px] !text-gray-800_01">
                              Create Simulator Excercise
                            </Text>
                            <Img src="images/img_plus_gray_900_07.svg" alt="plus" className="h-[16px] w-[16px]" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
