import React from "react";
import { Img, Heading } from "./..";

export default function Header({ ...props }) {
  return (
    <header
      {...props}
      className={`${props.className} flex items-center border-blue_gray-100 border-b border-solid bg-white-A700`}
    >
      <div className="container-xs flex items-left justify-between gap-5 md:flex-col">
        <div className="flex bg-white-A700 py-[7px] pl-[20.50rem]">
          <Heading size="11xl" as="h4" className="relative left-[-465px]">
            Good evening 🎑
          </Heading>
        </div>
        <Img src="images/img_frame_38.svg" alt="image" className="h-[24px] w-[40%] md:w-full" />
      </div>
    </header>
  );
}
