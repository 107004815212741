import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Text, Img, Heading, Button, CheckBox, Input, BackButton } from "../../../../components";
import TopNavLogin from "components/TopNavLogin";
import LoginIPadOne from "components/LoginIPadOne";

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;


export default function ConfirmEmailPage() {
    const [status, setStatus] = useState("pending");
    const [message, setMessage] = useState("");

    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token")
    const userId = searchParams.get("userId")



    useEffect(() => {
        // TODO: More params
        fetch(`${API_ENDPOINT}/confirmEmail?userId=${userId}&code=${token}`, {
            method: "GET",
            credentials: "include",
            headers: {
                'accept': 'text/plain'
            }
    })
        .then((data) => {
            // handle success or error from the server
            if (data.ok) {
                setStatus("success");
                setMessage("Email confirmation successful!")
            }
            else {
                data.json().then((d) => {
                    if (d.detail !== undefined) {
                        setStatus("error");
                        setMessage(d.detail);
                    } else {
                        setStatus("error");
                        setMessage("There was an issue confirming your email. Contact support or try again.");
                    }
                });
            }
        })
        .catch((error) => {
            // handle network error
            console.error(error);
            setStatus("error");
            setMessage("There was an issue confirming your email. Contact support or try again.");
        });
}, [userId, token])


return <>
    <Helmet>
        <title>Confirm Email | Intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
    </Helmet>
    <div className="flex w-full flex-col gap-2 bg-white-A700">
        <TopNavLogin />
        <div className="flex items-start gap-7 md:flex-col">
            <div className="mt-6 flex flex-1 flex-col items-center gap-[35px] md:self-stretch md:p-5">
                <div className="flex flex-col items-center">
                    <Heading size="15xl" as="h1" className="!text-gray-900_05">
                        Confirm Email
                    </Heading>
                    <Text as="p" className="!text-gray-900_05">
                        {message}
                    </Text>
                </div>
            </div>
            <LoginIPadOne />
        </div>
    </div>
</>
} 