import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Heading, Img, Text } from "../../components";
import StudentSidebar from "../../components/StudentSidebar";
import { useNavigate } from 'react-router-dom';
import JoinClassPopup from "../../components/JoinClassPopup"; // Import the JoinClassPopup component
import CourseCard from "components/CourseCard";
import HomeDate from "components/HomeDate";
import { ConvertDuration } from "utils";
import { fetchInfo, fetchStudentId, fetchStudentPortfolioGraph } from "SimulatorAPIService";

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

export default function CoursesPage() {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [studentId, setStudentId] = useState();

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = await fetchStudentId();
        setStudentId(id);

        if (id) {
          const coursesResponse = await fetch(`${API_ENDPOINT}/api/Students`, {
            credentials: "include",
            headers: {
              'accept': 'text/plain',
            }
          });

          if (coursesResponse.status === 401) {
            navigate("/timeout");
            return;
          }

          const coursesData = await coursesResponse.json();
          setCourses(coursesData.flatMap(s => s.courses.map(c => ({
            studentId: s.studentId,
            course: c.course,
            class: c.class
          }))));
        }
      } catch (error) {
        if (error.status === 401) {
          navigate("/timeout");
        } else {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>

      <div className="flex w-full h-screen overflow-hidden m-auto">
        <div><StudentSidebar /></div>

        <div className="flex flex-col w-full m-4 gap-4">
          <HomeDate className="w-full px-4 rounded-lg" />
          <div className="flex w-full rounded-lg gap-4 overflow-y-auto">
            <div className="bg-white-A700 px-6 pt-6 md:pb-5 sm:p-5">
              <div className="flex flex-col gap-6">
                <div className="flex self-start pb-[7px] pt-3.5">
                  <Heading size="9xl" as="h1">
                    My Courses
                  </Heading>
                </div>
                <div className="flex gap-6 rounded-lg md:flex-col">
                  {courses.map((course, index) => (
                    <CourseCard
                      key={index}
                      className={course.class.name} // Adjust according to data structure
                      courseName={course.course.name} // Example: "Not Started", "Continue"
                      expectedDuration={course.course.expectedDuration}
                      durationUnit={ConvertDuration(course.course.durationUnit)}
                      courseId={course.course.id}
                      studentId={studentId}
                      to={`/courses/course/${course.course.id}/student/${course.studentId}`} // Adjust routing as needed
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
