import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Heading, Text, Input, Img } from "..";
import SpendingPopupThankYou from "../../components/SpendingPopupThankYou";
import { useNavigate } from "react-router-dom";

const data = [
  { label: "Eat out almost every day", price: "$250/week" },
  { label: "Eat out 2-3 times per week", price: "$150/week" },
  { label: "Mostly homecooked meals", price: "$90/week" },
];

const SpendingStartComponent = ({ closePopup }) => {

  const [selectedOption, setSelectedOption] = useState(null);
  const [showNextPopup, setShowNextPopup] = useState(false);
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate('/BudgetSimulatorTwo');
  };

  if (showNextPopup) {
    return <SpendingPopupThankYou />;
  }

  return (
    <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000
      }}>
        <div style={{
          position: 'relative',
          width: '60%',
          minHeight: '40%',
          maxHeight: '90%', 
          padding: '20px',
          backgroundColor: 'white',
          borderRadius: '10px',
          overflowY: 'auto',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Helmet>
          <title>Meta title</title>
          <meta name="description" content="Web site created using create-react-app" />
        </Helmet>
        
        <div className="w-full">
          <Heading size="4xl" as="h2" className="!text-gray-900_02" style={{
              fontSize: '28px',
              fontWeight: 'bold',
              textAlign: 'center'
            }}>
            Thank you for your response!
          </Heading>
          <div className="flex justify-center w-full" style={{
              marginTop: '30px'
            }}>
            <Button
              color="blue_A200"
              size="3xl"
              shape="round"
              className="min-w-[191px] font-semibold shadow-xs sm:px-5"
              onClick={() => handleCardClick()}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              Begin Simulation
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpendingStartComponent;
