import React, { useEffect, useRef, memo } from 'react';

function AdvancedChart({ stockInfo }) {
    const container = useRef(null);

    useEffect(() => {
        container.current.innerHTML = '';

        if (stockInfo) {
            const script = document.createElement('script');
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
            script.type = 'text/javascript';
            script.async = true;
            script.innerHTML = JSON.stringify({
                "autosize": true,
                "symbol": `${stockInfo.profile?.exchange}:${stockInfo.profile?.symbol}`,
                "interval": "D",
                "timezone": "Etc/UTC",
                "theme": "light",
                "style": "1",
                "locale": "en",
                "allow_symbol_change": true,
                "calendar": false,
            });
            container.current.appendChild(script);
        }
    }, [stockInfo]);

    return (
        <div className="tradingview-widget-container" ref={container} style={{ height: "100%", width: "100%" }}>
            <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
            <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a></div>
        </div>
    );
}

export default memo(AdvancedChart);

