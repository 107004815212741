import React from "react";
import { Img, Heading } from "./..";

export default function RegisterOneRow({
  currencydollar = "images/img_currency_dollar.svg",
  twentyminutesone = "images/img_frame_25387.svg",
  duration = "20 minutes",
  twentyminutes = "images/img_frame_25385.svg",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} flex items-center w-full rounded`}>
      <div className="w-full rounded">
        <div className="rounded bg-yellow-100_01 pr-1 pt-1">
          <div className="flex flex-col items-end">
            <Img src={currencydollar} alt="currencydollar" className="mr-[7px] h-[59px] w-[59px] md:mr-0" />
            <div className="relative mt-[-14px] self-stretch py-2">
              <div className="px-[9px]">
                <div className="flex flex-col items-start gap-[3px]">
                  <Img src={twentyminutesone} alt="20_minutes" className="h-[8px] w-full" />
                  <div className="h-[14px] self-stretch rounded-[7px] bg-red-300" />
                  <Heading size="md" as="p" className="!text-[7.17px] !text-deep_orange-900_7f">
                    {duration}
                  </Heading>
                  <Img src={twentyminutes} alt="20_minutes" className="h-[11px] w-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
