import { Text, Img, Button, BackButton, Input, CheckBox } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

export default function ForgotPasswordForm() {
    // state variables for email and passwords
    const [email, setEmail] = useState("");
    // state variable for error messages
    const [error, setError] = useState("");

    const [success, setSuccess] = useState(false);

    const navigate = useNavigate();

    const handleClickBack = () => {
        navigate(-1);
    }
    
    // handle submit event for the form
    const handleSubmit = (e) => {
        e.preventDefault();
        // validate email and passwords
        if (!email) {
            setError("Please fill in all fields.");
        } else {
            // clear error message
            setError("");

            // post data to the /forgotPassword api
            fetch(API_ENDPOINT + "/forgotPassword", {
                method: "POST",
                mode: "cors",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                }),
            })
                .then((data) => {
                    // handle success or error from the server
                    if (data.ok) {
                        setError("Email Sent!");
                        setSuccess(true);
                    }
                    else
                        setError("There was a problem. Please contact support or try again later.");
                })
                .catch((error) => {
                    // handle network error
                    console.error(error);
                    setError("There was a problem. Please contact support or try again later.");
                });
        }
    };


    return (
        <div>
            {success ? <p>Email sent</p> :
                <form
                    onSubmit={handleSubmit}
                >
                    <div className="text-red-500 text-sm">
                        {error != "" ? error : ""}
                    </div>
                    <div className="flex flex-col gap-8">
                        <div className="flex flex-col gap-[23px]">
                            <div className="flex flex-col items-start gap-[5px]">
                                <Text as="p" className="!font-medium !text-blue_gray-800_01">
                                    Your Email
                                </Text>
                                <Input
                                    size="xl"
                                    shape="round"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={setEmail}
                                    className="self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                                />
                            </div>
                        </div>

                        <div className="flex gap-8 self-start">
                        <Button
                            size="5xl"
                            variant="outline"
                            shape="round"
                            leftIcon={<Img src="/images/img_arrowdown.svg" alt="arrow_down" className="h-[16px] w-[16px]" />}
                            className="min-w-[161px] gap-0.5 font-semibold shadow-sm sm:px-5 flex justify-center items-center"
                            onClick={handleClickBack}
                        >
                            Back
                        </Button>
                            <Button
                                size="5xl"
                                shape="round"
                                className="min-w-[367px] border border-solid border-blue-A200 font-semibold shadow-sm sm:px-5"
                            >
                                Reset Password
                            </Button>
                        </div>
                    </div>
                </form>}
        </div>

    );

};

