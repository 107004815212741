import React from "react";
import PropTypes from "prop-types";

const shapes = {
  circle: "rounded-[50%]",
  round: "rounded-lg",
  square: "rounded-[0px]",
};
const variants = {
  fill: {
    blue_500: "bg-blue-500 text-white-A700",
    blue_gray_100_05: "bg-blue_gray-100_05 text-white-A700",
    blue_50: "bg-blue-50 text-blue_gray-500",
    gray_100_05: "bg-gray-100_05 text-gray-900_07",
    white_A700: "bg-white-A700 text-blue_gray-500",
    blue_50_04: "bg-blue-50_04 text-blue-900",
    blue_gray_100: "bg-blue_gray-100 text-gray-900_07",
    gray_100_04: "bg-gray-100_04",
    blue_A200: "bg-blue-A200 text-white-A700",
    blue_400: "bg-blue-400 text-white-A700",
    gray_50_01: "bg-gray-50_01 text-blue_gray-500",
    black_900: "bg-black-900",
    gray_100_03: "bg-gray-100_03 text-blue_gray-400",
    gray_500: "bg-gray-500 text-red-A700",
    gray_100_02: "bg-gray-100_02 text-blue_gray-700_03",
    teal_50: "bg-teal-50",
    gray_100_01: "bg-gray-100_01 text-gray-900_07",
  },
  outline: {
    indigo_50: "border-indigo-50 border-b border-solid text-black-900",
    blue_A200: "border-blue-A200 border border-solid text-blue-A200",
    blue_gray_50_01: "border-blue_gray-50_01 border-b border-solid text-blue_gray-500",
    blue_gray_100: "border-blue_gray-100 border-b border-solid text-blue_gray-500",
  },
};
const sizes = {
  xs: "h-[22px] px-[7px] text-[7px]",
  xl: "h-[36px] px-6 text-sm",
  sm: "h-[22px] px-1",
  "7xl": "h-[64px] text-base",
  "2xl": "h-[40px] px-2",
  "5xl": "h-[48px] px-[35px] text-base",
  "4xl": "h-[44px] px-[18px] text-base",
  "8xl": "h-[72px] px-[35px] text-base",
  lg: "h-[32px] px-6 text-sm",
  "3xl": "h-[40px] px-6 text-sm",
  "6xl": "h-[56px] px-[35px] text-base",
  md: "h-[26px] px-1",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "fill",
  size = "md",
  color = "blue_A200",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex flex-row items-center justify-center text-center ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["circle", "round", "square"]),
  size: PropTypes.oneOf(["xs", "xl", "sm", "7xl", "2xl", "5xl", "4xl", "8xl", "lg", "3xl", "6xl", "md"]),
  variant: PropTypes.oneOf(["fill", "outline"]),
  color: PropTypes.oneOf([
    "blue_500",
    "blue_gray_100_05",
    "blue_50",
    "gray_100_05",
    "white_A700",
    "blue_50_04",
    "blue_gray_100",
    "gray_100_04",
    "blue_A200",
    "blue_400",
    "gray_50_01",
    "black_900",
    "gray_100_03",
    "gray_500",
    "gray_100_02",
    "teal_50",
    "gray_100_01",
    "indigo_50",
    "blue_gray_50_01",
  ]),
};

export { Button };
