import React from "react";
import { Helmet } from "react-helmet";
import { Text, Img, Heading, Button } from "../../../../components";
import { useNavigate } from 'react-router-dom';
import TopNavLogin from "components/TopNavLogin";
import LoginIPadStudent from "components/LoginIPadStudent";

export default function RegisterStudentSuccessPage() {
  const navigate = useNavigate();

  const handleToDashboardClick = () => {
    navigate('/loginpage');
  };


  return (
    <>
      <Helmet>
        <title>Register | Intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="w-full bg-white-A700">
        <TopNavLogin />
        <div className="relative mt-[-2px] flex items-center gap-[23px] md:flex-col">
          <div className="mb-2 flex flex-1 flex-col items-center justify-center gap-12 pb-[476px] pr-[7px] md:self-stretch md:p-5 md:pb-5">
            <div className="flex h-[583px] items-center justify-center self-stretch bg-[url(/public/images/img_group_92.svg)] bg-cover bg-no-repeat px-14 pb-[72px] pt-[132px] md:h-auto md:p-5">
              <div className="flex w-[61%] justify-center rounded-[7px] border border-solid border-blue_gray-100 bg-white-A700 px-14 py-[71px] min-w-fit md:p-5">
                <Img
                  src="/images/img_avatars_3d_avatar_13.png"
                  alt="avatars3d"
                  className="h-[206px] w-[206px] object-cover"
                />
              </div>
            </div>
            <div className="flex w-[95%] flex-col gap-[53px] md:w-full sm:gap-[26px]">
              <div className="flex flex-col items-center gap-4">
                <Heading size="15xl" as="h1" className="w-full text-center leading-[130%] !text-gray-900_05">
                  <>
                    Thank you for joining us!
                    <br />
                    Let&rsquo;s learn financial literacy together.
                  </>
                </Heading>
                <Text as="p" className="!text-gray-900_05">
                  You have been sent an email to confirm your account.
                </Text>
                <Text as="p" className="!text-gray-900_05">
                  You can now login to see your dashboard!
                </Text>
              </div>
              <div className="flex justify-center gap-2 px-[137px] md:px-5">
                <a href="/loginpage" onClick={handleToDashboardClick}>

                  <Button
                    size="5xl"
                    shape="round"
                    className="min-w-[278px] border border-solid border-blue-A200 font-semibold shadow-sm sm:px-5"
                  >
                    Go To Login Page
                  </Button>
                </a>
              </div>
            </div>
          </div>
          <LoginIPadStudent />
        </div>
      </div>
    </>
  );
}
