import React from "react"

export default function QuizAnswerChoice({ answer, onChange, val, selected, feedback }) {
    const handleChange = (e) => {
        if (onChange) onChange(e?.target?.checked);
    };

    return <label className={`flex-1 cursor-pointer gap-[22px] rounded-lg ` +
        `p-5 m-1 text-base text-black-900 sm:py-5 sm:pr-5 ` +
        `${feedback === "correct" ? "bg-green-500 text-white-A700"
            : feedback === "wrong" ? "bg-red-500 text-white-A700"
                : "bg-white-A700"}`}>
        <input
            className="mr-2 h-[24px] w-[24px] mr-5"
            checked={selected === val}
            type="radio"
            name="question"
            value="answer"
            onChange={handleChange}
            disabled={false}
        />
        <span className="shift-left">{answer.text}</span>
    </label>
};