import React from "react";
import { flexRender, getCoreRowModel, useReactTable, getSortedRowModel } from "@tanstack/react-table";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { IconChevronUp, IconChevronDown } from '@tabler/icons-react';

const variants = {
    simple: "bg-white-A700",
};
const sizes = {
    xs: "p-[5px]",
    sm: "p-2",
    md: "p-[11px]",
    lg: "p-3",
    xl: "p-5",
    "2xl": "p-6",
};

const getStatus = (progress) => {
    switch (progress) {
        case 0:
            return "Not Started";
        case 1:
            return "In Progress";
        case 2:
            return "Completed";
        default:
            return "";
    }
};

const getStatusClass = (progress) => {
    switch (progress) {
        case 0:
            return "text-red-500 bg-red-200 border border-red-600";
        case 1:
            return "text-yellow-700 bg-yellow-200 border border-yellow-700";
        case 2:
            return "text-green-700 bg-green-200 border border-green-700";
        default:
            return "";
    }
};

const ReactTableAnalytics = ({
    columns,
    data = [],
    headerProps = {},
    bodyProps = {},
    className = "",
    rowDataProps = { className: "" },
    size,
    variant,
    isLoading = false,
    expandedRows = {},
    handleExpandRow,
    handleExpandSection,
    expandedSections = {},
    orderType,
    ...restConfig
}) => {
    const tableConfig = {
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        ...restConfig,
    };

    const table = useReactTable(tableConfig);

    return (
        <table className={`${className} w-full text-left`}>
            <thead {...headerProps}>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <th key={header.id} {...header.column.columnDef?.meta} className="p-3 text-blue_gray-500 text-center">
                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...bodyProps}>
                {isLoading ? (
                    [...Array(10)].map((_, index) => (
                        <tr key={index} className={`${variant ? variants[variant] : ""} ${rowDataProps?.className}`}>
                            {columns.map((column, colIndex) => (
                                <td key={colIndex} className={`p-3 ${size ? sizes[size] : ''} text-center justify-center`}>
                                    <Skeleton />
                                </td>
                            ))}
                        </tr>
                    ))
                ) : (
                    table.getRowModel().rows.map((row) => (
                        <React.Fragment key={row.id}>
                            <tr
                                {...rowDataProps}
                                className={`${variant ? variants[variant] : ""} ${rowDataProps?.className}`}
                            >
                                {row.getVisibleCells().map((cell, cellIndex) => (
                                    <td key={cell.id} className={`p-3 ${size ? sizes[size] : ''} text-left justify-center`}>
                                        {cellIndex === 0 ? (
                                            <div className="flex gap-4 items-center justify-left">
                                                <button onClick={() => handleExpandRow(row.index)}>
                                                    {expandedRows[row.index] ? (<IconChevronDown />) : (<IconChevronUp />)}
                                                </button>
                                                <div>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </div>
                                            </div>
                                        ) : (
                                            flexRender(cell.column.columnDef.cell, cell.getContext())
                                        )}
                                    </td>
                                ))}
                            </tr>
                            {expandedRows[row.index] && (
                                <tr key={`expanded-${row.id}`} className="bg-gray-100">
                                    <td colSpan={columns.length} className="p-3">
                                        <div className="p-3">
                                            <table className="w-full text-left">
                                                <thead>
                                                    <tr>
                                                        {row.original.subColumns.map((col, index) => (
                                                            <th key={index} className="p-3 text-blue_gray-500 text-center">{col.header}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row.original.sections.map((section, sectionIndex) => (
                                                        <React.Fragment key={sectionIndex}>
                                                            <tr>
                                                                <td className="flex p-3 gap-4 text-left items-center ">
                                                                    <button onClick={() => handleExpandSection(row.index, sectionIndex)}>
                                                                        {expandedSections[row.index]?.[sectionIndex] ? (<IconChevronDown />) : (<IconChevronUp />)}
                                                                    </button>
                                                                    {section.sectionName}
                                                                </td>
                                                                <td className="p-3 text-center"><span className={`border p-2 rounded-lg ${getStatusClass(section.progress)}`}>{getStatus(section.progress)}</span></td>
                                                                <td className="p-3 text-center">{section.retriesUsed}</td>
                                                                <td className="p-3 text-center">{section.completedSectionInMinutes}</td>
                                                            </tr>
                                                            {expandedSections[row.index]?.[sectionIndex] && (
                                                                <tr key={`expanded-section-${sectionIndex}`} className="bg-gray-100">
                                                                    <td colSpan={row.original.subColumns.length} className="p-3">
                                                                        <div className="p-3">
                                                                            <table className="w-full text-left">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="p-3 text-blue_gray-500 text-center">Question</th>
                                                                                        <th className="p-3 text-green-500 text-center">Correct Answer</th>
                                                                                        <th className="p-3 text-red-500 text-center">Selected Answer</th>
                                                                                        
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {section.missedQuestions.filter(question => !question.correct).map((question, questionIndex) => (
                                                                                        <tr key={questionIndex}>
                                                                                            <td className="p-3 text-center">{question.questionText}</td>
                                                                                            <td className="p-3 text-center">{question.correctText}</td>
                                                                                            <td className="p-3 text-center">{question.selectedText}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))
                )}
            </tbody>
        </table>
    );
};

export { ReactTableAnalytics };
