import React from "react";
import { Text, Heading, Img } from "./..";

export default function RegisterColumniAmA({
  userimage = "images/img_avatars_3d_avatar_26.png",
  label = "I am a Student",
  header = "class code might be required. contact your teacher",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-center w-full pt-[37px] pb-9 gap-2 px-[37px] sm:p-5 border-blue_gray-100 border border-solid rounded-[7px] hover:bg-yellow-500 cursor-pointer`}
    >
      <Img src={userimage} alt="i_am_a_student" className="h-[116px] w-[116px] object-cover" />
      <Heading size="10xl" as="h5" className="!text-[22.19px] tracking-[0.09px] !text-gray-900_02 hover:!text-white">
        {label}
      </Heading>
      <Text size="3xl" as="p" className="w-[85%] text-center !text-[11.1px] leading-[130%] !text-gray-900_05 md:w-full hover:!text-white">
        {header}
      </Text>
    </div>
  );
}