import React from "react";
import { Heading, Img } from "./..";
import { useNavigate } from "react-router-dom";

// TODO: Progress Bar

const colors = {
  "orange": {
    bg: "bg-yellow-100_01",
    image: "/images/img_currency_dollar.svg",
    border: "border-deep_orange-900_87",
    text: "!text-deep_orange-900_7f",
    progress: "bg-deep_orange-900",
    progressbg: "bg-deep_orange-900_02",
  },
  "blue": {
    bg: "bg-blue-50_04",
    image: "/images/img_line_chart_up_03.svg",
    border: "border-blue-900_87",
    text: "!text-blue-900_7f",
    progress: "bg-blue-900_01",
    progressbg: "bg-blue-900_02",
  },
  "pink": {
    bg: "bg-purple-50",
    image: "/images/img_graduation_hat_02.svg",
    border: "border-pink-800_87",
    text: "!text-pink-800_7f",
    progress: "bg-pink-800",
    progressbg: "bg-pink-800_01",
  }
};

const colorKeys = Object.keys(colors); // Get array of color keys
const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

// Function to generate a seeded random number based on a string (courseName)
function seededRandom(seed) {
  let x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

export default function TeacherCourseCard({ 
  courseName, 
  to,
  className,
  expectedDuration,
  durationUnit,
  courseId,
  ...props 
}) {

  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate(to);
  };

  // Seed based on courseName
  const seed = courseName.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
  const colorIndex = Math.floor(seededRandom(seed) * colorKeys.length);
  const assignedColor = colorKeys[colorIndex];

  return (
    <div {...props} className={`${props.className} flex w-full max-w-[350px] rounded-lg course-card-hover`} onClick={handleCardClick} style={{ cursor: 'pointer' }}>
      <div className={`w-full rounded-lg ${colors[assignedColor].bg} pr-2 pt-2`}>
        <div className="flex flex-col items-end">
          <Img
            src={colors[assignedColor].image}
            alt="course icon"
            className="mr-3 h-[100px] w-[100px] md:mr-0"
          />
          <div className="relative mt-[-24px] flex flex-col items-start self-stretch px-4 pb-[17px] pt-[18px]">
            <Heading
              size="2xl"
              as="h2"
              className={`rounded border border-solid ${colors[assignedColor].border} p-[3px] ${colors[assignedColor].text} opacity-0.8`}
            >
              {className}
            </Heading>
            <Heading size="6xl" as="h3" className="!font-bold">
              {courseName}
            </Heading>
            <Heading as="h4" className={colors[assignedColor].text}>
              {`${expectedDuration} ${durationUnit}`}
            </Heading>
          </div>
        </div>
      </div>
    </div>
  );
}
