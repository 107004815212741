import React from "react";
import { Link } from 'react-router-dom';
import { Text } from "../../components";

const StockSimulatorTabs = ({ currentTab }) => {

    const tabClass = (tab) => {
        return currentTab === tab 
            ? "!font-roboto !font-medium tracking-[0.10px] !text-blue-500"
            : "!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01";
    };

    return (
        <>
            <Link to="/dashboard">
                <Text as="p" className={tabClass("dashboard")}>
                    Dashboard
                </Text>
            </Link>
            <Link to="/watchlist">
                <Text as="p" className={tabClass("watchlist")}>
                    Watchlist
                </Text>
            </Link>
            <Link to="/orders">
                <Text as="p" className={tabClass("orders")}>
                    Orders
                </Text>
            </Link>
            <Link to="/holdings">
                <Text as="p" className={tabClass("holdings")}>
                    Holdings
                </Text>
            </Link>
            <Link to="/heatmap">
                <Text as="p" className={tabClass("heatmap")}>
                    Heatmap
                </Text>
            </Link>
            <Link to="/economiccalendar">
                <Text as="p" className={tabClass("economiccalendar")}>
                    Economic Calendar
                </Text>
            </Link>
        </>
    );
};

export default StockSimulatorTabs;