import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const PortfolioChart = ({ portfolioData, height }) => {
    const [filteredData, setFilteredData] = useState([]);
    const [activeRange, setActiveRange] = useState('1W');

    useEffect(() => {
        if (portfolioData && portfolioData.historicalGraphPoints) {
            filterData('1W'); // Default filter to 1 day
        }
    }, [portfolioData]);

    const filterData = (range) => {
        const now = new Date();
        let filtered = [];

        switch (range) {
            case '1W':
                filtered = portfolioData.historicalGraphPoints.filter(point => {
                    const date = new Date(point.dateTime);
                    return now - date <= 7 * 24 * 60 * 60 * 1000;
                });
                break;
            case '1M':
                filtered = portfolioData.historicalGraphPoints.filter(point => {
                    const date = new Date(point.dateTime);
                    return now - date <= 30 * 24 * 60 * 60 * 1000;
                });
                break;
            case '6M':
                filtered = portfolioData.historicalGraphPoints.filter(point => {
                    const date = new Date(point.dateTime);
                    return now - date <= 6 * 30 * 24 * 60 * 60 * 1000;
                });
                break;
            case '1Y':
                filtered = portfolioData.historicalGraphPoints.filter(point => {
                    const date = new Date(point.dateTime);
                    return now - date <= 365 * 24 * 60 * 60 * 1000;
                });
                break;
            default:
                filtered = portfolioData.historicalGraphPoints;
                break;
        }

        setFilteredData(filtered.map(point => ({
            date: new Date(point.dateTime).toISOString(),
            value: point.portfolioValue
        })));
    };

    const handleRangeChange = (range) => {
        setActiveRange(range);
        filterData(range);
    };

    const options = {
        chart: {
            type: 'area',
            height: 350,
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        labels: filteredData.map(d => d.date),
        xaxis: {
            type: 'datetime',
            labels: {
                show: false // Hide x-axis labels
            }
        },
        yaxis: {
            opposite: true
        },
        legend: {
            horizontalAlign: 'left'
        },
        tooltip: {
            x: {
                format: 'dd MMM yyyy' // Custom date format to show the year
            },
            y: {
                formatter: function (val) {
                    return "$ " + val.toFixed(2);
                }
            }
        }
    };

    const series = [
        {
            name: "Portfolio Value",
            data: filteredData.map(d => d.value)
        }
    ];

    return (
        <div>

            <Chart options={options} series={series} type="area" height={height} />

            <div className="flex justify-center mb-4">
                {['1W', '1M', '6M', '1Y', 'All'].map(range => (
                    <button
                        key={range}
                        className={`px-4 py-2 m-2 rounded ${activeRange === range ? 'bg-blue-500 text-white-A700 w-24' : 'bg-gray-200 text-gray-800 w-15'}`}
                        onClick={() => handleRangeChange(range)}
                    >
                        {range}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default PortfolioChart;