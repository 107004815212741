import React, { useEffect, useRef, memo } from 'react';

function SymbolOverview({ stockSymbol, chartType }) {
    const container = useRef(null);

    useEffect(() => {
        container.current.innerHTML = '';

        if (stockSymbol) {
            const script = document.createElement('script');
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js';
            script.type = 'text/javascript';
            script.async = true;
            script.innerHTML = JSON.stringify({
                "symbols": [stockSymbol],
                "chartOnly": false,
                "width": "100%",
                "height": "100%",
                "locale": "en",
                "colorTheme": "light",
                "autosize": true,
                "showVolume": false,
                "showMA": false,
                "hideDateRanges": false,
                "hideMarketStatus": false,
                "hideSymbolLogo": false,
                "scalePosition": "right",
                "scaleMode": "Normal",
                "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
                "fontSize": "10",
                "noTimeScale": false,
                "valuesTracking": "1",
                "changeMode": "price-and-percent",
                "chartType": chartType,
                "maLineColor": "#2962FF",
                "maLineWidth": 1,
                "maLength": 9,
                "lineWidth": 2,
                "lineType": 0,
                "dateRanges": [
                    "1d|1",
                    "1m|1D",
                    "3m|60",
                    "12m|1D",
                    "60m|1W",
                    "all|1M"
                ]
            });
            container.current.appendChild(script);
        }
    }, [stockSymbol, chartType]);

    return (
        <div className="tradingview-widget-container" ref={container}>
            <div className="tradingview-widget-container__widget"></div>
        </div>
    );
}

export default memo(SymbolOverview);