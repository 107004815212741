import React, { useState, useEffect } from "react";
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import { createColumnHelper } from "@tanstack/react-table";
import { IconChevronUp, IconChevronDown } from '@tabler/icons-react';
import { ReactTableAnalytics } from "components/ReactTableAnalytics";
import StudentStockSimulator from "components/StudentStockSimulator";
import { fetchStudentDetails, fetchStudentProgress, fetchAnalyticsData } from "TeacherAPIService";
import AveragePerformanceChart from "components/AveragePerformanceChart";
import {IconSortAscendingLetters, IconSortDescendingLetters} from '@tabler/icons-react'

const Spinner = () => (
    <div className="spinner-container">
        <div className="spinner"></div>
    </div>
);

const studentDataCache = {};

export default function StudentPerformance({ classroomData }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [activeTab, setActiveTab] = useState(null);
    const [studentDetails, setStudentDetails] = useState(null);
    const [activeSection, setActiveSection] = useState('Courses');
    const [courseStatus, setCourseStatus] = useState(null);
    const [analyticsData, setAnalyticsData] = useState([]);
    const [isStudentLoading, setIsStudentLoading] = useState(false);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState({});
    const [expandedSections, setExpandedSections] = useState({});
    const [isAscending, setIsAscending] = useState(true); // State to track sort order
    const [sortedStudents, setSortedStudents] = useState(classroomData.students); // State to hold sorted students

    const handleSort = () => {
        const sorted = [...sortedStudents].sort((a, b) => {
            if (isAscending) {
                return a.last.localeCompare(b.last); // Sort by last name in ascending order
            } else {
                return b.last.localeCompare(a.last); // Sort by last name in descending order
            }
        });
        setSortedStudents(sorted);
        setIsAscending(!isAscending); // Toggle sort order
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option.course.name);
        setIsOpen(false);
    };

    const handleStudentInfo = async (studentId) => {
        setIsStudentLoading(true);
        if (studentDataCache[studentId]) {
            setStudentDetails(studentDataCache[studentId].details);
            setCourseStatus(studentDataCache[studentId].courseStatus);
            const processedAnalyticsData = processAnalyticsData(studentDataCache[studentId].analyticsData);
            setAnalyticsData(processedAnalyticsData);
            setIsStudentLoading(false);
            return;
        }

        const details = await fetchStudentDetails(studentId);
        setStudentDetails(details);
        if (details.courses && details.courses.length > 0) {
            setSelectedOption(details.courses[0].course.name);
            const progress = await handleStudentProgress(studentId, details.courses[0].course.id);
            const analytics = await fetchAnalyticsData(studentId, details.courses[0].course.id);
            const processedAnalyticsData = processAnalyticsData(analytics);
            studentDataCache[studentId] = {
                details,
                courseStatus: progress,
                analyticsData: processedAnalyticsData
            };
            setCourseStatus(progress);
            setAnalyticsData(processedAnalyticsData);
        }
        setIsStudentLoading(false);
    };

    const handleStudentProgress = async (studentId, courseId) => {
        const progress = await fetchStudentProgress(courseId, studentId);
        return progress;
    };

    const handleActiveSection = async (newSection) => {
        setActiveSection(newSection);
        if (newSection === "Courses" && studentDetails && studentDetails.courses.length > 0) {
            const progress = await handleStudentProgress(studentDetails.studentId, studentDetails.courses[0].course.id);
            setCourseStatus(progress);
            const analytics = await fetchAnalyticsData(studentDetails.studentId, studentDetails.courses[0].course.id);
            const processedAnalyticsData = processAnalyticsData(analytics);
            setAnalyticsData(processedAnalyticsData);
        }
    };

    const handleExpandRow = (rowIndex) => {
        setExpandedRows((prevState) => ({
            ...prevState,
            [rowIndex]: !prevState[rowIndex],
        }));
    };

    const handleExpandSection = (rowIndex, sectionIndex) => {
        setExpandedSections((prevState) => ({
            ...prevState,
            [rowIndex]: {
                ...prevState[rowIndex],
                [sectionIndex]: !prevState[rowIndex]?.[sectionIndex],
            }
        }));
    };

    useEffect(() => {
        if (classroomData.students && classroomData.students.length > 0) {
            setActiveTab(sortedStudents[0].id);
            handleStudentInfo(sortedStudents[0].id);
        }
    }, [classroomData, isAscending]);

    const calculateAvgTestReattempts = (analytics) => {
        const totalReattempts = analytics.reduce((acc, unit) => {
            return acc + unit.sections.reduce((sectionAcc, section) => sectionAcc + section.retriesUsed, 0);
        }, 0);
        const totalSections = analytics.reduce((acc, unit) => acc + unit.sections.length, 0);
        return totalSections ? (totalReattempts / totalSections).toFixed(2) : 0;
    };

    const calculateLateAssignments = (analytics) => {
        return analytics.reduce((acc, unit) => {
            return acc + unit.sections.reduce((sectionAcc, section) => sectionAcc + (section.late ? 1 : 0), 0);
        }, 0);
    };

    const getStatus = (progress) => {
        switch (progress) {
            case 0:
                return "Not Started";
            case 1:
                return "In Progress";
            case 2:
                return "Completed";
            default:
                return "";
        }
    };

    const getStatusClass = (progress) => {
        switch (progress) {
            case 0:
                return "text-red-500 bg-red-200 border border-red-600";
            case 1:
                return "text-yellow-700 bg-yellow-200 border border-yellow-700";
            case 2:
                return "text-green-700 bg-green-200 border border-green-700";
            default:
                return "";
        }
    };

    const processAnalyticsData = (analytics) => {
        return analytics.map((unit) => {
            const unitStatus = unit.sections.some(section => section.progress > 0 && section.progress < 100)
                ? 1 // In Progress
                : unit.sections.every(section => section.progress === 2)
                    ? 2 // Completed
                    : 0; // Not Started

            return {
                ...unit,
                unitStatus
            };
        });
    };

    const avgTestReattempts = calculateAvgTestReattempts(analyticsData);
    const lateAssignments = calculateLateAssignments(analyticsData);

    const table5Columns = React.useMemo(() => {
        const table5ColumnHelper = createColumnHelper();
        return [
            table5ColumnHelper.accessor("unitName", {
                cell: (info) => (
                    <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
                        {info.getValue()}
                    </div>
                ),
                header: () => "Modules",
                meta: { width: "auto" },
            }),

            table5ColumnHelper.accessor("unitStatus", {
                cell: (info) => (
                    <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
                        <span className={`border p-2 rounded-lg ${getStatusClass(info.getValue())}`}>
                            {getStatus(info.getValue())}
                        </span>
                    </div>
                ),
                header: () => "Status",
                meta: { width: "auto" },
            }),

            table5ColumnHelper.accessor("c", {
                cell: (info) => (
                    <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
                        {"20 min"}
                    </div>
                ),
                header: () => "Duration",
                meta: { width: "auto" },
            }),

            table5ColumnHelper.accessor("b", {
                cell: (info) => (
                    <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
                        {"Standard"}
                    </div>
                ),
                header: () => "Suggested Difficulty",
                meta: { width: "auto" },
            }),
        ];
    }, [expandedRows]);

    return (
        <Tabs className={"overflow-hidden"}>
            <div className="flex w-full h-[75vh] p-4 gap-4 overflow-auto">
                <div className="flex flex-col border min-w-[15%] max-w-[15%] rounded-lg">
                    <div className="flex justify-between items-center py-2 px-6 bg-blue-50 text-sm font-medium">
                        <h2>STUDENT NAME</h2>
                        <button onClick={handleSort} className="text-blue-500">
                            {isAscending ? <IconSortDescendingLetters/> : <IconSortAscendingLetters/>}
                        </button>
                    </div>
                    <TabList>
                        {sortedStudents.map((student) => {
                            const handleTabClick = async () => {
                                setActiveTab(student.id);
                                setActiveSection('Courses');
                                await handleStudentInfo(student.id);
                            };

                            return (
                                <Tab
                                    key={student.id}
                                    className="flex items-center py-2 px-6 text-sm"
                                    selectedClassName="bg-blue-100"
                                    onClick={handleTabClick}
                                >
                                    {student.first} {student.last}
                                </Tab>
                            );
                        })}
                    </TabList>
                </div>

                <div className="w-full overflow-hidden">
                    {classroomData.students.map((student) => (
                        <TabPanel key={student.id} className={"overflow-auto"}>
                            <div className="flex flex-col w-full overflow-auto">
                                {isStudentLoading ? (
                                    <div className="h-[70vh]">
                                        <Spinner />
                                    </div>

                                ) : (
                                    <Tabs className={"flex flex-col w-full overflow-auto h-[71vh] gap-4"}>
                                        <div className="flex w-full justify-between">
                                            {activeSection === 'Courses' && (
                                                <div className="relative">
                                                    <button
                                                        onClick={() => setIsOpen(!isOpen)}
                                                        className="flex items-center justify-between px-4 py-2 gap-1 text-gray-700 bg-white-A700 border border-gray-300 rounded-md shadow-sm focus:outline-none"
                                                    >
                                                        <div className="flex items-center text-sm truncate">
                                                            <span className="">{selectedOption}</span>
                                                        </div>
                                                        <svg
                                                            className={`w-4 h-4 transform ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                                                            fill="none"
                                                            stroke="currentColor"
                                                            viewBox="0 0 24 24"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M19 9l-7 7-7-7"
                                                            ></path>
                                                        </svg>
                                                    </button>
                                                    {isOpen && (
                                                        <div className="absolute z-10 w-full mt-2 bg-white-A700 border border-gray-300 rounded-lg shadow-md">
                                                            {studentDetails && studentDetails.courses.map((option) => (
                                                                <div
                                                                    key={option.course.id}
                                                                    onClick={() => handleOptionClick(option)}
                                                                    className="flex items-center rounded-lg p-4 text-gray-700 cursor-pointer bg-white-A700 hover:bg-gray-100"
                                                                >
                                                                    <span>{option.course.name}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            <TabList className={"flex flex-grow justify-end"}>
                                                {['Courses', 'Stock Simulator'].map((section) => (
                                                    <Tab
                                                        key={section}
                                                        onClick={() => setActiveSection(section)}
                                                        className={`px-4 py-2 rounded-full ${activeSection === section ? 'bg-blue-50 text-blue-500' : 'text-gray-700'} border-gray-300 focus:outline-none`}
                                                    >
                                                        {section}
                                                    </Tab>
                                                ))}
                                            </TabList>
                                        </div>

                                        <TabPanel className={"overflow-auto"}>
                                            <div className="flex flex-col gap-4">
                                                <div className="flex gap-4">
                                                    <div className="flex flex-col border rounded-lg p-4 w-[30%] gap-4">
                                                        <h2 className="font-medium text-left">Overview</h2>

                                                        <div className="flex flex-col gap-4 text-sm">
                                                            <p className="flex justify-between items-center"><span className="">Status: </span><span className={courseStatus?.courseStatus?.status === 0 ? 'text-red-500 bg-red-200 border border-red-600' : courseStatus?.courseStatus?.status === 1 ? 'text-green-700 bg-green-200 border border-green-700 p-1 rounded-lg' : 'text-gray-500'}>
                                                                {courseStatus?.courseStatus?.status === 0 ? 'Not Started' : courseStatus?.courseStatus?.status === 1 ? 'In Progress' : 'Completed'}
                                                            </span>
                                                            </p>

                                                            <p className="flex justify-between">Completion: <span>{courseStatus?.courseStatus?.percentageComplete}%</span></p>
                                                            <p className="flex justify-between">Avg Missed Questions: <span>{avgTestReattempts}</span></p>
                                                            <p className="flex justify-between">Late Assignments: <span>{lateAssignments}</span></p>
                                                        </div>

                                                    </div>

                                                    <div className="flex flex-col p-4 border rounded-lg w-full">
                                                        <div>
                                                            <h2>
                                                                Average Missed Questions Per Module
                                                            </h2>
                                                        </div>
                                                        <div>
                                                            <AveragePerformanceChart analyticsData={analyticsData} />
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="">
                                                    <ReactTableAnalytics
                                                        size="2xl"
                                                        bodyProps={{ className: "w-full" }}
                                                        headerProps={{
                                                            className: "w-full justify-center text-center items-center border-blue_gray-100 border-b border-solid bg-gray-50_01 md:flex-col",
                                                        }}
                                                        rowDataProps={{ className: "w-full border-b border-solid border-blue_gray-100" }}
                                                        className="w-full rounded-lg"
                                                        columns={table5Columns}
                                                        data={analyticsData.map(unit => ({
                                                            ...unit,
                                                            subColumns: [
                                                                { accessorKey: 'sectionName', header: 'Section Name' },
                                                                { accessorKey: 'progress', header: 'Progress' },
                                                                { accessorKey: 'retriesUsed', header: 'Missed Questions' },
                                                                { accessorKey: 'completedSectionInMinutes', header: 'Duration' },
                                                            ]
                                                        }))}
                                                        expandedRows={expandedRows}
                                                        handleExpandRow={handleExpandRow}
                                                        isLoading={isTableLoading}
                                                        handleExpandSection={handleExpandSection}
                                                        expandedSections={expandedSections}
                                                    />
                                                </div>

                                            </div>

                                        </TabPanel>
                                        <TabPanel className={"overflow-auto"}>
                                            <StudentStockSimulator studentId={student.id} />
                                        </TabPanel>
                                    </Tabs>
                                )}
                            </div>
                        </TabPanel>
                    ))}
                </div>
            </div>
        </Tabs>
    );
}

// CSS for the spinner
const style = `
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
}

.spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
`;

// Add the styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = style;
document.head.appendChild(styleSheet);