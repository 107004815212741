import React from "react";
import { Img, Text } from "./..";
import { useNavigate } from 'react-router-dom';

export default function DashboardWatchlist({
  headline = "META",
  headlineOne = "2.2",
  headlineTwo = "$10.00",
  price = "$10.00",
  headline1 = "META",
  headlineone1 = "2.2",
  headlinetwo1 = "$10.00",
  price1 = "$10.00",
  headline2 = "META",
  headlineone2 = "2.2",
  headlinetwo2 = "$10.00",
  price2 = "$10.00",
  headline3 = "META",
  headlineone3 = "2.2",
  headlinetwo3 = "$10.00",
  price3 = "$10.00",
  headline4 = "META",
  headlineone4 = "2.2",
  headlinetwo4 = "$10.00",
  price4 = "$10.00",
  headline5 = "META",
  headlineone5 = "2.2",
  headlinetwo5 = "$10.00",
  price5 = "$10.00",
  headline6 = "META",
  headlineone6 = "2.2",
  headlinetwo6 = "$10.00",
  price6 = "$10.00",
  headline7 = "META",
  headlineone7 = "2.2",
  headlinetwo7 = "$10.00",
  price7 = "$10.00",
  ...props
}) {
  const navigate = useNavigate();

  return (
    <div {...props} className={`${props.className} flex flex-col flex-grow `}>
      {[{
        headline, headlineOne, headlineTwo, price
      }, {
        headline: headline1, headlineOne: headlineone1, headlineTwo: headlinetwo1, price: price1
      }, {
        headline: headline2, headlineOne: headlineone2, headlineTwo: headlinetwo2, price: price2
      }, {
        headline: headline3, headlineOne: headlineone3, headlineTwo: headlinetwo3, price: price3
      }, {
        headline: headline4, headlineOne: headlineone4, headlineTwo: headlinetwo4, price: price4
      }, {
        headline: headline5, headlineOne: headlineone5, headlineTwo: headlinetwo5, price: price5
      }, {
        headline: headline6, headlineOne: headlineone6, headlineTwo: headlinetwo6, price: price6
      }, {
        headline: headline7, headlineOne: headlineone7, headlineTwo: headlinetwo7, price: price7
      }].map((stock, index) => (
        <div key={index} className="flex bg-white shadow rounded-lg m-2 p-4 cursor-pointer course-card-hover hover:shadow-md transition-shadow duration-300 flex-grow">
        <div className="flex w-full items-center">
          <div className="flex-1 flex items-center border-r border-gray-300 pr-2">
            <span className="text-lg tracking-wide">{stock.headline}</span>
          </div>
          <div className="flex flex-1 items-center justify-center p-2">
            <span className="text-md tracking-wide text-blue-gray-500">{stock.headlineOne}</span>
          </div>
          <div className="flex flex-1 items-center justify-center p-2">
            <img src={stock.headlineOne.charAt(0) === '-' ? "/images/img_arrow_down_red_500.svg" : "/images/img_arrow_up.svg"} alt={stock.headlineOne.charAt(0) === '-' ? "arrow down" : "arrow up"} className="h-6 w-6" />
            <span className="ml-2 text-md tracking-wide text-blue-gray-500">{stock.headlineTwo}</span>
          </div>
          <div className="flex flex-1 items-center justify-center p-2">
            <span className="text-md tracking-wide text-blue-gray-500">{stock.price}</span>
          </div>
        </div>
      </div>
      ))}
    </div>
  );
}