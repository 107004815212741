import React, {useEffect} from "react";

export default function TickerTape({ stocks }) {
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
        script.innerHTML = JSON.stringify({
          "symbols": [
            {
              "proName": "FOREXCOM:SPXUSD",
              "title": "S&P 500 Index"
            },
            {
              "proName": "FOREXCOM:NSXUSD",
              "title": "US 100 Cash CFD"
            },
            {
              "proName": "FX_IDC:EURUSD",
              "title": "EUR"
            },
            {
              "proName": "BITSTAMP:BTCUSD",
              "title": "Bitcoin"
            }
          ],
          "showSymbolLogo": true,
          "isTransparent": false,
          "displayMode": "regular",
          "colorTheme": "light",
          "locale": "en"
        });
        const container = document.querySelector('.tradingview-widget-container__widget');
        container.appendChild(script);
    }, []);

  return (
    <div class="tradingview-widget-container" className="pointer-events-none">
        <div class="tradingview-widget-container__widget"></div>
    </div>
  );
}
