import React from "react";
import { Text, Img, Heading, Button } from "./../../components";
import RegisterOneRow from "./../../components/RegisterOneRow";

export default function LoginIPadStudent() {
    return <div className="w-[44%] bg-light_blue-200 pb-[566px] pl-14 pt-[169px] md:w-full md:p-5 md:py-5 md:pl-5">
        <div className="flex items-center rounded-lg border-[16px] border-solid border-black-900 shadow-2xl sm:flex-col">
            <div className="flex w-[10%] flex-col gap-[125px] border-r border-solid border-blue_gray-100 bg-white-A700 pb-3.5 pt-[9px] md:gap-[93px] sm:w-full sm:gap-[62px]">
                <div className="flex flex-col items-center justify-center gap-3.5 pb-[157px] md:pb-5">
                    <Img src="/images/img_brand.svg" alt="brand" className="h-[19px] w-[19px]" />
                    <div className="flex flex-col gap-[9px] self-stretch">
                        <div className="flex flex-col items-center gap-0.5 px-1 pb-0.5 opacity-0.8">
                            <Button color="blue_50_04" size="sm" className="w-[23px] rounded">
                                <Img src="/images/img_grid.svg" />
                            </Button>
                            <Heading size="md" as="h2" className="!text-[7.17px] !text-gray-800_01">
                                Dashboard
                            </Heading>
                        </div>
                        <div className="flex flex-col items-center gap-0.5 px-[9px] pb-px opacity-0.8">
                            <Img
                                src="/images/img_container_gray_800_01_23x23.svg"
                                alt="container"
                                className="h-[23px] w-[23px] rounded"
                            />
                            <Heading size="md" as="h3" className="!text-[7.17px]">
                                Courses
                            </Heading>
                        </div>
                        <div className="flex flex-col items-center gap-0.5 px-px pb-0.5 opacity-0.8">
                            <Img
                                src="/images/img_container_gray_800_01.svg"
                                alt="container"
                                className="h-[23px] w-[23px] rounded-[50%]"
                            />
                            <Heading
                                size="md"
                                as="h4"
                                className="w-full text-center !text-[7.17px] leading-[9px] !text-gray-800_01"
                            >
                                Stock Simulator
                            </Heading>
                        </div>
                        <div className="flex flex-col items-center gap-0.5 px-px pb-0.5 opacity-0.8">
                            <Img
                                src="/images/img_container_gray_800_01_22x22.svg"
                                alt="container"
                                className="h-[23px] w-[23px] rounded-[50%]"
                            />
                            <Heading
                                size="md"
                                as="h5"
                                className="w-full text-center !text-[7.17px] leading-[9px] !text-gray-800_01"
                            >
                                Budget Simulator
                            </Heading>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center gap-[9px] px-1.5 opacity-0.8">
                    <Img src="/images/img_icon_gray_900_07.svg" alt="icon" className="h-[14px] w-[14px]" />
                    <div className="flex flex-col items-center gap-0.5 self-stretch">
                        <Img
                            src="/images/img_avatars_3d_avatar_13.png"
                            alt="avatars3d"
                            className="h-[19px] w-[19px] object-cover"
                        />
                        <Heading
                            size="md"
                            as="h6"
                            className="w-full text-center !text-[7.17px] leading-[9px] !text-gray-800_01"
                        >
                            Bhavik Donga
                        </Heading>
                    </div>
                </div>
            </div>
            <div className="flex-1 bg-white-A700 sm:self-stretch">
                <div>
                    <div className="relative z-[1] flex border-b border-solid border-blue_gray-100 bg-white-A700 px-3.5 py-1">
                        <div className="flex bg-white-A700 pb-[5px]">
                            <Heading size="4xl" as="p" className="!text-[14.34px]">
                                Hi welcome back! 🥳
                            </Heading>
                        </div>
                    </div>
                    <Img
                        src="/images/img_rectangle_9.png"
                        alt="image"
                        className="relative mt-[-37px] h-[184px] w-full object-cover md:h-auto"
                    />
                </div>
                <div className="relative mt-[-146px] overflow-auto py-3.5">
                    <div className="flex flex-col items-start gap-[9px]">
                        <Img
                            src="/images/img_frame_25394.png"
                            alt="image"
                            className="h-[115px] w-[90%] rounded-[7px] object-cover"
                        />
                        <div className="relative h-[171px] self-stretch">
                            <div className="absolute bottom-0 left-[0.00px] top-0 my-auto h-[171px] w-[90%] rounded border border-solid border-blue_gray-100 bg-white-A700" />
                            <div className="absolute left-0 right-0 top-[9.56px] m-auto flex w-full flex-col items-start gap-[7px] pl-[9px]">
                                <div className="flex">
                                    <Heading size="xl" as="p" className="!text-[9.56px] !text-gray-900_02">
                                        My Courses
                                    </Heading>
                                </div>
                                <div className="flex gap-[9px] self-stretch rounded sm:flex-col">
                                    <div className="flex flex-1 gap-[9px] md:flex-row sm:self-stretch">
                                        {/* TODO: (Cleanliness) Have the component handle all these variants through a "color" param. */}
                                        <RegisterOneRow
                                            currencydollar="/images/img_currency_dollar.svg"
                                            twentyminutesone="/images/img_frame_25387.svg"
                                            duration="20 minutes"
                                            twentyminutes="/images/img_frame_25385.svg"
                                        />
                                        <div className={` flex items-center w-full rounded`}>
                                            <div className="w-full rounded">
                                                <div className="rounded bg-blue-50_04 pr-1 pt-1">
                                                    <div className="flex flex-col items-end">
                                                        <Img
                                                            src="/images/img_currency_dollar_indigo_300.svg"
                                                            alt="dollar"
                                                            className="relative z-[2] mr-1 h-[59px] w-[59px] md:mr-0"
                                                        />
                                                        <div className="relative mt-[-14px] self-stretch py-2">
                                                            <div className="px-[9px]">
                                                                <div className="flex flex-col items-start gap-[3px]">
                                                                    <Img src="/images/img_frame_25387_indigo_100.svg" alt="20_minutes" className="h-[8px] w-full" />
                                                                    <div className="h-[14px] self-stretch rounded-[7px] bg-indigo-200_01" />
                                                                    <Heading size="md" as="p" className="!text-[7.17px] !text-indigo-200_01">
                                                                        15 minutes
                                                                    </Heading>
                                                                    <Img src="/images/img_frame_25385_blue_900_01.svg" alt="20_minutes" className="h-[11px] w-full" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={` flex items-center w-full rounded`}>
                                            <div className="w-full rounded">
                                                <div className="rounded bg-purple-50 pr-1 pt-1">
                                                    <div className="flex flex-col items-end">
                                                        <Img
                                                            src="/images/img_graduation_hat_02.svg"
                                                            alt="graduationhat"
                                                            className="relative z-[2] mr-1 h-[59px] w-[59px] md:mr-0"
                                                        />
                                                        <div className="relative mt-[-14px] self-stretch py-2">
                                                            <div className="px-[9px]">
                                                                <div className="flex flex-col items-start gap-[3px]">
                                                                    <Img src="/images/img_frame_25387_pink.svg" alt="20_minutes" className="h-[8px] w-full" />
                                                                    <div className="h-[14px] self-stretch rounded-[7px] bg-purple-100_01" />
                                                                    <Heading size="md" as="p" className="!text-[7.17px] !text-pink-800_7f">
                                                                        15 minutes
                                                                    </Heading>
                                                                    <Img src="/images/img_frame_25385_pink.svg" alt="20_minutes" className="h-[11px] w-full" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-[90%] rounded bg-white-A700 blur-[4.00px] backdrop-opacity-[0.5] md:w-full">
                            <div className="flex w-full flex-col gap-1 rounded border border-solid border-blue_gray-100 py-[9px] pl-[9px]">
                                <div className="flex self-start pt-1.5">
                                    <Heading size="xl" as="p" className="!text-[9.56px]">
                                        My Stock Portfolio
                                    </Heading>
                                </div>
                                <div className="rounded-[3px] pt-[11px]">
                                    <div className="flex items-start justify-between gap-5 sm:flex-col">
                                        <div className="mt-[15px] flex flex-col items-start gap-5">
                                            <Text size="s" as="p" className="!text-[6.7px] !text-gray-500_01">
                                                9k
                                            </Text>
                                            <Text size="s" as="p" className="!text-[6.7px] !text-gray-500_01">
                                                7k
                                            </Text>
                                            <Text size="s" as="p" className="!text-[6.7px] !text-gray-500_01">
                                                5k
                                            </Text>
                                            <Text size="s" as="p" className="!text-[6.7px] !text-gray-500_01">
                                                3k
                                            </Text>
                                            <Text size="s" as="p" className="self-end !text-[6.7px] !text-gray-500_01">
                                                1k
                                            </Text>
                                        </div>
                                        <div className="flex flex-1 flex-col gap-[3px] sm:self-stretch">
                                            <div className="flex items-center gap-[23px] sm:flex-col">
                                                <div className="flex flex-1 flex-col items-end sm:self-stretch">
                                                    <div className="relative h-[161px] self-stretch">
                                                        <Img
                                                            src="/images/img_vector_2.png"
                                                            alt="vectortwo"
                                                            className="absolute bottom-0 left-0 right-0 top-0 m-auto h-[161px] w-full object-cover"
                                                        />
                                                        <div className="absolute left-0 right-0 top-[0.00px] m-auto h-[121px] w-full md:h-auto">
                                                            <Img
                                                                src="/images/img_vector_1.png"
                                                                alt="vectorone"
                                                                className="h-[121px] w-full object-cover"
                                                            />
                                                            <div className="absolute right-[21%] top-[9.01px] m-auto flex w-[2%] justify-end">
                                                                <div className="h-[8px] w-[9px] rounded border-[0.5px] border-solid border-blue-400 bg-white-A700" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="relative z-[3] mr-[98px] mt-[-2px] h-[5px] w-[5px] rounded-sm border-[0.5px] border-solid border-blue-400 bg-white-A700 md:mr-0" />
                                                </div>
                                                <div className="h-[7px] w-px rotate-[-90deg] self-end bg-gray-200" />
                                            </div>
                                            <div className="ml-[18px] flex w-[82%] flex-wrap justify-between gap-5 md:ml-0 md:w-full">
                                                <Text
                                                    size="s"
                                                    as="p"
                                                    className="h-[10px] w-[10px] self-end !text-[6.7px] !text-gray-500_01"
                                                >
                                                    Jan
                                                </Text>
                                                <Text
                                                    size="s"
                                                    as="p"
                                                    className="h-[10px] w-[11px] self-start !text-[6.7px] !text-gray-500_01"
                                                >
                                                    Feb
                                                </Text>
                                                <Text size="s" as="p" className="self-start !text-[6.7px] !text-gray-500_01">
                                                    Mar
                                                </Text>
                                                <Text
                                                    size="s"
                                                    as="p"
                                                    className="h-[10px] w-[11px] self-end !text-[6.7px] !text-gray-500_01"
                                                >
                                                    Apr
                                                </Text>
                                                <Text size="s" as="p" className="self-end !text-[6.7px] !text-gray-500_01">
                                                    May
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}