import React, { useState, useEffect, useMemo } from "react";
import { Text, Button, Heading } from "../../components";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from 'react-router-dom';
import { fetchStudentPortfolioGraph } from "SimulatorAPIService"; // Import the function here
import { ReactTableHoldings } from "components/ReactTableHoldings";
import QuickLink from "components/QuickLink";
import {IconSortAscendingLetters, IconSortDescendingLetters} from '@tabler/icons-react'

const Spinner = () => (
    <div className="spinner-container">
        <div className="spinner"></div>
    </div>
);

const Students = ({ classroomData }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [isAscending, setIsAscending] = useState(true); // State to track sort order
    const [sortedStudents, setSortedStudents] = useState(classroomData.students); // State to track sorted students with balances
    const [loadingBalances, setLoadingBalances] = useState(true); // State to handle loading of balances

    const navigate = useNavigate(); // Initialize useNavigate

    const handleSelectRow = (id) => {
        setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.includes(id)
                ? prevSelectedRows.filter((rowId) => rowId !== id)
                : [...prevSelectedRows, id]
        );
    };

    const handleSelectAllRows = () => {
        if (selectedRows.length === sortedStudents.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(sortedStudents.map((student) => student.id));
        }
    };

    // Fetch the current balance for each student and update sortedStudents
    useEffect(() => {
        const fetchBalances = async () => {
            const balancePromises = classroomData.students.map(async (student) => {
                try {
                    const portfolioData = await fetchStudentPortfolioGraph(student.id);
                    
                    if (portfolioData.status === 401) {
                        navigate('/timeout'); // Navigate to timeout if 401 response
                        return { ...student, balance: 0 }; // Return early to prevent errors
                    }

                    return { ...student, balance: portfolioData.currentBalance };
                } catch (error) {
                    console.error(`Failed to fetch balance for student ${student.id}`, error);
                    return { ...student, balance: 0 }; // Default to 0 if failed
                }
            });

            const updatedStudents = await Promise.all(balancePromises);
            setSortedStudents(updatedStudents);
            setLoadingBalances(false); // Balances have finished loading
        };

        fetchBalances();
    }, [classroomData.students, navigate]);

    const handleSort = () => {
        const sorted = [...sortedStudents].sort((a, b) => {
            if (isAscending) {
                return a.last.localeCompare(b.last); // Sort by last name ascending
            } else {
                return b.last.localeCompare(a.last); // Sort by last name descending
            }
        });
        setSortedStudents(sorted);
        setIsAscending(!isAscending); // Toggle sort order
    };

    const handleCreateStudentsClick = () => {
        navigate('/createstudents', { state: { classroomId: classroomData.id } });
    };

    const table1Columns = useMemo(() => {
        const table1ColumnHelper = createColumnHelper();
        return [
            table1ColumnHelper.display({
                id: "selection",
                header: ({ table }) => (
                    <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                        checked={selectedRows.length === sortedStudents.length}
                        onChange={handleSelectAllRows}
                    />
                ),
                cell: (info) => (
                    <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                        checked={selectedRows.includes(info.row.original.id)}
                        onChange={() => handleSelectRow(info.row.original.id)}
                    />
                ),
                meta: { width: "55px" },
            }),
            table1ColumnHelper.accessor((row) => `${row.first} ${row.last}`, {
                id: 'name',
                cell: (info) => (
                    <div className="items-start text-left">
                        <Text size="7xl" as="p" className="tracking-[0.10px]">
                            {info?.getValue?.()}
                        </Text>
                    </div>
                ),
                header: (info) => (
                    <div className="flex justify-between items-center">
                        <Heading size="4xl" as="h3" className="!text-blue_gray-500 items-start text-left">
                            Name
                        </Heading>
                    </div>
                ),
                meta: { width: "full" },
            }),
            table1ColumnHelper.accessor((row) => row.balance, {
                id: 'balance',
                header: (info) => (
                    <Heading size="4xl" as="h3" className="!text-blue_gray-500 items-center text-center">
                        Current Balance
                    </Heading>
                ),
                cell: (info) => (
                    <div className="items-center text-center">
                        <Text size="7xl" as="p" className="tracking-[0.10px]">
                            {info.getValue() !== undefined ? `$${info.getValue().toFixed(2)}` : "Loading..."}
                        </Text>
                    </div>
                ),
                meta: { width: "full" },
            }),
        ];
    }, [selectedRows, sortedStudents, isAscending]);

    return (
        <div className="flex md:overflow-auto">
            <div className="flex flex-col w-[85%] h-[75vh] gap-4 p-4 md:w-full">
                <div className="flex w-full gap-2 justify-between">
                    <div className="flex gap-4">
                        <Button
                            size="3xl"
                            variant="outline"
                            shape="round"
                            className="min-w-[166px] font-roboto font-medium tracking-[0.10px] sm:px-5"
                            onClick={handleCreateStudentsClick}
                        >
                            Create Credentials
                        </Button>
                    </div>
                </div>

                <div className="flex flex-col h-full w-full border border-gray-300 rounded-lg overflow-hidden">
                    <div className="flex justify-end px-4 border-b">
                        <button onClick={handleSort} className="text-blue-500">
                            {isAscending ? <IconSortDescendingLetters/> : <IconSortAscendingLetters/>}
                        </button>
                        <button>
                            <p className="p-2 font-bold text-xl">⋮</p>
                        </button>
                    </div>
                    <div className="overflow-auto">
                        {loadingBalances ? (
                            <div className="h-[70vh]">
                                <Spinner />
                            </div>
                        ) : (
                            <ReactTableHoldings
                                size="lg"
                                bodyProps={{ className: "" }}
                                headerProps={{
                                    className: "rounded-lg border-blue_gray-50_01 border-b border-solid bg-gray-50_01 md:flex-col",
                                }}
                                rowDataProps={{ className: "md:flex-col border-b h-[75px]" }}
                                className="w-full rounded-lg"
                                columns={table1Columns}
                                data={sortedStudents} // Use sortedStudents for sorting
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="w-px min-h-[90vh] bg-gray-300"></div>

            <div className="flex flex-col w-[25%] h-full m-4 gap-5 md:w-[0%] md:hidden">
                <QuickLink classroomData={classroomData} />
            </div>
        </div>
    );
};

export default Students;

const style = `
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
}

.spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
`;

// Add the styles to the document
