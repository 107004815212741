import { Text, Img, Button, Input, CheckBox } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { fetchInfo } from "SimulatorAPIService";
import { UserContext } from "UserContext";
import React, { useState, useContext } from "react";

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

const Form = () => {
    // state variables for email and passwords
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberme, setRememberme] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    // state variable for error messages
    const [error, setError] = useState("");

    const { userInfo, setUserInfo } = useContext(UserContext);

    const navigate = useNavigate();

    const handleClickRegister = () => {
        navigate('/Register');
    };

    const handleClickForgotPassword = (e) => {
        navigate('/forgotPassword');
    }

    // handle submit event for the form
    const handleSubmit = async (e) => {
        e.preventDefault();
        // validate email and passwords
        if (!email || !password) {
            setError("Please fill in all fields.");
        } else {
            // clear error message
            setError("");
            // post data to the /register api

            var loginurl = "";
            if (rememberme === true)
                loginurl = "/login?useCookies=true";
            else
                loginurl = "/login?useSessionCookies=true";

            try {
                const response = await fetch(API_ENDPOINT + loginurl, {
                    method: "POST",
                    mode: "cors",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                        "accept": "application/json",
                    },
                    body: JSON.stringify({
                        email: email,
                        password: password,
                    }),
                });

                if (!response.ok) {
                    throw new Error("Error Logging In.");
                }

                setError("Successful Login.");

                // Fetch user info
                const userData = await fetchInfo();
                setUserInfo(userData);

                if (userData.role === "Student") {
                    window.location.href = '/studenthome';
                } else {
                    window.location.href = '/teacherhome';
                }
            } catch (error) {
                console.error(error);
                setError("Error Logging in.");
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSubmit(e);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="text-red-500 text-sm">
                    {error !== "" ? error : ""}
                </div>
                <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-[23px]">
                        <div className="flex flex-col items-start gap-[5px]">
                            <Text as="p" className="!font-medium !text-blue_gray-800_01">
                                Username or Email
                            </Text>
                            <Input
                                size="xl"
                                shape="round"
                                id="email"
                                name="email"
                                value={email}
                                onChange={setEmail}
                                onKeyDown={handleKeyDown}
                                className="self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                            />
                        </div>
                        <div className="flex flex-col items-start gap-1.5">
                            <Text as="p" className="!font-medium !text-blue_gray-800_01">
                                Password
                            </Text>
                            <Input
                                size="xl"
                                shape="round"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                id="password"
                                value={password}
                                onChange={setPassword}
                                onKeyDown={handleKeyDown}
                                suffix={<Img src={showPassword ? "/images/img_eye.svg" : "/images/img_eyeoff.svg"}
                                    alt={showPassword ? "eye" : "eye-off"}
                                    onClick={() => setShowPassword(!showPassword)}
                                    className="h-[16px] w-[16px] cursor-pointer" />}
                                className="gap-[35px] self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                            />
                        </div>
                        <div className="flex justify-between gap-5">
                            <CheckBox
                                label="Remember Password"
                                id="rememberme"
                                name="rememberme"
                                checked={rememberme}
                                onChange={setRememberme}
                                className="gap-3 self-start p-px font-inter text-base text-blue_gray-500"
                            />
                            <Text size="7xl" as="p" className="self-end !font-inter !text-blue-A200">
                                <a className="cursor-pointer" onClick={handleClickForgotPassword}>
                                    Forgot Password?
                                </a>
                            </Text>
                        </div>
                    </div>

                    <div className="flex gap-8 self-start justify-between w-full">
                        <Button
                            size="5xl"
                            variant="outline"
                            shape="round"
                            leftIcon={<Img src="/images/img_arrowdown.svg" alt="arrow_down" className="h-[16px] w-[16px]" />}
                            className="min-w-[161px] gap-0.5 font-semibold shadow-sm sm:px-5 flex justify-center items-center"
                            onClick={handleClickRegister}
                        >
                            Back
                        </Button>
                        <Button
                            size="5xl"
                            shape="round"
                            className="min-w-[367px] border border-solid border-blue-A200 font-semibold shadow-sm sm:px-5"
                        >
                            Login
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Form;
