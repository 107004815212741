import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Heading, Text } from "../../components"; // Adjust import paths as necessary
import { Img } from "../../components";

const Timeout = () => {
    const navigate = useNavigate();

    const handleLoginRedirect = () => {
        navigate("/loginpage"); // Adjust the route for your login page
    };

    return (
        <div className="flex flex-col items-center gap-4 justify-center min-h-screen bg-gray-100">
            
            <Img src="/images/img_header_logo.png" alt="Intertwined Finance" className="h-[100px]" />

            <div className="flex flex-col bg-white-A700 shadow-sm p-6 rounded-lg text-center">
                <Heading size="6xl" as="h2" className="mb-4">
                    Session Timed Out
                </Heading>
                <Text size="4xl" as="p" className="mb-4 text-gray-600">
                    Your session has expired due to inactivity. Please log in again to continue.
                </Text>
                <Button
                    size="3xl"
                    className="mt-4 px-8 py-3 bg-blue-500 text-white rounded-lg"
                    onClick={handleLoginRedirect}
                >
                    Go to Login Page
                </Button>
            </div>
        </div>
    );
};

export default Timeout;
